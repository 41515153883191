import {TButton} from '@symfonia/symfonia-ui-components';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IntlShape} from 'react-intl';
import {v4} from 'uuid';
import {
  DefaultItem,
  InvoicePreviewInterface,
  InvoicePreviewRepository,
} from '../../../../state/InvoicePreviewState/InvoicePreviewRepository';
import React from 'react';
import {ZoomTooltip} from './ZoomTooltip';
import {ButtonSecondarySize, IconSvg, TooltipPosition} from '@symfonia/brandbook';
import {InvoiceDto} from '@symfonia-ksef/graphql';

export enum ButtonKeys {
  ZoomIn,
  ZoomOut,
  Download,
  ArrowLeft,
  ArrowRight,
}

const {ARROW_FORWARD_IOS, ARROW_BACK_IOS, SAVE_ALT, ZOOM_IN, ZOOM_OUT} = IconSvg;

export type ButtonParams = {
  idKey: ButtonKeys;
  disabled?: boolean;
} & TButton;

type ButtonsVariants = ButtonParams;

export type Buttons = {
  right: ButtonsVariants;
  left: ButtonsVariants;
  zoomIn: ButtonsVariants;
  zoomOut: ButtonsVariants;
  download: ButtonsVariants;
};

export type OnChange<T extends DefaultItem = DefaultItem> = (item?: T) => Promise<void> | void;

type Handlers = Record<keyof Buttons, () => void>;

type HandlersFactoryParams<T extends DefaultItem = DefaultItem> = {
  previewRepository: InvoicePreviewInterface<T>;
  invoices: InvoiceDto[];
  zoomTooltipTimeout: NodeJS.Timeout | undefined;
  onChange?: OnChange<T>;
};

type GetButtonsParams<T extends DefaultItem = DefaultItem> = {
  intl: IntlShape;
  disabled?: boolean;
  currentInvoiceIndex: number;
  onChange?: OnChange<T>;
} & HandlersFactoryParams<T>;

const handlersFactory = <T extends DefaultItem = DefaultItem>({
  invoices,
  zoomTooltipTimeout,
  previewRepository,
  onChange,
}: HandlersFactoryParams<T>): Handlers => ({
  right: () => {
    previewRepository.setCurrentInvoiceId(invoices[previewRepository.getCurrentInvoiceIndex(invoices) + 1]?.Id);
    onChange?.(previewRepository.currentItem);
  },
  left: () => {
    previewRepository.setCurrentInvoiceId(invoices[previewRepository.getCurrentInvoiceIndex(invoices) - 1]?.Id);
    onChange?.(previewRepository.currentItem);
  },
  zoomIn: () => {
    if (zoomTooltipTimeout) {
      clearTimeout(zoomTooltipTimeout);
      zoomTooltipTimeout = undefined;
    }
    previewRepository.zoomIn();
    previewRepository.setShouldShowZoomRatio(true);
    zoomTooltipTimeout = setTimeout(() => previewRepository.setShouldShowZoomRatio(false), 3000);
  },
  zoomOut: () => {
    if (zoomTooltipTimeout) {
      clearTimeout(zoomTooltipTimeout);
      zoomTooltipTimeout = undefined;
    }
    previewRepository.zoomOut();
    previewRepository.setShouldShowZoomRatio(true);
    zoomTooltipTimeout = setTimeout(() => previewRepository.setShouldShowZoomRatio(false), 3000);
  },
  download: () => {
    previewRepository.pdfService
      .load()
      .then(pdfService => pdfService.download().catch(console.error))
      .catch(console.error);
  },
});

export const getButtons = <T extends DefaultItem = DefaultItem>({
  previewRepository,
  intl,
  disabled,
  invoices,
  currentInvoiceIndex,
  zoomTooltipTimeout,
  onChange,
}: GetButtonsParams<T>): Buttons => {
  const handlers = handlersFactory<T>({zoomTooltipTimeout, previewRepository, invoices, onChange});
  return {
    right: {
      icon: ARROW_FORWARD_IOS,
      idKey: ButtonKeys.ArrowRight,
      key: v4(),
      onClick: handlers.right,
      disabled: currentInvoiceIndex >= invoices.length - 1,
      outlined: true,
      testId: "InvoicePreviewRightButton",
    },
    left: {
      icon: ARROW_BACK_IOS,
      idKey: ButtonKeys.ArrowLeft,
      key: v4(),
      onClick: handlers.left,
      disabled: currentInvoiceIndex <= 0,
      outlined: true,
      testId: "InvoicePreviewLeftButton",
    },
    zoomIn: {
      size: ButtonSecondarySize.MD,
      icon: ZOOM_IN,
      key: v4(),
      idKey: ButtonKeys.ZoomIn,
      tooltipDisabled: false,
      tooltipTitle: React.createElement(ZoomTooltip, {previewRepository, title: intl.formatMessage({id: Tr.zoomIn})}),
      placement: TooltipPosition.LEFT,
      onClick: handlers.zoomIn,
      disabled: !previewRepository.canZoomIn,
      testId: "InvoicePreviewZoomInButton",
    },
    zoomOut: {
      size: ButtonSecondarySize.MD,
      icon: ZOOM_OUT,
      key: v4(),
      idKey: ButtonKeys.ZoomOut,
      tooltipDisabled: false,
      tooltipTitle: React.createElement(ZoomTooltip, {previewRepository, title: intl.formatMessage({id: Tr.zoomOut})}),
      placement: TooltipPosition.LEFT,
      onClick: handlers.zoomOut,
      disabled: !previewRepository.canZoomOut,
      testId: "InvoicePreviewZoomOutButton",
    },
    download: {
      size: ButtonSecondarySize.MD,
      icon: SAVE_ALT,
      key: v4(),
      idKey: ButtonKeys.Download,
      tooltipDisabled: false,
      tooltipTitle: intl.formatMessage({id: Tr.downloadPdf}),
      placement: TooltipPosition.LEFT,
      onClick: handlers.download,
      disabled,
      testId: "InvoicePreviewDownloadButton",
    },
  };
};
