import {BaseSideMenuFilterState} from '@symfonia-ksef/state/filters/BaseSideMenuFilterState';
import {InvoicesService} from '../Invoices.service';
import {FilterScopeType, ListFilterType, TranslatedFilter} from '@symfonia-ksef/state/filters/BaseFilterState';
import {computed, makeObservable, toJS} from 'mobx';
import {Pills} from '../../../../root/components/FiltersPills';
import {FiltersFieldsInput, IssuerInvoiceFragment, RecipientInvoiceFragment} from '@symfonia-ksef/graphql';
import {IssuersRepository} from './Issuers.repository';
import {RecipientsRepository} from './Recipients.repository';
import {InvoicesFiltersUtils} from './InvoicesFiltersUtils';
import {intl} from '../../../../root/IntlProvider';
import _ from 'lodash';

export enum InvoicesFilterKeys {
  Numbers = 'Numbers',
  DocumentTypes = 'DocumentTypes',
  IssuerNames = 'IssuerNames',
  IssuerNips = 'IssuerNips',
  RecipientNames = 'RecipientNames',
  RecipientNips = 'RecipientNips',
  NetValue = 'NetValue',
  VatValue = 'VatValue',
  GrossValue = 'GrossValue',
  Currencies = 'Currencies',
  netValueSearchAmount = 'netValueSearchAmount',
  netValueFrom = 'netValueFrom',
  netValueTo = 'netValueTo',
  vatValueSearchAmount = 'vatValueSearchAmount',
  vatValueFrom = 'vatValueFrom',
  vatValueTo = 'vatValueTo',
  grossValueSearchAmount = 'grossValueSearchAmount',
  grossValueFrom = 'grossValueFrom',
  grossValueTo = 'grossValueTo',
  dateFrom = 'DateFrom',
  dateTo = 'DateTo',
  invoiceKsefIssueDate = 'invoiceKsefIssueDate',
  invoiceKsefIssueDateFrom = 'invoiceKsefIssueDateFrom',
  invoiceKsefIssueDateTo = 'invoiceKsefIssueDateTo',
  DateOfIssue = 'DateOfIssue',
  KsefDate = 'KsefDate',
  KsefNumbers = 'KsefNumbers',
  KSeFStatuses = 'KSeFStatuses',
  RegistrationNumber = 'RegistrationNumber',
  InvoicePostingStatuses = 'InvoicePostingStatuses',
}

export class InvoicesFiltersState extends BaseSideMenuFilterState<InvoicesService, InvoicesFilterKeys> {
  private static readonly defaultFilters: Readonly<ListFilterType<InvoicesFilterKeys>> = Object.freeze([
    {
      key: InvoicesFilterKeys.Numbers,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.DocumentTypes,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.IssuerNames,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.IssuerNips,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.RecipientNips,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.RecipientNames,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.NetValue,
      value: {
        values: [{
          from: undefined,
          to: undefined,
          specific: undefined,
        }],
        isActive: false,
        isRangeFilter: true,
        type: FilterScopeType.Range,
      },
    },
    {
      key: InvoicesFilterKeys.VatValue,
      value: {
        values: [{
          from: undefined,
          to: undefined,
          specific: undefined,
        }],
        isActive: false,
        isRangeFilter: true,
        type: FilterScopeType.Range,
      },
    },
    {
      key: InvoicesFilterKeys.GrossValue,
      value: {
        values: [
          {
            from: undefined,
            to: undefined,
            specific: undefined,
          },
        ],
        isActive: false,
        isRangeFilter: true,
        type: FilterScopeType.Range,
      },
    },
    {
      key: InvoicesFilterKeys.Currencies,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.DateOfIssue,
      value: {
        values: [
          {
            from: undefined,
            to: undefined,
            specific: undefined,
          },
        ],
        isActive: false,
        isRangeFilter: true,
        type: FilterScopeType.Date,
      },
    },
    {
      key: InvoicesFilterKeys.KsefDate,
      value: {
        values: [
          {
            from: undefined,
            to: undefined,
            specific: undefined,
          },
        ],
        isActive: false,
        isRangeFilter: true,
        type: FilterScopeType.Date,
      },
    },
    {
      key: InvoicesFilterKeys.KsefNumbers,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.RegistrationNumber,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.KSeFStatuses,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.Enum,
        isPageFilter: true,
      },
    },
    {
      key: InvoicesFilterKeys.InvoicePostingStatuses,
      value: {
        values: [],
        isActive: false,
        type: FilterScopeType.String,
        isPageFilter: true,
      },
    },
  ]);

  private readonly issuersRepository!: IssuersRepository;
  private readonly recipientsRepository!: RecipientsRepository;

  constructor(invoicesService: InvoicesService) {
    super(invoicesService);
    this.issuersRepository = new IssuersRepository(this);
    this.recipientsRepository = new RecipientsRepository(this);
    makeObservable(this);
    this.defaultFilters = [...InvoicesFiltersState.defaultFilters];
    this.setDefaultValues();
  }

  @computed
  public get issuers(): IssuerInvoiceFragment[] {
    return this.issuersRepository.issuers;
  }

  @computed
  public get recipients(): RecipientInvoiceFragment[] {
    return this.recipientsRepository.recipients;
  }

  @computed
  public get pills(): Pills {
    const convertedPills: Pills = {};
    for (const [filterKey, value] of this.pillsList.entries()) {
      const {values} = value as TranslatedFilter;
      convertedPills[filterKey] = {
        filterLabel: intl.formatMessage({id: InvoicesFiltersUtils.translationsMap[filterKey as InvoicesFilterKeys]}),
        items: values.map(({value, key}) => ({
          key,
          label: value,
          onClick: () => {
            this.clearSingleFilter(filterKey, key, value);
            this.parent.repository.fetch();
          },
        })),
      };
    }
    return convertedPills;
  }

  @computed
  public get filtersForQuery(): FiltersFieldsInput {
    return InvoicesFiltersUtils.buildFiltersForQuery(this);
  }

  public get isExternal(): boolean {
    return this.parent.isExternal;
  }

  public get isInternal(): boolean {
    return this.parent.isInternal;
  }

  @computed
  public get filtersIsActive(): boolean {
    return Object.keys(this.pills).length > 0;
  }

  protected setDefaultValues() {
    let filterToCreate: ListFilterType<InvoicesFilterKeys> = this.storage.loadFromStorage();

    if (!filterToCreate?.length) {
      filterToCreate = [...InvoicesFiltersState.defaultFilters];
    }

    filterToCreate.forEach(f => {
      if (Object.keys(InvoicesFilterKeys).find(c => c === f.key)) {
        if (f.value.isRangeFilter && f.value.values.every(f => _.isEmpty(f))) {
          return;
        }
        this.setActiveFilters(f.key, f.value);
      }
    });

    this.createPills();
  }
}
