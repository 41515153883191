import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {observer} from 'mobx-react-lite';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {
  ButtonTertiary,
  ButtonTertiarySize,
  DropdownSize,
  DropdownWidth,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  Modal,
  Tooltip,
  Notification,
  NotificationSize,
  NotificationVariant,
} from '@symfonia/brandbook';
import FilehubFooterButtons from '../components/FilehubFooterButtons';
import FilehubFormatSelect from '../components/FilehubFormatSelect';
import FilehubTextarea from '../components/FilehubTextarea';
import FilehubEmailList from '../components/FilehubEmailList';
import {useEffect, useState} from 'react';
import {useFilehubMethods} from '../table/useFilehubMethods';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import FilehubConfirmationModal from '../components/FilehubConfirmationModal';

type SendAttachmentByLinkModalProps = {
  onClose: () => void;
  switchToAttachmentList: () => void;
  state: IFilehubState;
};

const SendAttachmentByLinkModal = observer(
  ({onClose, switchToAttachmentList, state}: SendAttachmentByLinkModalProps) => {
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [format, setFormat] = useState('formatPDF');
    const [isLoading, setIsLoading] = useState(false);
    const [isSendingLoading, setIsSendingLoading] = useState(false);
    const [shouldResetTextarea, setShouldResetTextarea] = useState(false);
    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
    const isModalOpened = state.isSendAttachmentByLinkModalOpen;
    const hasEmails = state.emailListToSend.length > 0;

    const {getAttachmentsAction, sendAttachmentLinkAction} = useFilehubMethods(state);

    useEffect(() => {
      if (state.invoiceId !== '') {
        setIsLoading(true);
        getAttachmentsAction(state.invoiceId);
        setIsLoading(false);
      }
    }, [state.invoiceId, state.isSendAttachmentByLinkModalOpen]);

    useEffect(() => {
      state.setSendByEmailText(
        intl.formatMessage(
          {id: Tr.emailMessage},
          {
            invoiceNumber: state.invoiceNumber,
            companyName: earchiveState.company.name,
          },
        ),
      );
    }, [state.invoiceNumber, shouldResetTextarea]);

    const sendAttachment = async () => {
      setIsSendingLoading(true);
      const bothFormats = format === 'formatPDFandXML';
      const hasPDF = format === 'formatPDF' || bothFormats;
      const hasXML = format === 'formatXML' || bothFormats;
      await sendAttachmentLinkAction(hasXML, hasPDF);

      setIsSendingLoading(false);

      setShouldResetTextarea(true);

      setFormat('formatPDF');
    };

    const clearFormState = () => {
      setShouldResetTextarea(true);
      state.setInvoiceIssuerName('');
      state.setInvoiceIssuerNIP('');
      state.setEmailListToSendList([]);
    };

    const handleDropdownItemClick = (value: string) => {
      setFormat(value);
      return;
    };

    const handleClose = () => {
      setIsConfirmationModalVisible(true);
    };

    const handleConfirmClose = () => {
      state.setIsSendAttachmentByLinkModalOpen(false);
      state.setInvoiceNumber('');
      clearFormState();
      setIsConfirmationModalVisible(false);
      onClose();
    };

    const handleCancelClose = () => {
      setIsConfirmationModalVisible(false);
    };

    const handleSwitchToAttachmentList = () => {
      switchToAttachmentList();
    };

    const filterCallback = (valueToFind: string): {value: string; label: string}[] => {
      const reply: {value: string; label: string}[] = [];
      return reply;
    };

    const onValidationCallback = (validationResult: boolean) => {
      if (validationResult === true) setIsSaveEnabled(true);
      else setIsSaveEnabled(false);
    };

    const amountOfPublicAttachments = state.attachments.filter(({IsPublic}) => IsPublic).length;

    return (
      <>
        <Modal
          className="w-[400px] p-[16px]"
          show={isModalOpened}
          onClose={handleClose}
          title={intl.formatMessage({id: Tr.sendingInfo})}
        >
          {intl.formatMessage({id: Tr.purchaseInvoiceFormat})} <strong>{state.invoiceNumber}</strong>
          <Tooltip text={intl.formatMessage({id: Tr.formatInvoiceTooltipContent})}>
            <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD} className="align-middle ml-[4px]"/>
          </Tooltip>
          <div className="mt-[8px]">
            <FilehubFormatSelect
              onDropdownItemClick={handleDropdownItemClick}
              dropdownSize={DropdownSize.MD}
              dropdownWidth={DropdownWidth.FULL}
            />
          </div>
          <Notification
            className="mt-[8px]"
            size={NotificationSize.SM}
            text={intl.formatMessage({id: Tr.attachingPDFfileInfo})}
            variant={NotificationVariant.WARNING}
          />
          <div className="mt-[16px]">
            {intl.formatMessage({id: Tr.publicAttachments})} {' '}
            <strong>{isLoading ? `${intl.formatMessage({id: Tr.loading})}...` : amountOfPublicAttachments}</strong>
            <Tooltip text={intl.formatMessage({id: Tr.messageInfoText})}>
              <Icon svg={IconSvg.INFO} color={IconColor.BLUE1_500} size={IconSize.MD} className="align-middle ml-[4px]"/>
            </Tooltip>
          </div>
          <div className="text-right mt-[5px]">
            <ButtonTertiary
              lIcon={IconSvg.LIST}
              onClick={handleSwitchToAttachmentList}
              size={ButtonTertiarySize.MD}
              text={intl.formatMessage({id: Tr.gotoAttachmentsList})}
            />
          </div>
          <FilehubTextarea state={state}/>
          <div className="mt-[16px]">
            <span>
              {intl.formatMessage({id: Tr.contractor})}: <b>{state.invoiceIssuerName}</b>
            </span>
          </div>
          <div>
            <span>
              {intl.formatMessage({id: Tr.NIP})}: <b>{state.invoiceIssuerNIP}</b>
            </span>
          </div>
          <div className="mt-[16px]">
            <FilehubEmailList
              state={state}
              filterCallback={filterCallback}
              lengthToPhraseCheck={4}
              onValidationCallback={onValidationCallback}
            />
          </div>
          <div className="mt-[30px]">
            <FilehubFooterButtons
              onClose={handleClose}
              onSave={sendAttachment}
              isSaveEnabled={isSaveEnabled && hasEmails}
              primaryButtonText={Tr.send}
              loading={isSendingLoading}
            />
          </div>
        </Modal>
        <FilehubConfirmationModal
          visible={isConfirmationModalVisible}
          onClose={handleCancelClose}
          onConfirm={handleConfirmClose}
        />
      </>
    );
  },
);

export default SendAttachmentByLinkModal;

