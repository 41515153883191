import {Repository} from '../../../../../root/services/MobXServices/Repository/Repository';
import {
  KSeFAuthResult,
  KSeFInvoicesDownloadSharedAccessDocument,
  KSeFInvoicesDownloadSharedAccessMutation,
  KSeFInvoicesDownloadSharedAccessMutationVariables,
  KSeFTokenCredential,
} from '@symfonia-ksef/graphql';
import {InitializerType} from '../../../../../../services/helpers/fetchMatchedAction';
import {CompanyState} from '@symfonia-ksef/state/EarchiveState/CompanyState';
import {addAlert} from '../../../../../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {computed, makeObservable, override} from 'mobx';
import {dateParser} from '../../../../../common';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class InvoicesSharedAccess extends Repository<'KSeFInvoicesDownloadSharedAccess', KSeFInvoicesDownloadSharedAccessMutation, KSeFInvoicesDownloadSharedAccessMutationVariables> {
  constructor(private companyState: CompanyState, earchiveState: EArchiveState, private readonly loadOnMount: boolean) {
    super('KSeFInvoicesDownloadSharedAccess', KSeFInvoicesDownloadSharedAccessDocument, companyState.envObserver, earchiveState, InitializerType.Mutation);

    makeObservable(this);
  }

  @computed
  public get userId(): string | null {
    return this.companyState.userId;
  }

  @computed
  public get settled(): boolean {
    return this.companyState.sharedAccessConfig?.UserId && this.companyState.sharedAccessConfig?.LastModifiedBy && this.companyState.sharedAccessConfig?.LastModifiedDate;
  }

  @computed
  public get modifiedBy(): string {
    return this.companyState.sharedAccessConfig?.LastModifiedBy ?? '';
  }

  @computed
  public get modificationDateTime(): string {
    return this.companyState.sharedAccessConfig?.LastModifiedDate ? dateParser(this.companyState.sharedAccessConfig.LastModifiedDate, true) : '';
  }

  @computed
  public get disabled(): boolean {
    const lastModifiedByCurrentUser = this.companyState?.sharedAccessConfig?.UserId === null || this.companyState?.sharedAccessConfig?.UserId === this.companyState.userId;
    const hasInvoiceReadCredentials = this.earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceRead);
    return this.isActive ? false : !lastModifiedByCurrentUser || !hasInvoiceReadCredentials;
  }

  @computed
  public get isActive(): boolean {
    return !!this.companyState.sharedAccessConfig?.Status;
  }

  @computed
  public get config() {
    return this.companyState.sharedAccessConfig;
  }

  @override
  public override get loading(): boolean {
    return super.loading || this.companyState.loading;
  }

  @computed
  public get authorizedInKSeF(): boolean {
    return this.companyState.authorizedInKSeF === KSeFAuthResult.Authorized || this.companyState.authorizedInKSeF === KSeFAuthResult.AuthorizedWithExternalToken ;
  }

  public override checkIsReady(): boolean {
    return !!this.envId && !!this.variables.Status;
  }

  protected override async _onMount(): Promise<void> {
    if (this.loadOnMount) {
      this.companyState.fetch();
    }
  }

  protected async onSuccess(data: KSeFInvoicesDownloadSharedAccessMutation['KSeFInvoicesDownloadSharedAccess']): Promise<void> {
    await this.companyState.fetch();
  }

  protected override onError(): void {
    addAlert({id: Tr.sharedAccessChangeError, color: ToastVariant.ERROR, duration: 8000});
  }
}
