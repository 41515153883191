import {CompaniesRepository, CompaniesRepositoryI} from '@symfonia-ksef/state/CompaniesRepository/CompaniesRepository';
import {HubOrganizationSelectState, HubOrganizationSelectStateI} from './storage/HubOrganizationSelectState';
import {getTenants} from '../../helpers/getTenants';
import {computed, makeObservable} from 'mobx';
import {BaseModule} from '../../services/MobXServices/BaseModule';
import {TableBuilder} from '../../services/TableServices/TableBuilder';
import {
  CompanyContext,
  CompanyDataSourceHandler,
  companyInitialColumns,
  companyInitialColumnsMobile,
  CompanyRow,
  companySortingConfig,
  companyTableKeysFactory,
} from './storage/hubTableConfig';
import {GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables} from '@symfonia-ksef/graphql';
import {ExtendedTableServiceI} from '../../services/TableServices/ExtendedTableService';
import {SearchService, SearchServiceI} from '../../services/SearchService/SearchService';
import {intl} from '../../IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {PaginationMode} from '../../services/TableServices/PaginationTableService';
import {TenantState} from '@symfonia-ksef/state/EarchiveState/TenantState';

export type Tenants = ReturnType<typeof getTenants>

export type CompaniesTableI = ExtendedTableServiceI<CompanyRow, 'CompanyStatistics', GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables, CompanyContext>

export interface HubServiceI extends BaseModule {
  readonly tableService: CompaniesTableI;
  readonly organizationsService: HubOrganizationSelectStateI;
  readonly searchService: SearchServiceI;

  get loading(): boolean;
}

export class HubService extends BaseModule implements HubServiceI {
  public readonly organizationsService!: HubOrganizationSelectStateI;
  public readonly searchService!: SearchServiceI;
  private readonly repository!: CompaniesRepositoryI;

  constructor(private tenant: TenantState, isPhoneOrSmallPhone: boolean) {
    super();
    makeObservable(this);
    const tenantId = tenant.state.rootTenant ? tenant.state.rootTenant.Tenant?.Id : tenant.state.regularTenant?.Tenant?.Id;
    const envId = tenant.state.rootTenant ? tenant.state.rootTenant.EnvironmentId : tenant.state.regularTenant?.EnvironmentId;
    const firstEnv = tenant.state.firstEnv;
    this.repository = new CompaniesRepository();
    this.organizationsService = new HubOrganizationSelectState(this.repository, tenantId ?? firstEnv?.Tenant?.Id);
    this.searchService = new SearchService(this.repository);
    this.createTableService(tenantId ?? firstEnv?.EnvironmentId, isPhoneOrSmallPhone);

    this.reactionsManager.add(() => this.paginationRelatedState, () => this.tableService.setPagination({page: 1}));
    this.reactionsManager.add(() => this.organizationsService.selectedOrganization, tenant => this.tableService.context.tenantId = tenant?.Id);
    this.repository
      .setEnvId(envId ?? firstEnv?.EnvironmentId)
      .configure({TenantIdFilter: this.tenant.state.selectedTenantForHub?.Id || tenantId});
  }

  private _tableService!: CompaniesTableI;

  public get tableService(): CompaniesTableI {
    return this._tableService;
  }

  @computed
  public get loading(): boolean {
    return this.repository.loading;
  }

  @computed
  private get paginationRelatedState() {
    return {
      tenantId: this.organizationsService.selectedOrganization?.Id,
      searchPhrase: this.repository.settledParameters.SearchText,
    };
  }

  public getTenantId(): string | undefined {
    return this.organizationsService.selectedOrganization?.Id;
  }

  protected _onMount(): void {
    this.tableService.onMount();
    this.organizationsService.onMount();
    this.searchService.onMount();
    this.repository.fetch();
  }

  protected _onUnmount(): void {
    this.organizationsService.onUnmount();
    this.tableService.onUnmount();
    this.searchService.onUnmount();
  }

  private createTableService(tenantId: string, isPhoneOrSmallPhone: boolean): void {
    this._tableService = TableBuilder.create<CompanyRow, 'CompanyStatistics', GetCompanyStatisticsQuery, GetCompanyStatisticsQueryVariables, CompanyContext>()
      .connectKeyFactory(companyTableKeysFactory)
      .connectColumns(isPhoneOrSmallPhone ? companyInitialColumnsMobile : companyInitialColumns)
      .connectContext({title: intl.formatMessage({id: Tr.open}), tenantId})
      .connectSortBy({sortBy: companySortingConfig})
      .connectDataSource(new CompanyDataSourceHandler(this, this.repository))
      .connectPagination({lifeCycle: {mode: PaginationMode.serverSide}})
      .buildExtended();
  }

}
