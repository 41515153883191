import {AlertDirection, AlertVariant, AlertWidth} from '@symfonia/brandbook';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {RequestStatus} from '../../earchive/pages/Documents/state/FilehubState';
import {intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {observer} from 'mobx-react-lite';
import {Attachment} from '@symfonia-ksef/graphql';
import {FilehubAlert} from '../components/FilehubAlert';

type Props = {
  state: IFilehubState;
};

const getAlertProps = (
  deletedAttachmentsList: Attachment[],
  downloadedAttachment: string,
  invoiceNumber: string,
  successfulDeleteRequest: RequestStatus,
  successfulDownloadSingleAttachmentRequest: RequestStatus,
  successfulMultipleActionsRequest: RequestStatus,
) => {
  const successVariant =
    successfulDeleteRequest || successfulDownloadSingleAttachmentRequest || successfulMultipleActionsRequest;
  const numberOfDeletedAttachments = deletedAttachmentsList.length;

  let description = null;
  let title = '';

  if (successfulDeleteRequest === true) {
    if (numberOfDeletedAttachments > 1) {
      title = intlFunction(Tr.attachmentsDeletedSuccess);
    } else {
      title = intl.formatMessage({id: Tr.attachmentDeletedSuccess}, {fileName: deletedAttachmentsList[0].FileName});
    }
  }

  if (successfulDeleteRequest === false) {
    if (numberOfDeletedAttachments > 1) {
      title = intlFunction(Tr.attachmentsDeletedError);
    } else {
      title = intl.formatMessage({id: Tr.attachmentDeletedError}, {fileName: deletedAttachmentsList[0].FileName});
    }
  }

  if (successfulDownloadSingleAttachmentRequest === true) {
    title = intlFunction(Tr.filehubDownloadedAttachmentSuccessTitle);
    description = downloadedAttachment;
  }

  if (successfulDownloadSingleAttachmentRequest === false) {
    title = intlFunction(Tr.filehubDownloadedAttachmentFailedTitle);
    description = intl.formatMessage(
      {id: Tr.filehubDownloadedAttachmentFailedDescriptionUnknown},
      {fileName: invoiceNumber},
    );
  }

  if (successfulMultipleActionsRequest === true) {
    title = intlFunction(Tr.filehubMultipleActionsRequestSuccess);
  }

  if (successfulMultipleActionsRequest === false) {
    title = intlFunction(Tr.filehubMultipleActionsRequestFail);
  }

  return {
    description,
    successVariant,
    title,
  };
};

const AlertBuilder = observer(({state}: Props) => {
  const deletedAttachmentsList = state.deletedAttachmentsList;
  const downloadedAttachment = state.downloadedAttachment;
  const invoiceNumber = state.invoiceNumber;
  const successfulDeleteRequest = state.successfulDeleteRequest;
  const successfulDownloadSingleAttachmentRequest = state.successfulDownloadSingleAttachmentRequest;
  const successfulMultipleActionsRequest = state.successfulMultipleActionsRequest;

  const hideAlert = () => {
    state.setSuccessfulAddRequest(null);
    state.setSuccessfulDeleteRequest(null);
    state.setSuccessfulDownloadSingleAttachmentRequest(null);
    state.setSuccessfulMultipleActionsRequest(null);
  };

  const isVisible =
    successfulDeleteRequest !== null ||
    successfulDownloadSingleAttachmentRequest !== null ||
    successfulMultipleActionsRequest !== null;

  if (!isVisible) return <></>;

  const {description, successVariant, title} = getAlertProps(
    deletedAttachmentsList,
    downloadedAttachment,
    invoiceNumber,
    successfulDeleteRequest,
    successfulDownloadSingleAttachmentRequest,
    successfulMultipleActionsRequest,
  );

  return (
    <FilehubAlert
      description={description || ''}
      onClose={hideAlert}
      title={title}
      variant={successVariant ? AlertVariant.SUCCESS : AlertVariant.ERROR}
      width={AlertWidth.FULL}
    />
  );
});

export default AlertBuilder;
