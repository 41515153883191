import {FC} from 'react';
import {useLinearLimitCalculations} from './linearPackageLimitHooks';
import {LinearPackageLimitProps} from '@symfonia/symfonia-ksef-components';
import ProgressBar from '@ramonak/react-progress-bar';
import {getEnumColor} from './packageLimitUtils';

const LinearPackageLimit: FC<LinearPackageLimitProps> = ({label, thresholds = [0, 90, 100], loading, lineLabel, ...restProps}) => {
  const {normalizedUsage, limit, message, barColor, textColor, usage, variant} = useLinearLimitCalculations({
    ...restProps,
    loading,
    thresholds,
  });

  const singleLineLabel = lineLabel ? `${lineLabel}: ` : '';

  return (
    <div className={'mt-[15px]'}>
      {label && <p>{!loading && label}</p>}
      <div className={'flex flex-col w-full justify-start'}>
        <ProgressBar bgColor={getEnumColor(barColor)} completed={usage} height="4px" isLabelVisible={false} maxCompleted={limit}/>
        <p className={'text-xs mt-[8px]'}>{singleLineLabel}{!loading && message}</p>
      </div>
    </div>
  );
};

export {LinearPackageLimit};
