import {Repository} from '../../../../../root/services/MobXServices/Repository/Repository';
import {
  KSeFAuthResult,
  KSeFInvoiceWhiteListValidationDocument,
  KSeFInvoiceWhiteListValidationMutation,
  KSeFInvoiceWhiteListValidationMutationVariables,
} from '@symfonia-ksef/graphql';
import {CompanyState} from '@symfonia-ksef/state/EarchiveState/CompanyState';
import {InitializerType} from '../../../../../../services/helpers/fetchMatchedAction';
import {computed, makeObservable, override} from 'mobx';
import {dateParser} from '../../../../../common';
import {addAlert} from '../../../../../../services/helpers/AlertService';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ToastVariant} from '@symfonia/brandbook';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';

export class WhiteList extends Repository<'KSeFInvoiceWhiteListValidation', KSeFInvoiceWhiteListValidationMutation, KSeFInvoiceWhiteListValidationMutationVariables> {
  constructor(private companyState: CompanyState, earchiveState: EArchiveState, private readonly loadOnMount: boolean) {
    super('KSeFInvoiceWhiteListValidation', KSeFInvoiceWhiteListValidationDocument, companyState.envObserver, earchiveState, InitializerType.Mutation);

    makeObservable(this);
  }

  @computed
  public get userId(): string | null {
    return this.companyState.userId;
  }

  @computed
  public get settled(): boolean {
    return this.companyState.whiteListConfig?.UserId && this.companyState.whiteListConfig?.LastModifiedBy && this.companyState.whiteListConfig?.LastModifiedDate;
  }

  @computed
  public get modifiedBy(): string {
    return this.companyState.whiteListConfig?.LastModifiedBy ?? '';
  }

  @computed
  public get modificationDateTime(): string {
    return this.companyState.whiteListConfig?.LastModifiedDate ? dateParser(this.companyState.whiteListConfig.LastModifiedDate, true) : '';
  }

  @computed
  public get isActive(): boolean {
    return !!this.companyState.whiteListConfig?.Status;
  }

  @computed
  public get config() {
    return this.companyState.whiteListConfig;
  }

  @override
  public override get loading(): boolean {
    return super.loading || this.companyState.loading;
  }

  @computed
  public get authorizedInKSeF(): boolean {
    return this.companyState.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
  }

  public override checkIsReady(): boolean {
    return !!this.envId && !!this.variables.Status;
  }

  protected override async _onMount(): Promise<void> {
    if (this.loadOnMount) {
      this.companyState.fetch();
    }
  }

  protected async onSuccess(data: KSeFInvoiceWhiteListValidationMutation['KSeFInvoiceWhiteListValidation']): Promise<void> {
    await this.companyState.fetch();
  }

  protected override onError(): void {
    addAlert({id: Tr.whiteListChangeError, color: ToastVariant.ERROR, duration: 8000});
  }
}
