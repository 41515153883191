import React, {FC, useCallback, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useModule} from '../../../root/services/MobXServices/BaseModule';
import {InvoicesService} from './Invoices.service';
import {ExtendedTable} from '../../../root/components/ExtendedTable';
import {InvoiceBound} from '@symfonia-ksef/graphql';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DropdownSize, DropdownWidth} from '@symfonia/brandbook';
import {SingleInvoiceActionMenu} from '../../components/InvoicesTable/ActionMenus/SingleInvoiceActionMenu';
import {GroupInvoiceActionPanel} from '../../components/InvoicesTable/ActionMenus/GroupInvoiceActionPanel';
import {IEarchiveStateMobx} from '../../IEArchiveStateMobx';
import {IFiltersState} from '../../pages/Documents/state/IFiltersState';
import {FiltersConfig} from '../../components/InvoicesTable/Filters/FiltersConfig';
import {useIntl} from 'react-intl';
import {useDownloadInvoicesReport} from '../../hooks/useDownloadInvoicesReport';
import {InvoicePreviewComponent} from '../../components/InvoicePreview/InvoicePreviewComponent';
import _ from 'lodash';
import {useInvoicesTableColumnsConfig} from '../../hooks/useInvoicesTableConfiguration';
import TableHeader from '../../components/TableHeader/TableHeader';
import {ArrowKeys, FiltersDrawer, useKeyboard, usePrevious} from '@symfonia/symfonia-ksef-components';
import {FiltersPills} from '../../../root/components/FiltersPills';
import {downloadInvoicesProgressTracker} from '@symfonia-ksef/state/rootRepository';
import FileHubModal from '../../../filehub/modals/FilehubModal';
import {ContractorsModal} from '../../components/InvoicesTable/Components/ContractorsModal';
import AddAttachmentModal from '../../../filehub/modals/AddAttachmentModal';
import {FiltersBarWithReports} from '../../components/FiltersBar/FiltersBarWithReports';
import SendAttachmentByLinkModal from '../../../filehub/modals/SendAttachmentByLinkModal';

type InvoicesViewProps = {
  eArchiveState: IEarchiveStateMobx;
  invoiceBound: InvoiceBound;
  filtersStateMobX: IFiltersState;
};

export const InvoicesView: FC<InvoicesViewProps> = observer(({eArchiveState, filtersStateMobX, invoiceBound}) => {
  const {contractorModalProps, closeContractorModal, confirmContractorModal, setContractorModalProps} =
    useInvoicesTableColumnsConfig();

  const [isFilehubModalInLinkMode, setFilehubModalInLinkMode] = useState(false);

  const module = useModule(() => new InvoicesService(invoiceBound, downloadInvoicesProgressTracker, setContractorModalProps));
  const intl = useIntl();

  const openFiltersMenu = module.filters.isFilterMenuOpen;
  const isFilehubModalOpen = eArchiveState.filehubState.isFileHubOpen;

  const handleFilter = useCallback(() => {
    module.filters.setOpenFilterMenu(false);
    module.filters.startFiltering();
    module.filters.storage.saveToStorage(module.filters.activeFilters);
    module.tableService.setPagination({page: 1});
    module.repository.fetch();
  }, [module]);

  const {download: downloadReport} = useDownloadInvoicesReport(
    module.filters,
    module.searchService.searchValue,
    module.repository.settledParameters.Order,
  );

  const clearFilters = useCallback(() => {
    module.filters.handleClearMenuFilter();
    module.repository.fetch();
  }, [module]);

  const closeFilehubModal = () => {
    eArchiveState.filehubState.setIsFileHubOpen(false);
    eArchiveState.filehubState.setSuccessfulDeleteRequest(null);
    if (isFilehubModalInLinkMode) {
      setFilehubModalInLinkMode(false);
      eArchiveState.filehubState.setIsSendAttachmentByLinkModalOpen(true);
    }

  };

  const closeAddAttachmentModal = () => {
    eArchiveState.filehubState.setIsAddAttachmentModalOpen(false);
    eArchiveState.filehubState.setSuccessfulAddRequest(null);
  };

  const closeSendAttachmentByLinkModal = () => {
    eArchiveState.filehubState.setIsSendAttachmentByLinkModalOpen(false);
  };

  const switchToAttachmentList = () => {
    setFilehubModalInLinkMode(true);
    eArchiveState.filehubState.setIsSendAttachmentByLinkModalOpen(false);

    // -------------------------------------------
    //TODO: ustawienie stanów okien
    // -------------------------------------------

    eArchiveState.filehubState.setIsFileHubOpen(true);
  };

  const previousFilters = usePrevious(module.filters.getActiveFilters);
  const downloadEnabled =
    (!!module.searchService.searchValue || module.filters.filtersIsActive) && !module.repository.loading;
  const wasFilterChanged = !_.isEqual(_.flatMap(previousFilters), _.flatMap(module.filters.getChangedFilters));

  const handleOpenAndLoad = useCallback((v: boolean) => {
    module.filters.setOpenFilterMenu(v);
  }, []);

  const dropdownProps = {
    width: DropdownWidth.FULL,
    options: module.scope.invoicesVariants,
    size: DropdownSize.SM,
    value: module.scope.currentVariant,
    onChange: ([value]: string[]) => module.scope.setVariant(value),
    className: 'min-w-[220px]',
  };

  useKeyboard(module.handlePreviousFocusedRow, {arrowKey: ArrowKeys.up, skipUntilTheEnd: true, preventDefault: true});
  useKeyboard(module.handleNextFocusedRow, {arrowKey: ArrowKeys.down, skipUntilTheEnd: true, preventDefault: true});

  return <>
    <SingleInvoiceActionMenu
      earchiveStateMobx={eArchiveState}
      menuState={module.contextMenuState}
      enabledGetInvoiceXml={true}
      isExternal={module.isExternal}
      isInternal={module.isInternal}
    />
    <FiltersDrawer
      hidden={!(module.tableService.rows.length > 1 && openFiltersMenu || wasFilterChanged)}
      hasError={module.filters.hasError}
      isFiltersMenuOpened={module.filters.isFilterMenuOpen}
      openFiltersMenu={handleOpenAndLoad}
      handleFilter={handleFilter}
      clearFilters={clearFilters}
      loading={module.repository.loading}
      tableLabel={intl.formatMessage({id: Tr.tableLabel})}
      closeLabel={intl.formatMessage({id: Tr.closeLabel})}
      filterLabel={intl.formatMessage({id: Tr.filterLabel})}
      clearButtonLabel={intl.formatMessage({id: Tr.clearAllLabel})}
      filtersTouched={wasFilterChanged}
      filtersModified={wasFilterChanged}
      onClose={() => {
        module.filters.setOpenFilterMenu(false);
        module.filters.resetFilterToStorage();
      }}
    >
      <FiltersConfig
        stateMobx={filtersStateMobX}
        invoiceBound={module.invoiceBound}
        state={module.filters}
      />
    </FiltersDrawer>
    {isFilehubModalOpen && <FileHubModal onClose={closeFilehubModal} invoicesService={module} state={eArchiveState.filehubState}/>}
    <AddAttachmentModal invoicesService={module} onClose={closeAddAttachmentModal} state={eArchiveState.filehubState}/>
    <SendAttachmentByLinkModal onClose={closeSendAttachmentByLinkModal} switchToAttachmentList={switchToAttachmentList} state={eArchiveState.filehubState}/>

    <TableHeader
      dropdown={dropdownProps}
      searchService={module.searchService}
      translationKey={Tr.search}
    >
      <div className="grow mb-[10px]">
        <FiltersBarWithReports
          clearFilters={clearFilters}
          filtersIsActive={module.filters.filtersIsActive}
          previewSwitchLabel={intl.formatMessage({id: Tr.previewMode})}
          clearFiltersLabel={intl.formatMessage({id: Tr.clearFiltersLabel})}
          invoicePreview={module.invoicePreview}
          isFiltersMenuOpened={module.filters.isFilterMenuOpen}
          openFiltersMenu={handleOpenAndLoad}
          tableLength={module.tableService.rows.length}
          filtersApplied={wasFilterChanged}
          filtersTouched={wasFilterChanged}
          downloadEnabled={downloadEnabled}
          onConfirm={async (reportType) => {
            if (reportType && downloadEnabled) {
              await downloadReport?.({reportFileType: reportType});
            }
          }}
          testId="invoicesFiltersWithReports"
        />
      </div>
    </TableHeader>
    <FiltersPills pills={module.filters.pills} maxVisiblePills={10}/>
    <GroupInvoiceActionPanel
      selectionState={module.tableService}
      invoicesBound={module}
      filtersState={module.filters}
      earchiveStateMobx={eArchiveState}
    />
    <div className="flex w-full min-h-[320px] items-start">
      <ExtendedTable
        service={module.tableService}
        loaderEnabled
        className={module.invoicePreview.enableInvoicePreview ? 'w-full md:w-[50%] mr-[10px]' : 'w-full'}
      />
      <InvoicePreviewComponent
        hidden={!module.invoicePreview.enableInvoicePreview}
        invoicePreviewRepository={module.invoicePreview}
        invoices={module.invoices}
        withButtons
        onChange={module.handleFocusRowChange}
      />
    </div>
    {contractorModalProps && <ContractorsModal
      confirm={confirmContractorModal}
      close={closeContractorModal}
      modalProps={contractorModalProps}
    />}
  </>;
});
