import clsx from 'clsx';
import {forwardRef, MouseEventHandler, useMemo, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import '../../../tailwind.utilities.css';
import {TestableElement} from '../../external/types';
import {Tooltip} from '../Tooltip/Tooltip';
import * as _icons from './assets';

const icons = _icons as Record<keyof typeof _icons, string>;

export enum IconSvg {
  _360 = '_360',
  _3D_ROTATION = '_3dRotation',
  _4K = '_4k',
  AC_UNIT = 'acUnit',
  ACCESS_ALARM = 'accessAlarm',
  ACCESS_ALARMS = 'accessAlarms',
  ACCESS_TIME = 'accessTime',
  ACCESSIBILITY = 'accessibility',
  ACCESSIBILITY_NEW = 'accessibilityNew',
  ACCESSIBLE = 'accessible',
  ACCESSIBLE_FORWARD = 'accessibleForward',
  ACCOUNT_BALANCE = 'accountBalance',
  ACCOUNT_BALANCE_WALLET = 'accountBalanceWallet',
  ACCOUNT_BOX = 'accountBox',
  ACCOUNT_CIRCLE = 'accountCircle',
  ACCOUNT_TREE = 'accountTree',
  ADB = 'adb',
  ADD_A_PHOTO = 'addAPhoto',
  ADD_ALARM = 'addAlarm',
  ADD_ALERT = 'addAlert',
  ADD_BOX = 'addBox',
  ADD_CIRCLE = 'addCircle',
  ADD_CIRCLE_OUTLINE = 'addCircleOutline',
  ADD_COMMENT = 'addComment',
  ADD = 'add',
  ADD_IC_CALL = 'addIcCall',
  ADD_LOCATION = 'addLocation',
  ADD_PHOTO_ALTERNATE = 'addPhotoAlternate',
  ADD_SHOPPING_CART = 'addShoppingCart',
  ADD_TO_HOME_SCREEN = 'addToHomeScreen',
  ADD_TO_PHOTOS = 'addToPhotos',
  ADD_TO_QUEUE = 'addToQueue',
  ADJUST = 'adjust',
  AIRLINE_SEAT_FLAT_ANGLED = 'airlineSeatFlatAngled',
  AIRLINE_SEAT_FLAT = 'airlineSeatFlat',
  AIRLINE_SEAT_INDIVIDUAL_SUITE = 'airlineSeatIndividualSuite',
  AIRLINE_SEAT_LEGROOM_EXTRA = 'airlineSeatLegroomExtra',
  AIRLINE_SEAT_LEGROOM_NORMAL = 'airlineSeatLegroomNormal',
  AIRLINE_SEAT_LEGROOM_REDUCED = 'airlineSeatLegroomReduced',
  AIRLINE_SEAT_RECLINE_EXTRA = 'airlineSeatReclineExtra',
  AIRLINE_SEAT_RECLINE_NORMAL = 'airlineSeatReclineNormal',
  AIRPLANEMODE_ACTIVE = 'airplanemodeActive',
  AIRPLANEMODE_INACTIVE = 'airplanemodeInactive',
  AIRPLAY = 'airplay',
  AIRPORT_SHUTTLE = 'airportShuttle',
  ALARM_ADD = 'alarmAdd',
  ALARM = 'alarm',
  ALARM_OFF = 'alarmOff',
  ALARM_ON = 'alarmOn',
  ALBUM = 'album',
  ALL_INBOX = 'allInbox',
  ALL_INCLUSIVE = 'allInclusive',
  ALL_OUT = 'allOut',
  ALTERNATE_EMAIL = 'alternateEmail',
  AMP_STORIES = 'ampStories',
  ANDROID = 'android',
  ANNOUNCEMENT = 'announcement',
  APARTMENT = 'apartment',
  APPS = 'apps',
  ARCHIVE = 'archive',
  ARROW_LEFT_DOUBLE = 'arrowLeftDouble',
  ARROW_RIGHT_DOUBLE = 'arrowRightDouble',
  ARROW_BACK = 'arrowBack',
  ARROW_BACK_IOS = 'arrowBackIos',
  ARROW_DOWNWARD = 'arrowDownward',
  ARROW_DROP_DOWN_CIRCLE = 'arrowDropDownCircle',
  ARROW_DROP_DOWN = 'arrowDropDown',
  ARROW_DROP_UP = 'arrowDropUp',
  ARROW_FORWARD = 'arrowForward',
  ARROW_FORWARD_IOS = 'arrowForwardIos',
  ARROW_LEFT = 'arrowLeft',
  ARROW_RIGHT_ALT = 'arrowRightAlt',
  ARROW_RIGHT = 'arrowRight',
  ARROW_UPWARD = 'arrowUpward',
  ART_TRACK = 'artTrack',
  ASPECT_RATIO = 'aspectRatio',
  ASSESSMENT = 'assessment',
  ASSIGNMENT = 'assignment',
  ASSIGNMENT_IND = 'assignmentInd',
  ASSIGNMENT_LATE = 'assignmentLate',
  ASSIGNMENT_RETURN = 'assignmentReturn',
  ASSIGNMENT_RETURNED = 'assignmentReturned',
  ASSIGNMENT_TURNED_IN = 'assignmentTurnedIn',
  ASSISTANT = 'assistant',
  ASSISTANT_PHOTO = 'assistantPhoto',
  ATM = 'atm',
  ATTACH_FILE = 'attachFile',
  ATTACH_MONEY = 'attachMoney',
  ATTACHMENT = 'attachment',
  AUDIOTRACK = 'audiotrack',
  AUTORENEW = 'autorenew',
  AV_TIMER = 'avTimer',
  BACKSPACE = 'backspace',
  BACKUP = 'backup',
  BALLOT = 'ballot',
  BAR_CHART = 'barChart',
  BATHTUB = 'bathtub',
  BATTERY_ALERT = 'batteryAlert',
  BATTERY_CHARGING_FULL = 'batteryChargingFull',
  BATTERY_FULL = 'batteryFull',
  BATTERY_STD = 'batteryStd',
  BATTERY_UNKNOWN = 'batteryUnknown',
  BEACH_ACCESS = 'beachAccess',
  BEENHERE = 'beenhere',
  BLOCK = 'block',
  BLUETOOTH_AUDIO = 'bluetoothAudio',
  BLUETOOTH_CONNECTED = 'bluetoothConnected',
  BLUETOOTH = 'bluetooth',
  BLUETOOTH_DISABLED = 'bluetoothDisabled',
  BLUETOOTH_SEARCHING = 'bluetoothSearching',
  BLUR_CIRCULAR = 'blurCircular',
  BLUR_LINEAR = 'blurLinear',
  BLUR_OFF = 'blurOff',
  BLUR_ON = 'blurOn',
  BOOK = 'book',
  BOOKMARK_BORDER = 'bookmarkBorder',
  BOOKMARK = 'bookmark',
  BOOKMARKS = 'bookmarks',
  BORDER_ALL = 'borderAll',
  BORDER_BOTTOM = 'borderBottom',
  BORDER_CLEAR = 'borderClear',
  BORDER_HORIZONTAL = 'borderHorizontal',
  BORDER_INNER = 'borderInner',
  BORDER_LEFT = 'borderLeft',
  BORDER_OUTER = 'borderOuter',
  BORDER_RIGHT = 'borderRight',
  BORDER_STYLE = 'borderStyle',
  BORDER_TOP = 'borderTop',
  BORDER_VERTICAL = 'borderVertical',
  BRANDING_WATERMARK = 'brandingWatermark',
  BRIGHTNESS1 = 'brightness1',
  BRIGHTNESS2 = 'brightness2',
  BRIGHTNESS3 = 'brightness3',
  BRIGHTNESS4 = 'brightness4',
  BRIGHTNESS5 = 'brightness5',
  BRIGHTNESS6 = 'brightness6',
  BRIGHTNESS7 = 'brightness7',
  BRIGHTNESS_AUTO = 'brightnessAuto',
  BRIGHTNESS_HIGH = 'brightnessHigh',
  BRIGHTNESS_LOW = 'brightnessLow',
  BRIGHTNESS_MEDIUM = 'brightnessMedium',
  BROKEN_IMAGE = 'brokenImage',
  BRUSH = 'brush',
  BUBBLE_CHART = 'bubbleChart',
  BUG_REPORT = 'bugReport',
  BUILD = 'build',
  BURST_MODE = 'burstMode',
  BUSINESS_CENTER = 'businessCenter',
  BUSINESS = 'business',
  CACHED = 'cached',
  CAKE = 'cake',
  CALCULATE = 'calculate',
  CALENDAR = 'calendar',
  CALENDAR_TODAY = 'calendarToday',
  CALENDAR_VIEW_DAY = 'calendarViewDay',
  CALL = 'call',
  CALL_END = 'callEnd',
  CALL_MADE = 'callMade',
  CALL_MERGE = 'callMerge',
  CALL_MISSED = 'callMissed',
  CALL_MISSED_OUTGOING = 'callMissedOutgoing',
  CALL_RECEIVED = 'callReceived',
  CALL_SPLIT = 'callSplit',
  CALL_TO_ACTION = 'callToAction',
  CAMERA_ALT = 'cameraAlt',
  CAMERA = 'camera',
  CAMERA_ENHANCE = 'cameraEnhance',
  CAMERA_FRONT = 'cameraFront',
  CAMERA_REAR = 'cameraRear',
  CAMERA_ROLL = 'cameraRoll',
  CANCEL = 'cancel',
  CANCEL_PRESENTATION = 'cancelPresentation',
  CANCEL_SCHEDULE_SEND = 'cancelScheduleSend',
  CARD_GIFTCARD = 'cardGiftcard',
  CARD_MEMBERSHIP = 'cardMembership',
  CARD_TRAVEL = 'cardTravel',
  CASINO = 'casino',
  CAST_CONNECTED = 'castConnected',
  CAST = 'cast',
  CATEGORY = 'category',
  CENTER_FOCUS_STRONG = 'centerFocusStrong',
  CENTER_FOCUS_WEAK = 'centerFocusWeak',
  CHANGE_HISTORY = 'changeHistory',
  CHAT_BUBBLE = 'chatBubble',
  CHAT_BUBBLE_OUTLINE = 'chatBubbleOutline',
  CHAT = 'chat',
  CHECK_BOX = 'checkBox',
  CHECK_BOX_OUTLINE_BLANK = 'checkBoxOutlineBlank',
  CHECK_CIRCLE = 'checkCircle',
  CHECK_CIRCLE_OUTLINE = 'checkCircleOutline',
  CHECK = 'check',
  CHECKMARK_CIRCLE = 'checkmarkCircle',
  CHEVRON_LEFT = 'chevronLeft',
  CHEVRON_RIGHT = 'chevronRight',
  CHILD_CARE = 'childCare',
  CHILD_FRIENDLY = 'childFriendly',
  CHROME_READER_MODE = 'chromeReaderMode',
  CLASS = 'class',
  CLEAR_ALL = 'clearAll',
  CLEAR = 'clear',
  CLOSE = 'close',
  CLOSED_CAPTION = 'closedCaption',
  CLOUD_CIRCLE = 'cloudCircle',
  CLOUD = 'cloud',
  CLOUD_DONE = 'cloudDone',
  CLOUD_DOWNLOAD = 'cloudDownload',
  CLOUD_HELP = 'cloudHelp',
  CLOUD_OFF = 'cloudOff',
  CLOUD_QUEUE = 'cloudQueue',
  CLOUD_UPLOAD = 'cloudUpload',
  CODE = 'code',
  COLLECTIONS_BOOKMARK = 'collectionsBookmark',
  COLLECTIONS = 'collections',
  COLOR_LENS = 'colorLens',
  COLORIZE = 'colorize',
  COMMENT = 'comment',
  COMMUTE = 'commute',
  COMPARE_ARROWS = 'compareArrows',
  COMPARE = 'compare',
  COMPASS_CALIBRATION = 'compassCalibration',
  COMPONENT1 = 'component1',
  COMPONENT2 = 'component2',
  COMPUTER = 'computer',
  CONFIRMATION_NUMBER = 'confirmationNumber',
  CONTACT_MAIL = 'contactMail',
  CONTACT_PAGE = 'contactPage',
  CONTACT_PHONE = 'contactPhone',
  CONTACT_SUPPORT = 'contactSupport',
  CONTACTLESS = 'contactless',
  CONTACTS = 'contacts',
  CONTROL_CAMERA = 'controlCamera',
  CONTROL_POINT = 'controlPoint',
  CONTROL_POINT_DUPLICATE = 'controlPointDuplicate',
  COPYRIGHT = 'copyright',
  CREATE = 'create',
  CREATE_NEW_FOLDER = 'createNewFolder',
  CREDIT_CARD = 'creditCard',
  CREDIT_SCORE = 'creditScore',
  CROP169 = 'crop169',
  CROP32 = 'crop32',
  CROP54 = 'crop54',
  CROP75 = 'crop75',
  CROP = 'crop',
  CROP_DIN = 'cropDin',
  CROP_FREE = 'cropFree',
  CROP_LANDSCAPE = 'cropLandscape',
  CROP_ORIGINAL = 'cropOriginal',
  CROP_PORTRAIT = 'cropPortrait',
  CROP_ROTATE = 'cropRotate',
  CROP_SQUARE = 'cropSquare',
  DASHBOARD = 'dashboard',
  DATA_USAGE = 'dataUsage',
  DATE_RANGE = 'dateRange',
  DECK = 'deck',
  DEHAZE = 'dehaze',
  DELETE = 'delete',
  DELETE_FOREVER = 'deleteForever',
  DELETE_OUTLINE = 'deleteOutline',
  DELETE_SWEEP = 'deleteSweep',
  DEPARTURE_BOARD = 'departureBoard',
  DESCRIPTION = 'description',
  DESKTOP_ACCESS_DISABLED = 'desktopAccessDisabled',
  DESKTOP_MAC = 'desktopMac',
  DESKTOP_WINDOWS = 'desktopWindows',
  DETAILS = 'details',
  DEVELOPER_BOARD = 'developerBoard',
  DEVELOPER_MODE = 'developerMode',
  DEVICE_HUB = 'deviceHub',
  DEVICE_UNKNOWN = 'deviceUnknown',
  DEVICES = 'devices',
  DEVICES_OTHER = 'devicesOther',
  DIALER_SIP = 'dialerSip',
  DIALPAD = 'dialpad',
  DIRECTIONS_BIKE = 'directionsBike',
  DIRECTIONS_BOAT = 'directionsBoat',
  DIRECTIONS_BUS = 'directionsBus',
  DIRECTIONS_CAR = 'directionsCar',
  DIRECTIONS = 'directions',
  DIRECTIONS_RAILWAY = 'directionsRailway',
  DIRECTIONS_RUN = 'directionsRun',
  DIRECTIONS_SUBWAY = 'directionsSubway',
  DIRECTIONS_TRANSIT = 'directionsTransit',
  DIRECTIONS_WALK = 'directionsWalk',
  DISC_FULL = 'discFull',
  DNS = 'dns',
  DOCK = 'dock',
  DOMAIN = 'domain',
  DOMAIN_DISABLED = 'domainDisabled',
  DONE_ALL = 'doneAll',
  DONE = 'done',
  DONE_OUTLINE = 'doneOutline',
  DONUT_LARGE = 'donutLarge',
  DONUT_SMALL = 'donutSmall',
  DOUBLE_ARROW = 'doubleArrow',
  DOT = 'dot',
  DRAFTS = 'drafts',
  DRAG_HANDLE = 'dragHandle',
  DRAG_INDICATOR = 'dragIndicator',
  DRIVE_ETA = 'driveEta',
  DUO = 'duo',
  DVR = 'dvr',
  DYNAMIC_FEED = 'dynamicFeed',
  ECO = 'eco',
  EDIT_ATTRIBUTES = 'editAttributes',
  EDIT = 'edit',
  EDIT_LOCATION = 'editLocation',
  EJECT = 'eject',
  EMAIL = 'email',
  EMOJI_EMOTIONS = 'emojiEmotions',
  EMOJI_EVENTS = 'emojiEvents',
  EMOJI_FLAGS = 'emojiFlags',
  EMOJI_FOOD_BEVERAGE = 'emojiFoodBeverage',
  EMOJI_NATURE = 'emojiNature',
  EMOJI_OBJECTS = 'emojiObjects',
  EMOJI_PEOPLE = 'emojiPeople',
  EMOJI_SYMBOLS = 'emojiSymbols',
  EMOJI_TRANSPORTATION = 'emojiTransportation',
  ENHANCED_ENCRYPTION = 'enhancedEncryption',
  ENTERPRISE = 'enterprise',
  EQUALIZER = 'equalizer',
  ERROR = 'error',
  ERROR_OUTLINE = 'errorOutline',
  EURO = 'euro',
  EURO_SYMBOL = 'euroSymbol',
  EV_STATION = 'evStation',
  EVENT_AVAILABLE = 'eventAvailable',
  EVENT_BUSY = 'eventBusy',
  EVENT = 'event',
  EVENT_NOTE = 'eventNote',
  EVENT_SEAT = 'eventSeat',
  EXIT_TO_APP = 'exitToApp',
  EXPAND_LESS = 'expandLess',
  EXPAND_MORE = 'expandMore',
  EXPLICIT = 'explicit',
  EXPLORE = 'explore',
  EXPLORE_OFF = 'exploreOff',
  EXPOSURE = 'exposure',
  EXPOSURE_NEG1 = 'exposureNeg1',
  EXPOSURE_NEG2 = 'exposureNeg2',
  EXPOSURE_PLUS1 = 'exposurePlus1',
  EXPOSURE_PLUS2 = 'exposurePlus2',
  EXPOSURE_ZERO = 'exposureZero',
  EXTENSION = 'extension',
  FACE = 'face',
  FAST_FORWARD = 'fastForward',
  FAST_REWIND = 'fastRewind',
  FASTFOOD = 'fastfood',
  FAVORITE_BORDER = 'favoriteBorder',
  FAVORITE = 'favorite',
  FEATURED_PLAY_LIST = 'featuredPlayList',
  FEATURED_VIDEO = 'featuredVideo',
  FEEDBACK = 'feedback',
  FIBER_DVR = 'fiberDvr',
  FIBER_MANUAL_RECORD = 'fiberManualRecord',
  FIBER_NEW = 'fiberNew',
  FIBER_PIN = 'fiberPin',
  FIBER_SMART_RECORD = 'fiberSmartRecord',
  FILE1 = 'file1',
  FILE_COPY = 'fileCopy',
  FILE_SAVE = 'fileSave',
  FILTER1 = 'filter1',
  FILTER2 = 'filter2',
  FILTER3 = 'filter3',
  FILTER4 = 'filter4',
  FILTER5 = 'filter5',
  FILTER6 = 'filter6',
  FILTER7 = 'filter7',
  FILTER8 = 'filter8',
  FILTER9 = 'filter9',
  FILTER9_PLUS = 'filter9Plus',
  FILTER_B_AND_W = 'filterBAndW',
  FILTER_CENTER_FOCUS = 'filterCenterFocus',
  FILTER = 'filter',
  FILTER_DRAMA = 'filterDrama',
  FILTER_FRAMES = 'filterFrames',
  FILTER_HDR = 'filterHdr',
  FILTER_LIST = 'filterList',
  FILTER_NONE = 'filterNone',
  FILTER_TILT_SHIFT = 'filterTiltShift',
  FILTER_VINTAGE = 'filterVintage',
  FIND_IN_PAGE = 'findInPage',
  FIND_REPLACE = 'findReplace',
  FINGERPRINT = 'fingerprint',
  FIREPLACE = 'fireplace',
  FIRST_PAGE = 'firstPage',
  FITNESS_CENTER = 'fitnessCenter',
  FLAG = 'flag',
  FLARE = 'flare',
  FLASH_AUTO = 'flashAuto',
  FLASH_OFF = 'flashOff',
  FLASH_ON = 'flashOn',
  FLIGHT = 'flight',
  FLIGHT_LAND = 'flightLand',
  FLIGHT_TAKEOFF = 'flightTakeoff',
  FLIP_CAMERA_ANDROID = 'flipCameraAndroid',
  FLIP_CAMERA_IOS = 'flipCameraIos',
  FLIP = 'flip',
  FLIP_TO_BACK = 'flipToBack',
  FLIP_TO_FRONT = 'flipToFront',
  FOLDER = 'folder',
  FOLDER_OPEN = 'folderOpen',
  FOLDER_SHARED = 'folderShared',
  FOLDER_SPECIAL = 'folderSpecial',
  FONT_DOWNLOAD = 'fontDownload',
  FORMAT_ALIGN_CENTER = 'formatAlignCenter',
  FORMAT_ALIGN_JUSTIFY = 'formatAlignJustify',
  FORMAT_ALIGN_LEFT = 'formatAlignLeft',
  FORMAT_ALIGN_RIGHT = 'formatAlignRight',
  FORMAT_BOLD = 'formatBold',
  FORMAT_CLEAR = 'formatClear',
  FORMAT_COLOR_RESET = 'formatColorReset',
  FORMAT_INDENT_DECREASE = 'formatIndentDecrease',
  FORMAT_INDENT_INCREASE = 'formatIndentIncrease',
  FORMAT_ITALIC = 'formatItalic',
  FORMAT_LINE_SPACING = 'formatLineSpacing',
  FORMAT_LIST_BULLETED = 'formatListBulleted',
  FORMAT_LIST_NUMBERED = 'formatListNumbered',
  FORMAT_LIST_NUMBERED_RTL = 'formatListNumberedRtl',
  FORMAT_PAINT = 'formatPaint',
  FORMAT_QUOTE = 'formatQuote',
  FORMAT_SHAPES = 'formatShapes',
  FORMAT_SIZE = 'formatSize',
  FORMAT_STRIKETHROUGH = 'formatStrikethrough',
  FORMAT_TEXTDIRECTION_L_TO_R = 'formatTextdirectionLToR',
  FORMAT_TEXTDIRECTION_R_TO_L = 'formatTextdirectionRToL',
  FORMAT_UNDERLINED = 'formatUnderlined',
  FORUM = 'forum',
  FORWARD10 = 'forward10',
  FORWARD30 = 'forward30',
  FORWARD5 = 'forward5',
  FORWARD = 'forward',
  FREE_BREAKFAST = 'freeBreakfast',
  FULLSCREEN = 'fullscreen',
  FULLSCREEN_EXIT = 'fullscreenExit',
  FUNCTIONS = 'functions',
  G_TRANSLATE = 'gTranslate',
  GAMEPAD = 'gamepad',
  GAMES = 'games',
  GAVEL = 'gavel',
  GESTURE = 'gesture',
  GET_APP = 'getApp',
  GIF = 'gif',
  GOLF_COURSE = 'golfCourse',
  GPS_FIXED = 'gpsFixed',
  GPS_NOT_FIXED = 'gpsNotFixed',
  GPS_OFF = 'gpsOff',
  GRADE = 'grade',
  GRADIENT = 'gradient',
  GRAIN = 'grain',
  GRAPHIC_EQ = 'graphicEq',
  GRID_OFF = 'gridOff',
  GRID_ON = 'gridOn',
  GROUP_ADD = 'groupAdd',
  GROUP = 'group',
  GROUP_WORK = 'groupWork',
  HD = 'hd',
  HDR_OFF = 'hdrOff',
  HDR_ON = 'hdrOn',
  HDR_STRONG = 'hdrStrong',
  HDR_WEAK = 'hdrWeak',
  HEADSET = 'headset',
  HEADSET_MIC = 'headsetMic',
  HEALING = 'healing',
  HEARING = 'hearing',
  HEIGHT = 'height',
  HELP = 'help',
  HELP_OUTLINE = 'helpOutline',
  HIGH_QUALITY = 'highQuality',
  HIGHLIGHT = 'highlight',
  HIGHLIGHT_OFF = 'highlightOff',
  HISTORY = 'history',
  HOME = 'home',
  HOME_REPAIR_SERVICE = 'homeRepairService',
  HOME_WORK = 'homeWork',
  HORIZONTAL_SPLIT = 'horizontalSplit',
  HOT_TUB = 'hotTub',
  HOTEL = 'hotel',
  HOURGLASS_EMPTY = 'hourglassEmpty',
  HOURGLASS_FULL = 'hourglassFull',
  HOUSE = 'house',
  HOW_TO_REG = 'howToReg',
  HOW_TO_VOTE = 'howToVote',
  HTTP = 'http',
  HTTPS = 'https',
  IMAGE_ASPECT_RATIO = 'imageAspectRatio',
  IMAGE = 'image',
  IMAGE_SEARCH = 'imageSearch',
  IMPORT_CONTACTS = 'importContacts',
  IMPORT_EXPORT = 'importExport',
  IMPORTANT_DEVICES = 'importantDevices',
  INBOX = 'inbox',
  INDETERMINATE_CHECK_BOX = 'indeterminateCheckBox',
  INFO = 'info',
  INPUT = 'input',
  INSERT_CHART = 'insertChart',
  INSERT_CHART_OUTLINED = 'insertChartOutlined',
  INSERT_COMMENT = 'insertComment',
  INSERT_DRIVE_FILE = 'insertDriveFile',
  INSERT_EMOTICON = 'insertEmoticon',
  INSERT_INVITATION = 'insertInvitation',
  INSERT_LINK = 'insertLink',
  INSERT_PHOTO = 'insertPhoto',
  INVERT_COLORS = 'invertColors',
  INVERT_COLORS_OFF = 'invertColorsOff',
  ISO = 'iso',
  KEYBOARD_ARROW_DOWN = 'keyboardArrowDown',
  KEYBOARD_ARROW_LEFT = 'keyboardArrowLeft',
  KEYBOARD_ARROW_RIGHT = 'keyboardArrowRight',
  KEYBOARD_ARROW_UP = 'keyboardArrowUp',
  KEYBOARD_BACKSPACE = 'keyboardBackspace',
  KEYBOARD_CAPSLOCK = 'keyboardCapslock',
  KEYBOARD = 'keyboard',
  KEYBOARD_HIDE = 'keyboardHide',
  KEYBOARD_RETURN = 'keyboardReturn',
  KEYBOARD_TAB = 'keyboardTab',
  KEYBOARD_VOICE = 'keyboardVoice',
  KING_BED = 'kingBed',
  KITCHEN = 'kitchen',
  LABEL = 'label',
  LABEL_IMPORTANT = 'labelImportant',
  LABEL_OFF = 'labelOff',
  LANDSCAPE = 'landscape',
  LANGUAGE = 'language',
  LAPTOP_CHROMEBOOK = 'laptopChromebook',
  LAPTOP = 'laptop',
  LAPTOP_MAC = 'laptopMac',
  LAPTOP_WINDOWS = 'laptopWindows',
  LAST_PAGE = 'lastPage',
  LAUNCH = 'launch',
  LAYERS_CLEAR = 'layersClear',
  LAYERS = 'layers',
  LEAK_ADD = 'leakAdd',
  LEAK_REMOVE = 'leakRemove',
  LENS = 'lens',
  LIBRARY_ADD_CHECK = 'libraryAddCheck',
  LIBRARY_ADD = 'libraryAdd',
  LIBRARY_BOOKS = 'libraryBooks',
  LIBRARY_MUSIC = 'libraryMusic',
  LINE_STYLE = 'lineStyle',
  LINE_WEIGHT = 'lineWeight',
  LINEAR_SCALE = 'linearScale',
  LINK = 'link',
  LINK_OFF = 'linkOff',
  LINKED_CAMERA = 'linkedCamera',
  LIST_ALT = 'listAlt',
  LIST = 'list',
  LIVE_HELP = 'liveHelp',
  LIVE_TV = 'liveTv',
  LOCAL_ACTIVITY = 'localActivity',
  LOCAL_AIRPORT = 'localAirport',
  LOCAL_ATM = 'localAtm',
  LOCAL_BAR = 'localBar',
  LOCAL_CAFE = 'localCafe',
  LOCAL_CAR_WASH = 'localCarWash',
  LOCAL_CONVENIENCE_STORE = 'localConvenienceStore',
  LOCAL_DINING = 'localDining',
  LOCAL_DRINK = 'localDrink',
  LOCAL_FLORIST = 'localFlorist',
  LOCAL_GAS_STATION = 'localGasStation',
  LOCAL_GROCERY_STORE = 'localGroceryStore',
  LOCAL_HOSPITAL = 'localHospital',
  LOCAL_HOTEL = 'localHotel',
  LOCAL_LAUNDRY_SERVICE = 'localLaundryService',
  LOCAL_LIBRARY = 'localLibrary',
  LOCAL_MALL = 'localMall',
  LOCAL_MOVIES = 'localMovies',
  LOCAL_OFFER = 'localOffer',
  LOCAL_PARKING = 'localParking',
  LOCAL_PHARMACY = 'localPharmacy',
  LOCAL_PHONE = 'localPhone',
  LOCAL_PIZZA = 'localPizza',
  LOCAL_PLAY = 'localPlay',
  LOCAL_POST_OFFICE = 'localPostOffice',
  LOCAL_PRINTSHOP = 'localPrintshop',
  LOCAL_SEE = 'localSee',
  LOCAL_SHIPPING = 'localShipping',
  LOCAL_TAXI = 'localTaxi',
  LOCATION_CITY = 'locationCity',
  LOCATION_DISABLED = 'locationDisabled',
  LOCATION_OFF = 'locationOff',
  LOCATION_ON = 'locationOn',
  LOCATION_SEARCHING = 'locationSearching',
  LOCK = 'lock',
  LOCK_OPEN = 'lockOpen',
  LOOKS3 = 'looks3',
  LOOKS4 = 'looks4',
  LOOKS5 = 'looks5',
  LOOKS6 = 'looks6',
  LOOKS = 'looks',
  LOOKS_ONE = 'looksOne',
  LOOKS_TWO = 'looksTwo',
  LOOP = 'loop',
  LOUPE = 'loupe',
  LOW_PRIORITY = 'lowPriority',
  LOYALTY = 'loyalty',
  MAIL = 'mail',
  MAIL_OUTLINE = 'mailOutline',
  MAP = 'map',
  MARKUNREAD = 'markunread',
  MARKUNREAD_MAILBOX = 'markunreadMailbox',
  MAXIMIZE = 'maximize',
  MEETING_ROOM = 'meetingRoom',
  MEMORY = 'memory',
  MENU_BOOK = 'menuBook',
  MENU = 'menu',
  MENU_OPEN = 'menuOpen',
  MERGE_TYPE = 'mergeType',
  MESSAGE = 'message',
  MIC = 'mic',
  MIC_NONE = 'micNone',
  MIC_OFF = 'micOff',
  MINIMIZE = 'minimize',
  MISSED_VIDEO_CALL = 'missedVideoCall',
  MMS = 'mms',
  MOBILE_FRIENDLY = 'mobileFriendly',
  MOBILE_OFF = 'mobileOff',
  MOBILE_SCREEN_SHARE = 'mobileScreenShare',
  MODE_COMMENT = 'modeComment',
  MONETIZATION_ON = 'monetizationOn',
  MONEY = 'money',
  MONEY_OFF = 'moneyOff',
  MONOCHROME_PHOTOS = 'monochromePhotos',
  MOOD_BAD = 'moodBad',
  MOOD = 'mood',
  MORE = 'more',
  MORE_HORIZ = 'moreHoriz',
  MORE_VERT = 'moreVert',
  MOUSE = 'mouse',
  MOVE_TO_INBOX = 'moveToInbox',
  MOVIE = 'movie',
  MOVIE_CREATION = 'movieCreation',
  MOVIE_FILTER = 'movieFilter',
  MULTILINE_CHART = 'multilineChart',
  MUSEUM = 'museum',
  MUSIC_NOTE = 'musicNote',
  MUSIC_OFF = 'musicOff',
  MUSIC_VIDEO = 'musicVideo',
  MY_LOCATION = 'myLocation',
  NATURE = 'nature',
  NATURE_PEOPLE = 'naturePeople',
  NAVIGATE_BEFORE = 'navigateBefore',
  NAVIGATE_NEXT = 'navigateNext',
  NAVIGATION = 'navigation',
  NEAR_ME = 'nearMe',
  NETWORK_CHECK = 'networkCheck',
  NETWORK_LOCKED = 'networkLocked',
  NEW_RELEASES = 'newReleases',
  NEXT_WEEK = 'nextWeek',
  NFC = 'nfc',
  NIGHTS_STAY = 'nightsStay',
  NO_ENCRYPTION = 'noEncryption',
  NO_MEETING_ROOM = 'noMeetingRoom',
  NO_SIM = 'noSim',
  NOT_INTERESTED = 'notInterested',
  NOT_LISTED_LOCATION = 'notListedLocation',
  NOTE_ADD = 'noteAdd',
  NOTE = 'note',
  NOTES = 'notes',
  NOTIFICATION_IMPORTANT = 'notificationImportant',
  NOTIFICATIONS_ACTIVE = 'notificationsActive',
  NOTIFICATIONS = 'notifications',
  NOTIFICATIONS_NONE = 'notificationsNone',
  NOTIFICATIONS_OFF = 'notificationsOff',
  NOTIFICATIONS_PAUSED = 'notificationsPaused',
  OFFLINE_BOLT = 'offlineBolt',
  OFFLINE_PIN = 'offlinePin',
  ONDEMAND_VIDEO = 'ondemandVideo',
  OPACITY = 'opacity',
  OPEN_IN_BROWSER = 'openInBrowser',
  OPEN_IN_NEW = 'openInNew',
  OPEN_WITH = 'openWith',
  OUTDOOR_GRILL = 'outdoorGrill',
  OUTLINED_FLAG = 'outlinedFlag',
  PAGES = 'pages',
  PAGEVIEW = 'pageview',
  PALETTE = 'palette',
  PAN_TOOL = 'panTool',
  PANORAMA = 'panorama',
  PANORAMA_FISH_EYE = 'panoramaFishEye',
  PANORAMA_HORIZONTAL = 'panoramaHorizontal',
  PANORAMA_VERTICAL = 'panoramaVertical',
  PANORAMA_WIDE_ANGLE = 'panoramaWideAngle',
  PARTY_MODE = 'partyMode',
  PAUSE_CIRCLE_FILLED = 'pauseCircleFilled',
  PAUSE_CIRCLE_OUTLINE = 'pauseCircleOutline',
  PAUSE = 'pause',
  PAUSE_PRESENTATION = 'pausePresentation',
  PAYMENT = 'payment',
  PAYMENTS = 'payments',
  PEOPLE_ALT = 'peopleAlt',
  PEOPLE = 'people',
  PEOPLE_OUTLINE = 'peopleOutline',
  PERM_CAMERA_MIC = 'permCameraMic',
  PERM_CONTACT_CALENDAR = 'permContactCalendar',
  PERM_DATA_SETTING = 'permDataSetting',
  PERM_DEVICE_INFORMATION = 'permDeviceInformation',
  PERM_IDENTITY = 'permIdentity',
  PERM_MEDIA = 'permMedia',
  PERM_PHONE_MSG = 'permPhoneMsg',
  PERM_SCAN_WIFI = 'permScanWifi',
  PERSON_ADD = 'personAdd',
  PERSON_ADD_DISABLED = 'personAddDisabled',
  PERSON = 'person',
  PERSON_OUTLINE = 'personOutline',
  PERSON_PIN_CIRCLE = 'personPinCircle',
  PERSON_PIN = 'personPin',
  PERSONAL_VIDEO = 'personalVideo',
  PETS = 'pets',
  PHONE_ANDROID = 'phoneAndroid',
  PHONE_BLUETOOTH_SPEAKER = 'phoneBluetoothSpeaker',
  PHONE_CALLBACK = 'phoneCallback',
  PHONE = 'phone',
  PHONE_DISABLED = 'phoneDisabled',
  PHONE_ENABLED = 'phoneEnabled',
  PHONE_FORWARDED = 'phoneForwarded',
  PHONE_IN_TALK = 'phoneInTalk',
  PHONE_IPHONE = 'phoneIphone',
  PHONE_LOCKED = 'phoneLocked',
  PHONE_MISSED = 'phoneMissed',
  PHONE_PAUSED = 'phonePaused',
  PHONELINK = 'phonelink',
  PHONELINK_ERASE = 'phonelinkErase',
  PHONELINK_LOCK = 'phonelinkLock',
  PHONELINK_OFF = 'phonelinkOff',
  PHONELINK_RING = 'phonelinkRing',
  PHONELINK_SETUP = 'phonelinkSetup',
  PHOTO_ALBUM = 'photoAlbum',
  PHOTO_CAMERA = 'photoCamera',
  PHOTO = 'photo',
  PHOTO_FILTER = 'photoFilter',
  PHOTO_LIBRARY = 'photoLibrary',
  PHOTO_SIZE_SELECT_ACTUAL = 'photoSizeSelectActual',
  PHOTO_SIZE_SELECT_LARGE = 'photoSizeSelectLarge',
  PHOTO_SIZE_SELECT_SMALL = 'photoSizeSelectSmall',
  PICTURE_AS_PDF = 'pictureAsPdf',
  PIE_CHART = 'pieChart',
  PIN_DROP = 'pinDrop',
  PLACE = 'place',
  PLAY_ARROW = 'playArrow',
  PLAY_CIRCLE_FILLED = 'playCircleFilled',
  PLAY_CIRCLE_OUTLINE = 'playCircleOutline',
  PLAY_FOR_WORK = 'playForWork',
  PLAYLIST_ADD_CHECK = 'playlistAddCheck',
  PLAYLIST_ADD = 'playlistAdd',
  PLAYLIST_PLAY = 'playlistPlay',
  PLUS = 'plus',
  PLUS_ONE = 'plusOne',
  POLICY = 'policy',
  POLL = 'poll',
  POLYMER = 'polymer',
  POOL = 'pool',
  PORTABLE_WIFI_OFF = 'portableWifiOff',
  PORTRAIT = 'portrait',
  POST_ADD = 'postAdd',
  POWER = 'power',
  POWER_INPUT = 'powerInput',
  POWER_OFF = 'powerOff',
  POWER_SETTINGS_NEW = 'powerSettingsNew',
  PREGNANT_WOMAN = 'pregnantWoman',
  PRESENT_TO_ALL = 'presentToAll',
  PRINT = 'print',
  PRINT_DISABLED = 'printDisabled',
  PRIORITY_HIGH = 'priorityHigh',
  PUBLIC = 'public',
  PUBLISH = 'publish',
  QUERY_BUILDER = 'queryBuilder',
  QUESTION_ANSWER = 'questionAnswer',
  QUEUE = 'queue',
  QUEUE_MUSIC = 'queueMusic',
  QUEUE_PLAY_NEXT = 'queuePlayNext',
  RADIO_BUTTON_CHECKED = 'radioButtonChecked',
  RADIO_BUTTON_UNCHECKED = 'radioButtonUnchecked',
  RADIO = 'radio',
  RATE_REVIEW = 'rateReview',
  RECEIPT = 'receipt',
  RECENT_ACTORS = 'recentActors',
  RECORD_VOICE_OVER = 'recordVoiceOver',
  REDEEM = 'redeem',
  REDO = 'redo',
  REFRESH = 'refresh',
  REMOVE_CIRCLE = 'removeCircle',
  REMOVE_CIRCLE_OUTLINE = 'removeCircleOutline',
  REMOVE = 'remove',
  REMOVE_FROM_QUEUE = 'removeFromQueue',
  REMOVE_RED_EYE = 'removeRedEye',
  REMOVE_SHOPPING_CART = 'removeShoppingCart',
  REORDER = 'reorder',
  REPEAT = 'repeat',
  REPEAT_ONE = 'repeatOne',
  REPLAY10 = 'replay10',
  REPLAY30 = 'replay30',
  REPLAY5 = 'replay5',
  REPLAY = 'replay',
  REPLY_ALL = 'replyAll',
  REPLY = 'reply',
  REPORT = 'report',
  REPORT_OFF = 'reportOff',
  REPORT_PROBLEM = 'reportProblem',
  RESTAURANT = 'restaurant',
  RESTAURANT_MENU = 'restaurantMenu',
  RESTORE = 'restore',
  RESTORE_FROM_TRASH = 'restoreFromTrash',
  RESTORE_PAGE = 'restorePage',
  RING_VOLUME = 'ringVolume',
  ROOM = 'room',
  ROOM_SERVICE = 'roomService',
  ROTATE90_DEGREES_CCW = 'rotate90DegreesCcw',
  ROTATE_LEFT = 'rotateLeft',
  ROTATE_RIGHT = 'rotateRight',
  ROUNDED_CORNER = 'roundedCorner',
  ROUTER = 'router',
  ROWING = 'rowing',
  RSS_FEED = 'rssFeed',
  RV_HOOKUP = 'rvHookup',
  SAILING = 'sailing',
  SATELLITE = 'satellite',
  SAVE_ALT = 'saveAlt',
  SAVE = 'save',
  SCANNER = 'scanner',
  SCATTER_PLOT = 'scatterPlot',
  SCHEDULE = 'schedule',
  SCHOOL = 'school',
  SCORE = 'score',
  SCREEN_LOCK_LANDSCAPE = 'screenLockLandscape',
  SCREEN_LOCK_PORTRAIT = 'screenLockPortrait',
  SCREEN_LOCK_ROTATION = 'screenLockRotation',
  SCREEN_ROTATION = 'screenRotation',
  SCREEN_SHARE = 'screenShare',
  SD_CARD = 'sdCard',
  SD_STORAGE = 'sdStorage',
  SEARCH = 'search',
  SECURITY = 'security',
  SELECT_ALL = 'selectAll',
  SEND = 'send',
  SENTIMENT_DISSATISFIED = 'sentimentDissatisfied',
  SENTIMENT_SATISFIED_ALT = 'sentimentSatisfiedAlt',
  SENTIMENT_SATISFIED = 'sentimentSatisfied',
  SENTIMENT_VERY_DISSATISFIED = 'sentimentVeryDissatisfied',
  SENTIMENT_VERY_SATISFIED = 'sentimentVerySatisfied',
  SETTINGS_APPLICATIONS = 'settingsApplications',
  SETTINGS_BACKUP_RESTORE = 'settingsBackupRestore',
  SETTINGS_BLUETOOTH = 'settingsBluetooth',
  SETTINGS_BRIGHTNESS = 'settingsBrightness',
  SETTINGS_CELL = 'settingsCell',
  SETTINGS = 'settings',
  SETTINGS_ETHERNET = 'settingsEthernet',
  SETTINGS_INPUT_ANTENNA = 'settingsInputAntenna',
  SETTINGS_INPUT_COMPONENT = 'settingsInputComponent',
  SETTINGS_INPUT_COMPOSITE = 'settingsInputComposite',
  SETTINGS_INPUT_HDMI = 'settingsInputHdmi',
  SETTINGS_INPUT_SVIDEO = 'settingsInputSvideo',
  SETTINGS_OVERSCAN = 'settingsOverscan',
  SETTINGS_PHONE = 'settingsPhone',
  SETTINGS_POWER = 'settingsPower',
  SETTINGS_REMOTE = 'settingsRemote',
  SETTINGS_SYSTEM_DAYDREAM = 'settingsSystemDaydream',
  SETTINGS_VOICE = 'settingsVoice',
  SHARE = 'share',
  SHIELD_WITH_HEART = 'shieldWithHeart',
  SHOP = 'shop',
  SHOP_TWO = 'shopTwo',
  SHOPPING_BASKET = 'shoppingBasket',
  SHOPPING_CART = 'shoppingCart',
  SHORT_TEXT = 'shortText',
  SHOW_CHART = 'showChart',
  SHUFFLE = 'shuffle',
  SHUTTER_SPEED = 'shutterSpeed',
  SIGNAL_CELLULAR4_BAR = 'signalCellular4Bar',
  SIGNAL_CELLULAR_ALT = 'signalCellularAlt',
  SIGNAL_CELLULAR_CONNECTED_NO_INTERNET4_BAR = 'signalCellularConnectedNoInternet4Bar',
  SIGNAL_CELLULAR_NO_SIM = 'signalCellularNoSim',
  SIGNAL_CELLULAR_NULL = 'signalCellularNull',
  SIGNAL_CELLULAR_OFF = 'signalCellularOff',
  SIGNAL_WIFI4_BAR = 'signalWifi4Bar',
  SIGNAL_WIFI4_BAR_LOCK = 'signalWifi4BarLock',
  SIGNAL_WIFI_OFF = 'signalWifiOff',
  SIM_CARD = 'simCard',
  SINGLE_BED = 'singleBed',
  SKIP_NEXT = 'skipNext',
  SKIP_PREVIOUS = 'skipPrevious',
  SLIDESHOW = 'slideshow',
  SLOW_MOTION_VIDEO = 'slowMotionVideo',
  SMARTPHONE = 'smartphone',
  SMOKE_FREE = 'smokeFree',
  SMOKING_ROOMS = 'smokingRooms',
  SMS = 'sms',
  SMS_FAILED = 'smsFailed',
  SNOOZE = 'snooze',
  SORT_BY_ALPHA = 'sortByAlpha',
  SORT = 'sort',
  SPA = 'spa',
  SPACE_BAR = 'spaceBar',
  SPEAKER = 'speaker',
  SPEAKER_GROUP = 'speakerGroup',
  SPEAKER_NOTES = 'speakerNotes',
  SPEAKER_NOTES_OFF = 'speakerNotesOff',
  SPEAKER_PHONE = 'speakerPhone',
  SPEED = 'speed',
  SPELLCHECK = 'spellcheck',
  SPORTS_BASEBALL = 'sportsBaseball',
  SPORTS_BASKETBALL = 'sportsBasketball',
  SPORTS = 'sports',
  SPORTS_CRICKET = 'sportsCricket',
  SPORTS_ESPORTS = 'sportsEsports',
  SPORTS_FOOTBALL = 'sportsFootball',
  SPORTS_GOLF = 'sportsGolf',
  SPORTS_HANDBALL = 'sportsHandball',
  SPORTS_HOCKEY = 'sportsHockey',
  SPORTS_KABADDI = 'sportsKabaddi',
  SPORTS_MMA = 'sportsMma',
  SPORTS_MOTORSPORTS = 'sportsMotorsports',
  SPORTS_RUGBY = 'sportsRugby',
  SPORTS_SOCCER = 'sportsSoccer',
  SPORTS_TENNIS = 'sportsTennis',
  SPORTS_VOLLEYBALL = 'sportsVolleyball',
  SQUARE_FOOT = 'squareFoot',
  STAR_BORDER = 'starBorder',
  STAR = 'star',
  STAR_HALF = 'starHalf',
  STAR_OUTLINE = 'starOutline',
  STARS = 'stars',
  STAY_CURRENT_LANDSCAPE = 'stayCurrentLandscape',
  STAY_CURRENT_PORTRAIT = 'stayCurrentPortrait',
  STAY_PRIMARY_LANDSCAPE = 'stayPrimaryLandscape',
  STAY_PRIMARY_PORTRAIT = 'stayPrimaryPortrait',
  STOP = 'stop',
  STOP_SCREEN_SHARE = 'stopScreenShare',
  STORAGE = 'storage',
  STORE = 'store',
  STORE_MALL_DIRECTORY = 'storeMallDirectory',
  STOREFRONT = 'storefront',
  STRAIGHTEN = 'straighten',
  STREETVIEW = 'streetview',
  STRIKETHROUGH_S = 'strikethroughS',
  STYLE = 'style',
  SUBDIRECTORY_ARROW_LEFT = 'subdirectoryArrowLeft',
  SUBDIRECTORY_ARROW_RIGHT = 'subdirectoryArrowRight',
  SUBJECT = 'subject',
  SUBSCRIPTIONS = 'subscriptions',
  SUBTITLES = 'subtitles',
  SUBWAY = 'subway',
  SUPERVISED_USER_CIRCLE = 'supervisedUserCircle',
  SUPERVISOR_ACCOUNT = 'supervisorAccount',
  SURROUND_SOUND = 'surroundSound',
  SWAP_CALLS = 'swapCalls',
  SWAP_HORIZ = 'swapHoriz',
  SWAP_HORIZONTAL_CIRCLE = 'swapHorizontalCircle',
  SWAP_VERT = 'swapVert',
  SWAP_VERTICAL_CIRCLE = 'swapVerticalCircle',
  SWITCH_CAMERA = 'switchCamera',
  SWITCH_VIDEO = 'switchVideo',
  SYNC_ALT = 'syncAlt',
  SYNC = 'sync',
  SYNC_DISABLED = 'syncDisabled',
  SYNC_PROBLEM = 'syncProblem',
  SYSTEM_UPDATE_ALT = 'systemUpdateAlt',
  SYSTEM_UPDATE = 'systemUpdate',
  TAB = 'tab',
  TAB_UNSELECTED = 'tabUnselected',
  TABLE_CHART = 'tableChart',
  TABLET_ANDROID = 'tabletAndroid',
  TABLET = 'tablet',
  TABLET_MAC = 'tabletMac',
  TAG_FACES = 'tagFaces',
  TAP_AND_PLAY = 'tapAndPlay',
  TASK = 'task',
  TERRAIN = 'terrain',
  TEXT_FIELDS = 'textFields',
  TEXT_FORMAT = 'textFormat',
  TEXT_ROTATE_UP = 'textRotateUp',
  TEXT_ROTATE_VERTICAL = 'textRotateVertical',
  TEXT_ROTATION_ANGLEDOWN = 'textRotationAngledown',
  TEXT_ROTATION_ANGLEUP = 'textRotationAngleup',
  TEXT_ROTATION_DOWN = 'textRotationDown',
  TEXT_ROTATION_NONE = 'textRotationNone',
  TEXTSMS = 'textsms',
  TEXTURE = 'texture',
  THEATERS = 'theaters',
  THUMB_DOWN_ALT = 'thumbDownAlt',
  THUMB_DOWN = 'thumbDown',
  THUMB_UP_ALT = 'thumbUpAlt',
  THUMB_UP = 'thumbUp',
  THUMBS_UP_DOWN = 'thumbsUpDown',
  TIME_TO_LEAVE = 'timeToLeave',
  TIMELAPSE = 'timelapse',
  TIMELINE = 'timeline',
  TIMER10 = 'timer10',
  TIMER3 = 'timer3',
  TIMER = 'timer',
  TIMER_OFF = 'timerOff',
  TITLE = 'title',
  TOC = 'toc',
  TODAY = 'today',
  TOGGLE_OFF = 'toggleOff',
  TOGGLE_ON = 'toggleOn',
  TOLL = 'toll',
  TONALITY = 'tonality',
  TOUCH_APP = 'touchApp',
  TOYS = 'toys',
  TRACK_CHANGES = 'trackChanges',
  TRAFFIC = 'traffic',
  TRAIN = 'train',
  TRAM = 'tram',
  TRANSFER_WITHIN_A_STATION = 'transferWithinAStation',
  TRANSFORM = 'transform',
  TRANSIT_ENTEREXIT = 'transitEnterexit',
  TRANSLATE = 'translate',
  TRENDING_DOWN = 'trendingDown',
  TRENDING_FLAT = 'trendingFlat',
  TRENDING_UP = 'trendingUp',
  TRIP_ORIGIN = 'tripOrigin',
  TUNE = 'tune',
  TURNED_IN = 'turnedIn',
  TURNED_IN_NOT = 'turnedInNot',
  TV = 'tv',
  TV_OFF = 'tvOff',
  TWO_WHEELER = 'twoWheeler',
  UNARCHIVE = 'unarchive',
  UNDO = 'undo',
  UNFOLD_LESS = 'unfoldLess',
  UNFOLD_MORE = 'unfoldMore',
  UNSUBSCRIBE = 'unsubscribe',
  UPDATE = 'update',
  USB = 'usb',
  VERIFIED_USER = 'verifiedUser',
  VERTICAL_ALIGN_BOTTOM = 'verticalAlignBottom',
  VERTICAL_ALIGN_CENTER = 'verticalAlignCenter',
  VERTICAL_ALIGN_TOP = 'verticalAlignTop',
  VERTICAL_SPLIT = 'verticalSplit',
  VIBRATION = 'vibration',
  VIDEO_CALL = 'videoCall',
  VIDEO_LABEL = 'videoLabel',
  VIDEO_LIBRARY = 'videoLibrary',
  VIDEOCAM = 'videocam',
  VIDEOCAM_OFF = 'videocamOff',
  VIDEOGAME_ASSET = 'videogameAsset',
  VIEW_AGENDA = 'viewAgenda',
  VIEW_ARRAY = 'viewArray',
  VIEW_CAROUSEL = 'viewCarousel',
  VIEW_COLUMN = 'viewColumn',
  VIEW_COMFY = 'viewComfy',
  VIEW_COMPACT = 'viewCompact',
  VIEW_DAY = 'viewDay',
  VIEW_HEADLINE = 'viewHeadline',
  VIEW_LIST = 'viewList',
  VIEW_MODULE = 'viewModule',
  VIEW_QUILT = 'viewQuilt',
  VIEW_STREAM = 'viewStream',
  VIEW_WEEK = 'viewWeek',
  VIGNETTE = 'vignette',
  VISIBILITY = 'visibility',
  VISIBILITY_OFF = 'visibilityOff',
  VOICE_CHAT = 'voiceChat',
  VOICE_OVER_OFF = 'voiceOverOff',
  VOICEMAIL = 'voicemail',
  VOLUME_DOWN = 'volumeDown',
  VOLUME_MUTE = 'volumeMute',
  VOLUME_OFF = 'volumeOff',
  VOLUME_UP = 'volumeUp',
  VPN_KEY = 'vpnKey',
  VPN_LOCK = 'vpnLock',
  WALLPAPER = 'wallpaper',
  WARNING_CIRCLE = 'warningCircle',
  WARNING = 'warning',
  WATCH = 'watch',
  WATCH_LATER = 'watchLater',
  WAVES = 'waves',
  WB_AUTO = 'wbAuto',
  WB_CLOUDY = 'wbCloudy',
  WB_INCANDESCENT = 'wbIncandescent',
  WB_IRIDESCENT = 'wbIridescent',
  WB_SUNNY = 'wbSunny',
  WC = 'wc',
  WEB_ASSET = 'webAsset',
  WEB = 'web',
  WEEKEND = 'weekend',
  WHATSHOT = 'whatshot',
  WHERE_TO_VOTE = 'whereToVote',
  WIDGETS = 'widgets',
  WIFI = 'wifi',
  WIFI_LOCK = 'wifiLock',
  WIFI_OFF = 'wifiOff',
  WIFI_TETHERING = 'wifiTethering',
  WORK = 'work',
  WORK_HISTORY = 'workHistory',
  WORK_OFF = 'workOff',
  WORK_OUTLINE = 'workOutline',
  WRAP_TEXT = 'wrapText',
  YOUTUBE_SEARCHED_FOR = 'youtubeSearchedFor',
  ZOOM_IN = 'zoomIn',
  ZOOM_OUT = 'zoomOut',
  ZOOM_OUT_MAP = 'zoomOutMap',
}

export enum IconVariant {
  FILLED = 'Filled',
  CONTOUR = 'Contour',
}

export enum IconSize {
  SM = 'w-icon-16 h-icon-16',
  MD = 'w-icon-20 h-icon-20',
  LG = 'w-icon-24 h-icon-24',
}

export enum IconState {
  DEFAULT = 'default',
  HOVER = 'hover',
  ACTIVE = 'active',
}

export enum IconColor {
  BLUE1_50 = 'filter-blue1-50',
  BLUE1_100 = 'filter-blue1-100',
  BLUE1_200 = 'filter-blue1-200',
  BLUE1_300 = 'filter-blue1-300',
  BLUE1_400 = 'filter-blue1-400',
  BLUE1_500 = 'filter-blue1-500',
  BLUE1_600 = 'filter-blue1-600',
  BLUE1_700 = 'filter-blue1-700',
  BLUE1_800 = 'filter-blue1-800',
  BLUE2_50 = 'filter-blue2-50',
  BLUE2_100 = 'filter-blue2-100',
  BLUE2_200 = 'filter-blue2-200',
  BLUE2_300 = 'filter-blue2-300',
  BLUE2_400 = 'filter-blue2-400',
  BLUE2_500 = 'filter-blue2-500',
  BLUE2_600 = 'filter-blue2-600',
  BLUE2_700 = 'filter-blue2-700',
  BLUE2_800 = 'filter-blue2-800',
  PURPLE_50 = 'filter-purple-50',
  PURPLE_100 = 'filter-purple-100',
  PURPLE_200 = 'filter-purple-200',
  PURPLE_300 = 'filter-purple-300',
  PURPLE_400 = 'filter-purple-400',
  PURPLE_500 = 'filter-purple-500',
  PURPLE_600 = 'filter-purple-600',
  PURPLE_700 = 'filter-purple-700',
  PURPLE_800 = 'filter-purple-800',
  ORANGE_50 = 'filter-orange-50',
  ORANGE_100 = 'filter-orange-100',
  ORANGE_200 = 'filter-orange-200',
  ORANGE_300 = 'filter-orange-300',
  ORANGE_400 = 'filter-orange-400',
  ORANGE_500 = 'filter-orange-500',
  ORANGE_600 = 'filter-orange-600',
  ORANGE_700 = 'filter-orange-700',
  ORANGE_800 = 'filter-orange-800',
  RED_50 = 'filter-red-50',
  RED_100 = 'filter-red-100',
  RED_200 = 'filter-red-200',
  RED_300 = 'filter-red-300',
  RED_400 = 'filter-red-400',
  RED_500 = 'filter-red-500',
  RED_600 = 'filter-red-600',
  RED_700 = 'filter-red-700',
  RED_800 = 'filter-red-800',
  GREEN_50 = 'filter-green-50',
  GREEN_100 = 'filter-green-100',
  GREEN_200 = 'filter-green-200',
  GREEN_300 = 'filter-green-300',
  GREEN_400 = 'filter-green-400',
  GREEN_500 = 'filter-green-500',
  GREEN_600 = 'filter-green-600',
  GREEN_700 = 'filter-green-700',
  GREEN_800 = 'filter-green-800',
  PRIMARY_50 = 'filter-primary-50',
  PRIMARY_100 = 'filter-primary-100',
  PRIMARY_200 = 'filter-primary-200',
  PRIMARY_300 = 'filter-primary-300',
  PRIMARY_400 = 'filter-primary-400',
  PRIMARY_500 = 'filter-primary-500',
  PRIMARY_600 = 'filter-primary-600',
  PRIMARY_700 = 'filter-primary-700',
  PRIMARY_800 = 'filter-primary-800',
  WHITE = 'filter-grey-0',
  GREY_50 = 'filter-grey-50',
  GREY_100 = 'filter-grey-100',
  GREY_200 = 'filter-grey-200',
  GREY_300 = 'filter-grey-300',
  GREY_400 = 'filter-grey-400',
  GREY_500 = 'filter-grey-500',
  GREY_600 = 'filter-grey-600',
  GREY_700 = 'filter-grey-700',
  GREY_800 = 'filter-grey-800',
  YELLOW_50 = 'filter-yellow-50',
  YELLOW_100 = 'filter-yellow-100',
  YELLOW_200 = 'filter-yellow-200',
  YELLOW_300 = 'filter-yellow-300',
  YELLOW_400 = 'filter-yellow-400',
  YELLOW_500 = 'filter-yellow-500',
  YELLOW_600 = 'filter-yellow-600',
  YELLOW_700 = 'filter-yellow-700',
  YELLOW_800 = 'filter-yellow-800',
}

export type IconAppearance = {
  color: IconColor;
  variant: IconVariant;
  size?: IconSize;
};

/* eslint react/require-default-props: 0 */
export type IconProps = {
  svg: IconSvg;
  size?: IconSize | null;
  ariaLabel?: string;
  state?: IconState | null;
  appearance?: Partial<Record<IconState, IconAppearance>>;
  color?: IconColor;
  variant?: IconVariant | null;
  className?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
  onMouseDown?: MouseEventHandler<HTMLSpanElement>;
  onMouseEnter?: MouseEventHandler<HTMLSpanElement>;
  onMouseLeave?: MouseEventHandler<HTMLSpanElement>;
  tooltipText?: string;
  withHover?: boolean;
  draggable? : boolean
} & TestableElement;
/* eslint react/require-default-props: 1 */

export const Icon = forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      svg,
      size = null,
      state = null,
      appearance = {[IconState.DEFAULT]: {color: IconColor.GREY_500, variant: IconVariant.CONTOUR}},
      ariaLabel = '',
      color = null,
      variant = null,
      className = '',
      onClick = undefined,
      onMouseDown = undefined,
      onMouseEnter = undefined,
      onMouseLeave = undefined,
      testId = undefined,
      tooltipText = undefined,
      withHover = false,
      draggable = false,
      ...rest
    },
    ref,
  ) => {
    const [isHover, setIsHover] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const innerState = useMemo(() => {
      if (isActive && appearance[IconState.ACTIVE]) {
        return IconState.ACTIVE;
      }
      if (isHover && appearance[IconState.HOVER]) {
        return IconState.HOVER;
      }
      return IconState.DEFAULT;
    }, [isActive, isHover, appearance]);

    const componentClasses = useMemo<string>(
      () =>
        className
          .split(' ')
          .filter(css => !css.includes('filter'))
          .join(' '),
      [className],
    );
    const iconClasses = useMemo<string>(
      () =>
        className
          .split(' ')
          .filter(css => css.includes('filter'))
          .join(' '),
      [className],
    );

    const iconVariant = variant || appearance[state || innerState]?.variant || IconVariant.CONTOUR;
    const iconColor = color || appearance[state || innerState]?.color || null;
    const iconHoverColor = iconColor && `${iconColor.split('-')[1]}`;

    const img = () => (
      <img
        aria-label={ariaLabel}
        draggable={draggable}
        src={icons[`${svg}${iconVariant}`]}
        className={twMerge(
          clsx({
            [iconColor as string]: iconColor !== null && !iconClasses,
            [`group-hover:filter-${iconHoverColor as string}-600 hover:cursor-pointer`]:
              iconHoverColor !== null && !iconClasses && withHover,
            [`w-full h-full`]: size !== null,
            [iconClasses]: !!iconClasses,
          }),
        )}
        alt="icon"
      />
    );

    return (
      <span
        data-testid={testId}
        data-test-element="icon"
        ref={ref}
        onClick={onClick}
        onMouseEnter={event => {
          if (onMouseEnter) {
            onMouseEnter(event);
          }
          setIsHover(true);
        }}
        onMouseLeave={event => {
          if (onMouseLeave) {
            onMouseLeave(event);
          }
          setIsHover(false);
        }}
        onMouseDown={e => {
          if (onMouseDown) {
            onMouseDown(e);
          }
          setIsActive(true);
        }}
        onMouseUp={() => setIsActive(false)}
        className={twMerge(
          clsx(
            'inline-flex items-center justify-center group select-none',
            {
              [size as string]: size !== null,
            },
            componentClasses,
          ),
        )}
        {...rest}
      >
        {tooltipText ? <Tooltip text={tooltipText}>{img()}</Tooltip> : img()}
      </span>
    );
  },
);
