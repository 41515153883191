import {FormattedMessage, useIntl} from 'react-intl';
import {Tr} from '@symfonia-ksef/locales/keys';
import {dateParser} from '../../helpers';
import {PackageType} from '@symfonia-ksef/graphql';
import {observer} from 'mobx-react-lite';
import {useMemo} from 'react';
import {Text} from '../../../root/components/Text';
import {BulletList, IconColor, IconSvg, TooltipPosition} from '@symfonia/brandbook';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {ChartElement, CircularPackageLimit, IconWithTooltip} from '@symfonia/symfonia-ksef-components';

export const PackageInfo = observer(() => {
  const intl = useIntl();

  const {
    state: {DateTo, Items: Packages},
    subscriptionIsInactive,
    loadingError,
    subscriptionNotFound,
    maxInvoicesSize,
    maxStorageSize,
    currentStorageUsage,
    currentInvoicesUsage,
    availableStorageSize,
    availableInvoicesSize,
  } = earchiveState.packageStatistics;

  const hasActivePackages =
    loadingError || (!subscriptionNotFound && subscriptionIsInactive === false) || subscriptionIsInactive === false;

  const packageListBulletpoints = useMemo(
    () =>
      Packages &&
      Packages.map(item => {
        const label =
          item.PackageTypeEnum === PackageType.Service
            ? item.PackageDescription
            : intl.formatMessage(
              {id: `description_${item.PackageTypeEnum}`},
              {value: item.PackageTypeEnum === PackageType.Storage ? maxStorageSize.valueWithSpace : item.PackageValue},
            );

        return label;
      }),
    [Packages, intl, maxStorageSize],
  );

  const usedIntl = Tr.used;
  const availableIntl = Tr.available;
  const packageIntl = Tr.package;
  const invoicesInsideText =
    <div className="flex flex-col items-center">
      <FormattedMessage id="availableInvoices"/>:
      <div className="flex flex-wrap justify-center items-center">
        <p className="font-bold">{maxInvoicesSize - currentInvoicesUsage}/</p>
        <p className="font-bold">{maxInvoicesSize}</p>
      </div>
    </div>;

  const memoryInsideText =
    <div className="flex flex-col items-center">
      <FormattedMessage id="availableSpace"/>: 
      <div className="flex flex-wrap justify-center items-center">
        <p className="font-bold">{availableStorageSize.valueWithoutUnit}/</p>
        <p className="font-bold">{maxStorageSize.valueWithSpace}</p>
      </div>
    </div>;

  const chartDataAmountOfInvoices = [
    {
      type: ChartElement.AVAILABLE,
      name: availableIntl,
      numberValue: maxInvoicesSize - currentInvoicesUsage,
    },
    {
      type: ChartElement.USED,
      name: usedIntl,
      numberValue: currentInvoicesUsage,
    },
    {
      type: ChartElement.CAPACITY,
      name: packageIntl,
      numberValue: maxInvoicesSize,
    },
  ];

  const chartDataMemoryUsage = [
    {
      type: ChartElement.AVAILABLE,
      name: availableIntl,
      numberValue: maxStorageSize.bytes - currentStorageUsage.bytes,
      stringValue: availableStorageSize.valueWithSpace,
    },
    {
      type: ChartElement.USED,
      name: usedIntl,
      numberValue: currentStorageUsage.bytes,
      stringValue: currentStorageUsage.valueWithSpace,
    },
    {
      type: ChartElement.CAPACITY,
      name: packageIntl,
      numberValue: maxStorageSize.bytes,
      stringValue: maxStorageSize.valueWithSpace,
    },
  ];

  return (
    <div className="grid">
      <div className="grid">
        <Text className="font-bold my-[20px]">
          <FormattedMessage id={Tr.yourPackage}/>
        </Text>

        {hasActivePackages && Packages && Packages.length > 0 && (
          <>
            <div>
              <Text testId="packageInfoActiveUntil">
                <FormattedMessage id={Tr.activeUntil}/>:{' '}
              </Text>
              {DateTo ? <Text className="font-bold">{dateParser(DateTo)}</Text> : <FormattedMessage id="noData"/>}
            </div>

            <Text testId="packageInfopackageIncludes" className="my-[16px]">
              <FormattedMessage id={Tr.packageIncludes}/>{' '}
              {!Packages || (!Packages.length && <FormattedMessage id={Tr.noData}/>)}
            </Text>
            {hasActivePackages && Packages && <BulletList className={'whitespace-nowrap'} elements={packageListBulletpoints as string[]}/>}
          </>
        )}
      </div>
      {hasActivePackages && Packages && Packages.length > 0 && (
        <div className="grid gap-[16px]">
          <div>
            <CircularPackageLimit
              chartData={chartDataAmountOfInvoices}
              insideText={invoicesInsideText}
              testId="packageInfoInvoicesAmount"
              titleElement={
                <div className="flex justify-start gap-[8px]">
                  <Text className="font-bold">
                    <FormattedMessage id={Tr.invoicesCount}/>
                  </Text>
                  <IconWithTooltip
                    tooltipWidth="auto"
                    tooltipClass={'w-[800px]'}
                    icon={{icon: IconSvg.INFO, color: IconColor.BLUE1_500}}
                    tooltipPosition={TooltipPosition.RIGHT}
                    tooltipContent={<p>{intl.formatMessage({id: Tr.packageUsageTooltipContent}, {br: <br/>})}</p>}
                  />
                </div>
              }
            />
          </div>
          <div>
            <CircularPackageLimit
              chartData={chartDataMemoryUsage}
              insideText={memoryInsideText}
              testId="packageInfoDateMemoryUsage"
              titleElement={
                  <Text className="font-bold">
                    <FormattedMessage id={Tr.memory}/>
                  </Text>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
});
