import {FormattedMessage, useIntl} from 'react-intl';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {NavTabs, NavTabsElement, useIsRouteActive} from '../../../common';
import {Tr} from '@symfonia-ksef/locales/keys';
import {IDocumentsState} from './state/IDocumentsState';
import {observer} from 'mobx-react-lite';
import {Breadcrumbs, ButtonTertiary, ButtonTertiarySize, IconSvg, TooltipPosition} from '@symfonia/brandbook';
import {DownloadInvoicesButton} from '../../components/InvoicesTable/DownloadInvoicesButtonModule/DownloadInvoicesButton';
import {KSeFAuthResult, KSeFTokenCredential} from '@symfonia-ksef/graphql';
import {AppRoutes} from '../../../root/Routes';
import * as React from 'react';
import {useCallback, useState} from 'react';
import {useInvoicesTableMethods} from '../../hooks/useInvoicesTableMethods';
import {Header} from '../../../root/components/Header';
import {Content} from '../../../root/components/Content';
import {Menu, MenuOptions} from '../../components/Menu/Menu';
import {IconWithInfo} from '@symfonia/symfonia-ui-components';
import {MenuItemBtn} from '../../../common/components/MenuItem/MenuItem';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {CancelInvoiceDownloadButton} from '../../components/InvoicesTable/CancelInvoiceDownloadButton';

export interface DocumentsPageProps {
  state: IDocumentsState;
}

export const DocumentsPage = observer(({state}: DocumentsPageProps) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const breadcrumbs = [
    {
      label: intl.formatMessage({id: Tr.companiesList}),
      onClick: () => {
        earchiveState.company.removeStoredCompanyId();
        navigate(AppRoutes.hub.address);
      } 
    },
    {
      label: 'KSeF',
    },
  ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const hasInvoiceRead = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceRead);
  const {reloadInvoices} = useInvoicesTableMethods();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isAutopostingView = location.pathname.includes('/autoposting');

  const autopostingDisabled = !earchiveState.company.autoPosting?.Status;

  const navTabElements: NavTabsElement[] = [
    {
      label: intl.formatMessage({id: Tr.purchaseDocumentsTab}),
      href: 'purchase',
      active: useIsRouteActive('purchase'),
      'test-id': 'foreign',
    },
    {
      label: intl.formatMessage({id: Tr.salesDocumentsTab}),
      href: 'sales',
      active: useIsRouteActive('sales'),
      'test-id': 'own',
    },
  ];

  const goToOcrList = useCallback(() => {
    navigate(
      `/earchive/${earchiveState.company.tenantId}/company/${earchiveState.company.companyId}${AppRoutes.ocr.address}/list`,
      {state: {from: location.pathname}},
    );
  }, []);

  //ZAMIEŃ MENUITEMWITHTOOLTIP NA ZREDESIGNOWANY KOMPONENT GDY BEDZIE GOTOWY
  const menuOptions: MenuOptions = [
    {
      hidden: false,
      renderComponent: () => (
        <MenuItemBtn
          tooltipTitle={<FormattedMessage id={Tr.invoicesUploadXMLTooltip}/>}
          onClick={() =>
            navigate(
              `/earchive/${earchiveState.company.tenantId}/company/${earchiveState.company.companyId}${AppRoutes.uploadXML.address}`,
              {state: {from: location.pathname}},
            )
          }
          placement={TooltipPosition.LEFT}
          text={intl.formatMessage(
            {id: Tr.addFilesForUpload},
            {i: !earchiveState.packageStatistics.isPackageNotActive ? 'i' : ''},
          )}
          key={'menuItemPostingDetailsChangeAccountType-1'}
          icon={IconSvg.NOTE_ADD}
        />
      ),
    },
    {
      hidden: false,
      renderComponent: () => (
        <MenuItemBtn
          onClick={() =>
            navigate(
              `/earchive/${earchiveState.company.tenantId}/company/${earchiveState.company.companyId}${AppRoutes.ocr.address}`,
              {state: {from: location.pathname}},
            )
          }
          placement={TooltipPosition.LEFT}
          disabled={autopostingDisabled}
          text={intl.formatMessage({id: Tr.addFilesForOcrUpload})}
          key={'menuItemPostingDetailsChangeAccountType-2'}
          icon={IconSvg.ADD_A_PHOTO}
        />
      ),
    },
  ];

  return (
    <Content>
      <Breadcrumbs breadcrumbs={breadcrumbs} className="mb-[8px]" testId="documentsPage-breadcrumbs"/>
      <div className="flex justify-between items-end flex-wrap">
        <Header>{intl.formatMessage({id: isAutopostingView ? Tr.decreesList : Tr.documentsList})}</Header>
        {!isAutopostingView && (
          <div className="flex grow justify-end flex-wrap">
            <CancelInvoiceDownloadButton/>
            <DownloadInvoicesButton
              environmentId={earchiveState.company.companyId ?? ''}
              isEnabled={earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized}
              onDownloaded={reloadInvoices}
              hasInvoiceReadCredential={hasInvoiceRead}
            />
            <IconWithInfo
              btnClass="ml-[8px] mb-[8px]"
              id="menu-button"
              aria-controls={open ? 'menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              action={e => handleClick(e)}
              icon={IconSvg.ADD}
              tooltipContent={intl.formatMessage({id: Tr.uploadInvoice_tooltipContent})}
            />
            <Menu options={menuOptions} onClose={handleClose} anchorEl={anchorEl} isOpen={open}/>
          </div>
        )}
      </div>
      {!isAutopostingView && (
        <div className="flex justify-between items-end mr-[8px] flex-wrap">
          <NavTabs elements={navTabElements}/>
          <ButtonTertiary
            size={ButtonTertiarySize.SM}
            text={intl.formatMessage({id: Tr.showDocumentsToVerification})}
            disabled={autopostingDisabled}
            onClick={goToOcrList}
            testId='goToOcrPageButton'
          />
        </div>
      )}
      <Outlet/>
    </Content>
  );
});
