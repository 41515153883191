import {FormattedMessage, useIntl} from 'react-intl';
import {ActionToggler, SingleActionTypeEnum} from '../../../models';
import {
  InvoiceDto,
  InvoicePostingStatus,
  KSeFAuthResult,
  KSeFStatus,
  KSeFTokenCredential,
  KsefXsdVersion,
  UpoFileType,
  useGetInvoiceFileContentSingleLazyQuery,
  useGetKSeFInvoiceXmlLazyQuery,
} from '@symfonia-ksef/graphql';
import * as React from 'react';
import {useCallback, useMemo, useState} from 'react';
import {downloadInvoicePdf, exportToSymfoniaDesktop, getKSeFInvoiceXml} from './InvoicesViewServices';
import {useInvoicesTableMethods} from '../../../hooks/useInvoicesTableMethods';
import {isSymfoniaTrade} from '../../../../common/helpers/checkCurrentPlatform';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';
import {isFeatureEnabled} from '../../../../common/helpers/featureSwitch';
import {FeatureSwitchTypeEnum} from '../../../../common/enums/FeatureSwitchTypeEnum';
import {isPostingAvailableForInvoice} from '../../../helpers/postingStatusHelpers';
import {earchiveStateMobx} from '../../../EArchiveModule';
import {IconSvg, ToastVariant} from '@symfonia/brandbook';
import {checkIsAdmin} from '../../../../../helpers/permissionsHelper';
import {ContextualMenu} from '../../../../common/components/ContextualMenu/ContextualMenu';
import {MenuAction} from '../../../../common/types/MenuActions';
import {ConfirmModal} from '@symfonia/symfonia-ksef-components';
import {
  authState,
  checkWhiteListService,
  deleteInvoiceService,
  earchiveState,
  getUpoResultService,
  sendInvoicesResultService,
} from '@symfonia-ksef/state/rootRepository';
import {StateI} from '../../../../root/services/MobXServices/State';
import {ContextualMenuState} from '../../../modules/Invoices/InvoicesTable/invoicesTableConfig';
import {IEarchiveStateMobx} from '../../../IEArchiveStateMobx';
import {EArchiveStateMobx} from '../../../EArchiveStateMobx';

export const SingleInvoiceActionMenu = observer(
  ({
    enabledGetInvoiceXml,
    isInternal,
    isExternal,
    menuState,
  }: ActionToggler<SingleActionTypeEnum> & {
    isInternal: boolean;
    isExternal: boolean;
    menuState: StateI<ContextualMenuState>;
    earchiveStateMobx: IEarchiveStateMobx;
  }) => {
    const intl = useIntl();
    const addAlert = earchiveState.alertsState.addAlert;
    const {authorizedInKSeF, companyId} = earchiveState.company;

    const {isPackageNotActive} = earchiveState.packageStatistics;
    const enabledExportToSymfoniaTrade = isSymfoniaTrade();
    const enabledSendToKSeF = isInternal;
    const enabledPosting =
      isFeatureEnabled(FeatureSwitchTypeEnum.autoposting) && earchiveStateMobx.postingState.isPostingConfigured;

    const canDownloadUpo =
      menuState.state.item?.KSeFStatus === KSeFStatus.Approved && menuState.state.item?.CanDownloadUpo;
    const isInvoiceReadyForPosting = menuState.state.item && isPostingAvailableForInvoice(menuState.state.item);
    const hasInvoiceWriteCredential = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
    const {closeSingleInvoiceActionsMenu} = useInvoicesTableMethods(undefined, undefined, menuState);
    const isSelectedInvoiceSchemaNotSupported = menuState.state.item?.SchemaVersion !== KsefXsdVersion.V12648;
    const whiteListCheckDisabled =
      menuState.state.item?.WhiteListStatus?.IsValidated &&
      menuState.state.item?.WhiteListStatus?.Accounts?.length === 0;
    const isSendToKSeFButtonDisabled =
      authorizedInKSeF === KSeFAuthResult.NotAuthorized || isPackageNotActive || !hasInvoiceWriteCredential;
    const isAdmin = checkIsAdmin();
    const isDeleteButtonDisabled =
      (menuState.state.item?.CreatedBy !== authState.currentUserEmail && !isAdmin) ||
      menuState.state.item?.KSeFStatus !== KSeFStatus.Ocr;

    const [showModal, setShowModal] = useState(false);
    const [invoiceToDelete, setInvoiceToDelete] = useState(null);

    const {sendToKSeF, runPosting, publishInvoices} = useInvoicesTableMethods();

    const [getKSeFInvoiceXmlQuery, {loading: xmlLoading}] = useGetKSeFInvoiceXmlLazyQuery({
      context: {
        envId: companyId,
        client: 'grapghql2',
      },
    });

    const getInvoiceXml = useCallback(
      async (invoiceId: string) => {
        try {
          await getKSeFInvoiceXml(invoiceId, getKSeFInvoiceXmlQuery);
        } catch (e) {
          addAlert(<FormattedMessage id={Tr.getInvoiceXmlError}/>, ToastVariant.ERROR);
        } finally {
          //TODO: Loader show/hide po wdrożeniu zustand
        }
      },
      [addAlert, getKSeFInvoiceXmlQuery],
    );

    const [GetInvoiceFileContentSingleQuery, {loading: pdfLoading}] = useGetInvoiceFileContentSingleLazyQuery({
      context: {
        envId: companyId,
      },
    });

    const downloadInvoice = useCallback(
      async (InvoiceId: string) => await downloadInvoicePdf(InvoiceId, GetInvoiceFileContentSingleQuery),
      [GetInvoiceFileContentSingleQuery],
    );

    const showSendByEmailModal = () => {
      earchiveStateMobx.filehubState.setInvoiceIssuerName(menuState.state.item?.IssuerName ?? '');
      earchiveStateMobx.filehubState.setInvoiceIssuerNIP(menuState.state.item?.IssuerNip ?? '');
      earchiveStateMobx.filehubState.setInvoiceNumber(menuState.state.item?.Number);
      earchiveStateMobx.filehubState.setInvoiceId(menuState.state.item?.Id);
      earchiveStateMobx.filehubState.setIsSendAttachmentByLinkModalOpen(true);
    };

    const showFilehubAddAttachmentModal = () => {
      earchiveStateMobx.filehubState.setInvoiceId(menuState.state.item?.Id);
      earchiveStateMobx.filehubState.setInvoiceNumber(menuState.state.item?.Number);
      earchiveStateMobx.filehubState.setIsAddAttachmentModalOpen(true);
    };

    const publishInvoice = (invoice: InvoiceDto) => {
      addAlert(<FormattedMessage id={Tr.downloadInvoiceToFKInfo}/>, ToastVariant.INFO, {
        displayDuration: 10000,
        omitIfHasTheSameAlert: true,
      });
      if (
        invoice.InvoicePostingStatus === InvoicePostingStatus.Posted ||
        invoice.InvoicePostingStatus === InvoicePostingStatus.PostingIncomplete
      )
        publishInvoices([invoice.Id]);
      else exportToSymfoniaDesktop([invoice]);
    };

    const actionHandler = useCallback(
      (action: SingleActionTypeEnum) => {
        closeSingleInvoiceActionsMenu();
        if (!menuState.state.item || !menuState.state.item.Id) {
          addAlert(intl.formatMessage({id: Tr.actionError}), ToastVariant.ERROR, {omitIfHasTheSameAlert: true});
          return;
        }

        switch (action) {
          case SingleActionTypeEnum.exportToSymfoniaTrade:
            return exportToSymfoniaDesktop([menuState.state.item]);
          case SingleActionTypeEnum.exportToSymfoniaFinancesAndAccountancy:
            return publishInvoice(menuState.state.item);
          case SingleActionTypeEnum.sendToKSeF:
            return sendToKSeF([menuState.state.item.Id]);
          case SingleActionTypeEnum.getUpoPdf:
            return getUpoResultService.jobRunner.run({
              InvoiceIds: [menuState.state.item.Id],
              UpoFileType: UpoFileType.Pdf,
            });
          case SingleActionTypeEnum.getUpoXml:
            return getUpoResultService.jobRunner.run({
              InvoiceIds: [menuState.state.item.Id],
              UpoFileType: UpoFileType.Xml,
            });
          case SingleActionTypeEnum.getInvoiceXml:
            return getInvoiceXml(menuState.state.item.Id);
          case SingleActionTypeEnum.sendForPosting:
            return runPosting([menuState.state.item.Id]);
          case SingleActionTypeEnum.downloadInvoicePdf:
            return downloadInvoice(menuState.state.item.Id);
          case SingleActionTypeEnum.deleteInvoice:
            setShowModal(true);
            setInvoiceToDelete(menuState.state.item.Id);
            return;
          case SingleActionTypeEnum.addAttachment:
            showFilehubAddAttachmentModal();
            return;
          case SingleActionTypeEnum.whiteListCheck:
            checkWhiteListService.jobRunner
              .configure({InvoiceIds: [menuState.state.item.Id], Date: new Date()})
              .fetch();
            return;
          case SingleActionTypeEnum.sendByEmail:
            showSendByEmailModal();
            return;
        }
      },
      [
        closeSingleInvoiceActionsMenu,
        menuState.state.item,
        addAlert,
        intl,
        sendToKSeF,
        getInvoiceXml,
        downloadInvoice,
        runPosting,
      ],
    );

    const acceptBeforeSendToFKIsActive = earchiveState.company.autoPosting?.AcceptBeforeSendToFK?.IsActive;

    const isExportToSymfoniaFKVisible = enabledPosting ?
      !isSymfoniaTrade() &&
      menuState.state.item &&
      [
        InvoicePostingStatus.NotAllowed,
        InvoicePostingStatus.Posting,
        InvoicePostingStatus.PostingFailed,
        InvoicePostingStatus.ReadyForPosting,
        InvoicePostingStatus.Undefined,
      ].includes(menuState.state.item?.InvoicePostingStatus) 
      : true;

    const actions = useMemo<MenuAction[]>(() => {
      return [
        {
          'test-id': 'sendToKSeFSingleInvoiceAction',
          key: Tr.sendToKSeF,
          label: intl.formatMessage({id: Tr.sendToKSeF}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.sendToKSeF);
          },
          icon: IconSvg.VERTICAL_ALIGN_TOP,
          disabled: isSendToKSeFButtonDisabled || isSelectedInvoiceSchemaNotSupported,
          hidden:
            !(
              menuState.state.item?.KSeFStatus &&
              [KSeFStatus.NotSent, KSeFStatus.Error].includes(menuState.state.item.KSeFStatus)
            ) || !enabledSendToKSeF,
          loading: !!menuState.state.item?.Id && sendInvoicesResultService.checkIsPending(menuState.state.item.Id),
          tooltipTitle: isSelectedInvoiceSchemaNotSupported ? (
            intl.formatMessage({id: Tr.invoiceSentToKsefDisabledCauseSchemaTooltip})
          ) : isSendToKSeFButtonDisabled ? (
            <>
              <b>{intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Header})}</b>
              <br/>
              <br/>
              {intl.formatMessage({id: Tr.noInvoiceWriteKsefSendTooltip_Content})}
            </>
          ) : null,
        },
        {
          'test-id': 'downloadInvoicePdfSingleInvoiceAction',
          key: Tr.downloadInvoicePdf,
          label: intl.formatMessage({id: Tr.downloadInvoicePdf}),
          loading: pdfLoading,
          onClick: () => {
            actionHandler(SingleActionTypeEnum.downloadInvoicePdf);
          },
          icon: IconSvg.SAVE_ALT,
        },
        {
          'test-id': 'getInvoiceXmlSingleInvoiceAction',
          hidden: !enabledGetInvoiceXml,
          key: Tr.getInvoiceXml,
          loading: xmlLoading,
          label: intl.formatMessage({id: Tr.getInvoiceXml}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.getInvoiceXml);
          },
          icon: IconSvg.SAVE_ALT,
        },
        {
          'test-id': 'getUpoPdfSingleInvoiceAction',
          hidden: isExternal || !canDownloadUpo,
          key: Tr.getUpoPdf,
          label: intl.formatMessage({id: Tr.getUpoPdf}),
          loading: getUpoResultService.checkIsPending(menuState.state.item?.Id, UpoFileType.Pdf),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.getUpoPdf);
          },
          icon: IconSvg.SAVE_ALT,
        },
        {
          'test-id': 'getUpoXmlSingleInvoiceAction',
          hidden: isExternal || !canDownloadUpo,
          key: Tr.getUpoXml,
          label: intl.formatMessage({id: Tr.getUpoXml}),
          loading: getUpoResultService.checkIsPending(menuState.state.item?.Id, UpoFileType.Xml),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.getUpoXml);
          },
          icon: IconSvg.SAVE_ALT,
        },
        {
          'test-id': 'exportToSymfoniaTradeSingleInvoiceAction',
          hidden: !(isExternal && enabledExportToSymfoniaTrade),
          key: Tr.exportToSymfoniaTrade,
          label: intl.formatMessage({id: Tr.exportToSymfoniaTrade}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.exportToSymfoniaTrade);
          },
          icon: IconSvg.SAVE_ALT,
          disabled: !!menuState.state.item?.Downloaded,
        },
        {
          'test-id': 'exportToSymfoniaFinancesAndAccountancySingleInvoiceAction',
          hidden: !isExportToSymfoniaFKVisible,
          key: Tr.exportToSymfoniaFinancesAndAccountancy,
          label: intl.formatMessage({id: Tr.exportToSymfoniaFinancesAndAccountancy}),
          loading: menuState.state.item?.InvoicePostingStatus === InvoicePostingStatus.DecreeSent,
          disabled: enabledPosting ? acceptBeforeSendToFKIsActive : false,
          onClick: () => {
            actionHandler(SingleActionTypeEnum.exportToSymfoniaFinancesAndAccountancy);
          },
          icon: IconSvg.SAVE_ALT,
        },
        {
          'test-id': 'postInvoiceSingleInvoiceAction',
          key: Tr.postInvoice,
          label: intl.formatMessage({id: Tr.postInvoice}),
          hidden: !enabledPosting,
          disabled: !isInvoiceReadyForPosting,
          onClick: () => {
            actionHandler(SingleActionTypeEnum.sendForPosting);
          },
          icon: IconSvg.EDIT,
        },
        {
          'test-id': 'whiteListCheckSingleInvoiceAction',
          hidden: !isExternal,
          disabled: whiteListCheckDisabled,
          key: Tr.whiteListCheckAction,
          loading: checkWhiteListService.checkIsPending(menuState.state.item?.Id),
          label: intl.formatMessage({id: Tr.whiteListCheckAction}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.whiteListCheck);
          },
          icon: IconSvg.PLAYLIST_ADD_CHECK,
        },
        {
          'test-id': 'addAttachmentsInvoiceAction',
          hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.filehub),
          key: Tr.addAttachments,
          label: intl.formatMessage({id: Tr.addAttachments}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.addAttachment);
          },
          icon: IconSvg.NOTE_ADD,
        },
        {
          'test-id': 'sendByEmailInvoiceAction',
          hidden: !isFeatureEnabled(FeatureSwitchTypeEnum.filehub),
          key: Tr.sendByEmail,
          label: intl.formatMessage({id: Tr.sendByEmail}),
          onClick: () => {
            actionHandler(SingleActionTypeEnum.sendByEmail);
          },
          icon: IconSvg.SEND,
        },
        {
          'test-id': 'removeSingleInvoiceAction',
          key: Tr.removeButton,
          loading: deleteInvoiceService.checkIsPending(menuState.state.item?.Id),
          label: intl.formatMessage({id: Tr.removeButton}),
          tooltipTitle: intl.formatMessage({id: Tr.cantDeleteInvoiceTooltip}),
          disabled: isDeleteButtonDisabled,
          onClick: () => {
            actionHandler(SingleActionTypeEnum.deleteInvoice);
          },
          icon: IconSvg.DELETE_FOREVER,
        },
      ];
    }, [isExternal, menuState.state.item, enabledSendToKSeF, isSendToKSeFButtonDisabled, actionHandler]);

    return (
      <>
        <ContextualMenu
          test-id="singleInvoiceActionMenu"
          isOpen={menuState.state.isOpen}
          actions={actions}
          anchorEl={menuState.state.anchorEl ?? null}
          onClose={closeSingleInvoiceActionsMenu}
        />
        <ConfirmModal
          testId="singleInvoiceActionConfirmModal"
          open={showModal}
          title={intl.formatMessage({id: Tr.deleteInvoiceConfirmationModalTitle})}
          content={intl.formatMessage({id: Tr.deleteInvoiceConfirmationModalContent})}
          confirmButtonName={intl.formatMessage({id: Tr.removeButton})}
          cancelButtonName={intl.formatMessage({id: Tr.cancelButton})}
          onCancel={() => {
            setShowModal(false);
            setInvoiceToDelete(null);
          }}
          onConfirm={() => {
            deleteInvoiceService.jobRunner.run({
              InvoiceIds: [invoiceToDelete],
            });
            setShowModal(false);
            setInvoiceToDelete(null);
          }}
        />
      </>
    );
  },
);
