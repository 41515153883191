import {usePageTitle} from '../../../../common';
import CompanyInfoContainer from './CompanyInfoContainer';
import TenantInfoContainer from './TenantInfoContainer';
import {Tr} from '@symfonia-ksef/locales/keys';
import {KSeFConnectionType} from '../../../../common/components/KSeFConnectionType';

import {PackageInfo} from '../../../../common/components/PackageInfo/PackageInfo';
import SubscriptionFormContainer from './SubscriptionFormContainer';


import {observer} from 'mobx-react-lite';
import {Breadcrumbs, ButtonPrimary, IconSvg, IconVariant, Spinner, SpinnerSize} from '@symfonia/brandbook';
import {Header} from '../../../../root/components/Header';
import {redirectToSymfoniaShop} from '../../../../common/helpers/redirectToSymfoniaShop';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {useIntl} from 'react-intl';
import {SubscriptionType} from '@symfonia-ksef/graphql';

export const TenantPage = observer(() => {

  const intl = useIntl();
  const {setPageTitle} = usePageTitle();

  const {isAdmin, company: {currentEnvironment}, packageStatistics} = earchiveState;
  const isEnterprise = packageStatistics.subscriptionType === SubscriptionType.Enterprise;

  const breadcrumbs = [
    {label: intl.formatMessage({id: Tr.settings})},
    {label: intl.formatMessage({id: Tr.createTenantForm_Header})},
  ];

  if (!currentEnvironment) {
    return <>No selected company</>;
  }
  setPageTitle(intl.formatMessage({id: Tr.tenantDetails}));
  return (
    <div className="p-[24px] flex flex-col gap-2 lg:ml-[40px] h-auto overflow-[hidden]">
      <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-[10px]'} testId="tenantPage-breadcrumbs"/>
      <Header>{intl.formatMessage({id: Tr.createTenantForm_Header})}</Header>
      <div className="gap-[70px] grid grid-cols-1 md:grid-cols-2 mt-[20px] max-w-screen-md">
        <div className={''}>
          <TenantInfoContainer data={currentEnvironment.Tenant}/>
          <CompanyInfoContainer data={currentEnvironment.Company}/>
          <KSeFConnectionType isDemo={currentEnvironment?.Company.IsDemo}/>

        </div>
        {packageStatistics.loading ? (
          <Spinner size={SpinnerSize.SM}/>
        ) : (
          <div className="grid">
            {!isEnterprise && 
              <>
                <SubscriptionFormContainer
                  isAdmin={isAdmin}
                  packageSubscriptionRepository={packageStatistics}
                />
                {isAdmin && <PackageInfo/>}
                {isAdmin && (
                  <ButtonPrimary
                    onClick={() => redirectToSymfoniaShop(packageStatistics)}
                    text={intl.formatMessage({id: Tr.buyPackage})}
                    className={'mt-[20px]'}
                    testId='redirectToSymfoniaShopButton'
                  />
                )}
              </>
            }
          </div>
        )}
      </div>
    </div>
  );
});
