import {CompanyKsefServicesStatusEnum} from '@symfonia-ksef/graphql';
import {useCallback, useState} from 'react';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {useModule} from '../../../../../root/services/MobXServices/BaseModule';
import {WhiteList} from './WhiteList';

export function useWhiteList(opt?: { loadOnMount: boolean }) {
  const whiteListsModule = useModule(() => new WhiteList(earchiveState.company, earchiveState, !!opt?.loadOnMount));
  const {settled, modifiedBy, modificationDateTime} = whiteListsModule;
  const [isActive, setIsActive] = useState<boolean>(() => whiteListsModule.isActive);

  const onChange = useCallback((isActive: boolean) => setIsActive(isActive), []);

  const isDirty: boolean = whiteListsModule.isActive !== isActive;


  const handleWhiteListChange = useCallback(async () => {
    if (!isDirty) {
      return;
    }
    whiteListsModule.configure({Status: isActive ? CompanyKsefServicesStatusEnum.Active : CompanyKsefServicesStatusEnum.Inactive}).fetch();
  }, [isActive, isDirty, whiteListsModule]);


  return {
    isActive,
    onChange,
    whiteListLoading: whiteListsModule.loading,
    handleWhiteListChange,
    whiteListIsActive: whiteListsModule.isActive,
    whiteListSettled: settled,
    modifiedBy,
    modificationDateTime,
    isDirty,
  };
}
