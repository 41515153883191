import {action, computed, makeObservable, observable, toJS} from 'mobx';
import * as yup from 'yup';
import {intl} from '../../modules/root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {AutoFetchingInvoicesModel} from '../../modules/earchive/models/AutoFetchingInvoicesModel';
import {
  CompanyKsefServicesStatusEnum,
  KSeFAuthResult,
  KSeFAutomaticOperationType,
  KSeFTokenCredential,
} from '@symfonia-ksef/graphql';

import {AutoSendingInvoicesModel} from '../../modules/earchive/models/AutoSendingInvoicesModel';
import {authenticationState, removeAuthorizationInKSeFService} from '@symfonia-ksef/state/rootRepository';
import {EArchiveState} from '@symfonia-ksef/state/EarchiveState/EarchiveState';
import {AutoFetchingInvoicesService} from '@symfonia-ksef/state/BackgroundActionsServices/AutoFetchingInvoicesService';
import {AutoSendingInvoicesService} from '@symfonia-ksef/state/BackgroundActionsServices/AutoSendingInvoicesService';


export enum AutoFetchingInvoicesType {
  Internal = 'Internal',
  External = 'External',
  All = 'All'
}

export interface BackgroundActionsSchemaValues {
  AutoFetchingInvoices: AutoFetchingInvoicesModel | undefined;
  AutoSendingInvoices: AutoSendingInvoicesModel | undefined;
  FetchOption: AutoFetchingInvoicesType | undefined;
  SendOption: KSeFAutomaticOperationType | undefined; //type
  FetchType: KSeFAutomaticOperationType | undefined;
  FetchTime: string;
  FetchInterval: number;
  SendTime: string; //minutes to convert
  SendInterval: number; //hours to convert
  DownloadLimitDateSelected: boolean;
}

export interface BackgroundActionsFormValues extends BackgroundActionsSchemaValues {
  AutoFetchingDisabled: boolean,
  AutoSendingDisabled: boolean,
}

export type SendingConfig = { Status: boolean | undefined, Minutes?: number | undefined, Type?: KSeFAutomaticOperationType | undefined, LastModifiedBy?: string | undefined, LastModifiedDate?: string | undefined }
export type FetchingConfig = SendingConfig & { External?: boolean, Internal?: boolean, DownloadLimitDate?: string | undefined }

export class BackgroundActionsService {

  @observable
  public AutoFetchingInvoices: AutoFetchingInvoicesModel = {
    Status: false,
    ExternalEnabled: false,
    InternalEnabled: false,
    LastModifiedBy: undefined,
    LastModifiedDate: undefined,
    Type: KSeFAutomaticOperationType.Time,
    Minutes: 0,
    DownloadLimitDate: undefined,
  };

  @observable
  public AutoSendingInvoices: AutoSendingInvoicesModel = {
    Status: false,
    LastModifiedBy: undefined,
    LastModifiedDate: undefined,
    Type: KSeFAutomaticOperationType.Time,
    Minutes: 0,
  };

  public BackgroundActionsSchema = yup.object().shape({
    FetchOption: yup
      .string()
      .when(['AutoFetchingInvoices.Status'], {
        is: (status: boolean | undefined) => {
          return !!status;
        },
        then: yup.string().required(intl.formatMessage({id: Tr.requiredField})),
        otherwise: yup.string().notRequired().nullable(),
      }),
    SendTime: yup
      .string()
      .when(['SendOption', 'AutoSendingInvoices.Status'], {
        is: (sendOption: KSeFAutomaticOperationType | undefined, status: boolean | undefined) => {
          return status && sendOption === KSeFAutomaticOperationType.Time;
        },
        then: yup.string().required(intl.formatMessage({id: Tr.requiredField})),
        otherwise: yup.string().notRequired().nullable(),
      }),
    SendInterval: yup
      .number()
      .when(['SendOption', 'AutoSendingInvoices.Status'], {
        is: (sendOption: KSeFAutomaticOperationType | undefined, status: boolean | undefined) => status && sendOption === KSeFAutomaticOperationType.Interval,
        then: yup.number().required(intl.formatMessage({id: Tr.requiredField})).notOneOf([undefined, 0]).min(2, intl.formatMessage({id: Tr.nonEmptyField})),
        otherwise: yup.number().notRequired().nullable(),
      }),
  });

  @observable
  public FetchOption: BackgroundActionsFormValues['FetchOption'] | undefined = undefined;

  @observable
  public SendOption: BackgroundActionsFormValues['SendOption'] | undefined = undefined;

  @observable
  public FetchType: BackgroundActionsFormValues['SendOption'] | undefined = undefined;

  @observable
  public SendTime: BackgroundActionsFormValues['SendTime'] = '';

  @observable
  public SendInterval: BackgroundActionsFormValues['SendInterval'] = 0;

  @observable
  public FetchTime: BackgroundActionsFormValues['FetchTime'] = '';

  @observable
  public FetchInterval: BackgroundActionsFormValues['FetchInterval'] = 0;

  public intervalOptions: [number, string][] = [[2, "2 godziny"], [4, "4 godziny"], [6, "6 godzin"], [8, "8 godzin"], [12, "12 godzin"], [18, "18 godzin"]];

  @observable
  public AutoFetchingDisabled: boolean = false;

  @observable
  public AutoSendingDisabled: boolean = false;

  @observable
  public DownloadLimitDateSelected: BackgroundActionsFormValues['DownloadLimitDateSelected'] = true;

  private autoFetchingInvoicesService !: AutoFetchingInvoicesService;
  private autoSendingInvoicesService!: AutoSendingInvoicesService;

  constructor(private earchiveState: EArchiveState) {
    this.autoSendingInvoicesService = new AutoSendingInvoicesService(earchiveState.company.envObserver, earchiveState);
    this.autoFetchingInvoicesService = new AutoFetchingInvoicesService(earchiveState.company.envObserver, earchiveState);
    makeObservable(this);
    this.clearState();
  }

  @computed
  get timeOptions() {
    const options = [];
    for (let i = 0; i < 24 * 60 / 60; i++) {
      const hour = Math.floor(i * 60 / 60);
      const minute = (i * 60) % 60;
      const formattedHour = hour.toString().padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      options.push(`${formattedHour}:${formattedMinute}`);
    }
    return options;
  }


  @computed
  public get disabledSend() {
    const isAuthorizedInKsef = this.earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
    const hasInvoiceWrite = this.earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
    return this.authIsPending || this.AutoSendingDisabled || (!isAuthorizedInKsef || !hasInvoiceWrite);
  }

  @computed
  public get disabledFetch() {
    const isAuthorizedInKsef = this.earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
    const hasInvoiceRead = this.earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceRead);
    return this.authIsPending || this.AutoFetchingDisabled || (!isAuthorizedInKsef || !hasInvoiceRead);
  }

  @computed
  public get sendingHourVariant() {
    return (this.SendInterval === 2 || this.SendInterval === 4) ? Tr.hours : Tr.hours2;
  }

  @computed
  public get fetchingHourVariant() {
    return (this.FetchInterval === 2 || this.FetchInterval === 4) ? Tr.hours : Tr.hours2;
  }

  @computed
  public get authIsPending(): boolean {
    return removeAuthorizationInKSeFService.isPending || authenticationState.loadingSignature || authenticationState.loadingToken;
  }

  @action.bound
  public setAutoFetchingInvoices(config: FetchingConfig | ((current: FetchingConfig) => FetchingConfig)): { FetchTime: string, FetchInterval: number } {
    const {
      Status,
      Internal,
      External,
      LastModifiedDate,
      LastModifiedBy,
      Type,
      Minutes,
      DownloadLimitDate,
    } = typeof config === 'function' ? config({
      External: this.AutoFetchingInvoices.ExternalEnabled,
      Internal: this.AutoFetchingInvoices.InternalEnabled,
      Type: this.AutoFetchingInvoices.Type,
      Minutes: this.AutoFetchingInvoices.Minutes,
      Status: this.AutoFetchingInvoices.Status,
      LastModifiedBy: this.AutoFetchingInvoices.LastModifiedBy,
      LastModifiedDate: this.AutoFetchingInvoices.LastModifiedDate,
      DownloadLimitDate: this.AutoFetchingInvoices.DownloadLimitDate,
    }) : config;
    this.AutoFetchingInvoices.Status = !!Status;
    this.AutoFetchingInvoices.InternalEnabled = !!Internal;
    this.AutoFetchingInvoices.ExternalEnabled = !!External;
    this.AutoFetchingInvoices.LastModifiedDate = LastModifiedDate ?? new Date(Date.now()).toLocaleDateString();
    this.AutoFetchingInvoices.LastModifiedBy = LastModifiedBy ?? 'system';
    this.AutoFetchingInvoices.Minutes = Status ? Minutes : undefined;
    this.AutoFetchingInvoices.Type = Type ?? KSeFAutomaticOperationType.Time;
    this.AutoFetchingInvoices.DownloadLimitDate = DownloadLimitDate;
        this.FetchType = Type ?? KSeFAutomaticOperationType.Time;
    if (Type === KSeFAutomaticOperationType.Interval && Minutes) {
      this.FetchInterval = Status ? (Minutes / 60) : 0;
      return {FetchTime: this.FetchTime, FetchInterval: this.FetchInterval};
    }
    this.FetchTime = (!Status || (!Minutes && Minutes !== 0)) ? '' : this.minutesToTime(Minutes);
    return {FetchTime: this.FetchTime, FetchInterval: this.FetchInterval};
  }

  @action.bound
  public setAutoSendingInvoices({
                                  Status,
                                  Minutes,
                                  LastModifiedDate,
                                  LastModifiedBy,
                                  Type,
                                }: SendingConfig): { SendTime: string, SendInterval: number } {
    this.AutoSendingInvoices.Status = !!Status;
    this.AutoSendingInvoices.LastModifiedDate = LastModifiedDate ?? new Date(Date.now()).toLocaleDateString();
    this.AutoSendingInvoices.LastModifiedBy = LastModifiedBy ?? 'system';
    this.AutoSendingInvoices.Minutes = Status ? Minutes : undefined;
    this.AutoSendingInvoices.Type = Type ?? KSeFAutomaticOperationType.Time;
    this.SendOption = Type ?? KSeFAutomaticOperationType.Time;
    this.DownloadLimitDateSelected = !!this.AutoFetchingInvoices.DownloadLimitDate;
    if (Type === KSeFAutomaticOperationType.Interval && Minutes) {
      this.SendInterval = Status ? (Minutes / 60) : 0;
      return {SendTime: this.SendTime, SendInterval: this.SendInterval};
    }
    this.SendTime = (!Status || (!Minutes && Minutes !== 0)) ? '' : this.minutesToTime(Minutes);
    return {SendTime: this.SendTime, SendInterval: this.SendInterval};
  }

  @action.bound
  public setDownloadLimitDateSelected(option: BackgroundActionsFormValues['DownloadLimitDateSelected']) {
    this.DownloadLimitDateSelected = option;
  }

  @action.bound
  public setFetchOption(option: BackgroundActionsFormValues['FetchOption'] | undefined): { External?: boolean, Internal?: boolean } {
    this.FetchOption = option;
    return this.checkFetchRadioOption(option);
  }

  @action.bound
  public getRadioOptionValue(internal: boolean | undefined, external: boolean | undefined): AutoFetchingInvoicesType | undefined {
    if (internal && external) return AutoFetchingInvoicesType.All;
    if (internal) return AutoFetchingInvoicesType.Internal;
    if (external) return AutoFetchingInvoicesType.External;
    return undefined;
  }

  @action.bound
  public checkFetchRadioOption(option: BackgroundActionsFormValues['FetchOption']): { Internal: boolean | undefined, External: boolean | undefined } {
    switch (option) {
      case AutoFetchingInvoicesType.External:
        this.AutoFetchingInvoices.ExternalEnabled = true;
        this.AutoFetchingInvoices.InternalEnabled = false;
        break;
      case AutoFetchingInvoicesType.Internal:
        this.AutoFetchingInvoices.ExternalEnabled = false;
        this.AutoFetchingInvoices.InternalEnabled = true;
        break;
      case AutoFetchingInvoicesType.All:
        this.AutoFetchingInvoices.ExternalEnabled = true;
        this.AutoFetchingInvoices.InternalEnabled = true;
        break;
      default:
        this.AutoFetchingInvoices.ExternalEnabled = false;
        this.AutoFetchingInvoices.InternalEnabled = false;
        break;
    }
    return {External: this.AutoFetchingInvoices.ExternalEnabled, Internal: this.AutoFetchingInvoices.InternalEnabled};
  }

  @action.bound
  public setSendOption(option: BackgroundActionsFormValues['SendOption']) {
    this.SendOption = option;
    this.AutoSendingInvoices.Type = option;
    if (option === KSeFAutomaticOperationType.Time) {
      this.setSendTime(this.SendTime);
    } else {
      this.setSendInterval(this.SendInterval);
    }
  }

  @action.bound
  public setSendTime(time: BackgroundActionsFormValues['SendTime']) {
    this.SendInterval = 0;
    this.SendTime = time;
    this.AutoSendingInvoices.Minutes = this.timeToMinutes(time) ?? 0;
  }

  @action.bound
  public setSendInterval(interval: BackgroundActionsFormValues['SendInterval']) {
    this.SendTime = '';
    this.SendInterval = interval;
    this.AutoSendingInvoices.Minutes = (interval ?? 2) * 60;
  }

  @action.bound
  public setFetchType(type: BackgroundActionsFormValues['FetchType']) {
    this.FetchType = type;
    this.AutoFetchingInvoices.Type = type;
    if (type === KSeFAutomaticOperationType.Time) {
      this.setFetchTime(this.FetchTime);
    } else {
      this.setFetchInterval(this.FetchInterval);
    }
  }

  @action.bound
  public setFetchTime(time: BackgroundActionsFormValues['FetchTime']) {
    this.FetchInterval = 0;
    this.FetchTime = time;
    this.AutoFetchingInvoices.Minutes = this.timeToMinutes(time) ?? 0;
  }

  @action.bound
  public setFetchInterval(interval: BackgroundActionsFormValues['FetchInterval']) {
    this.FetchTime = '';
    this.FetchInterval = interval;
    this.AutoFetchingInvoices.Minutes = (interval ?? 2) * 60;
  }

  @action.bound
  public reset() {
    const defaultValues = this.earchiveState.company.currentEnvironment?.UserProperties.EArchive;
    this.AutoFetchingInvoices = defaultValues?.AutoFetchingInvoices as AutoFetchingInvoicesModel ?? {
      InternalEnabled: defaultValues?.AutoFetchingInvoices?.InternalEnabled,
      ExternalEnabled: defaultValues?.AutoFetchingInvoices?.ExternalEnabled,
      Status: defaultValues?.AutoFetchingInvoices,
      LastModifiedBy: defaultValues?.AutoFetchingInvoices,
      LastModifiedDate: defaultValues?.AutoFetchingInvoices,
      DownloadLimitDate: defaultValues?.AutoFetchingInvoices?.DownloadLimitDate,
    };
    this.AutoSendingInvoices = defaultValues?.AutoSendingInvoices as AutoSendingInvoicesModel ?? {
      Status: defaultValues?.AutoSendingInvoices?.Status,
      LastModifiedBy: defaultValues?.AutoSendingInvoices?.LastModifiedBy,
      LastModifiedDate: defaultValues?.AutoSendingInvoices?.LastModifiedDate,
      Type: undefined,
      Minutes: undefined,
    };
    this.SendOption = defaultValues?.AutoSendingInvoices?.Type ?? KSeFAutomaticOperationType.Time;
    this.FetchType = defaultValues?.AutoFetchingInvoices?.Type ?? KSeFAutomaticOperationType.Time;
    this.SendTime = defaultValues?.AutoSendingInvoices?.Type === KSeFAutomaticOperationType.Time && defaultValues?.AutoSendingInvoices?.Minutes !== null ? this.minutesToTime(defaultValues?.AutoSendingInvoices?.Minutes) : '';
    this.SendInterval = defaultValues?.AutoSendingInvoices?.Type === KSeFAutomaticOperationType.Interval && defaultValues?.AutoSendingInvoices?.Minutes ? (defaultValues?.AutoSendingInvoices?.Minutes / 60) : 0;
    this.FetchTime = defaultValues?.AutoFetchingInvoices?.Type === KSeFAutomaticOperationType.Time && defaultValues?.AutoFetchingInvoices?.Minutes !== null ? this.minutesToTime(defaultValues?.AutoFetchingInvoices?.Minutes) : '';
    this.FetchInterval = defaultValues?.AutoFetchingInvoices?.Type === KSeFAutomaticOperationType.Interval && defaultValues?.AutoFetchingInvoices?.Minutes ? (defaultValues?.AutoFetchingInvoices?.Minutes / 60) : 0;
    this.AutoFetchingDisabled = this.disabledFetch;
    this.AutoSendingDisabled = this.disabledSend;
  }

  @action.bound
  public checkTheFetchSwitch(): void {
    let checked = false;
    let disabled = true;
    const isAuthorizedInKsef = this.earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
    const hasCompanyAutoFetch = this.earchiveState.company.autoFetchingInvoicesConfig?.Status;
    const hasInvoiceRead = this.earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceRead);
    const {isPackageNotActive} = this.earchiveState.packageStatistics;
    
    if (isPackageNotActive && isAuthorizedInKsef) {
      if (hasCompanyAutoFetch) {
        this.toggleAutoFetch(true, false);
        return;
      }
      this.toggleAutoFetch(false, true);
      return;
    }

    const autoFetchingInvoices = this.earchiveState.company.autoFetchingInvoicesConfig as AutoFetchingInvoicesModel;
    this.AutoFetchingInvoices.Status = autoFetchingInvoices.Status;

    if (!isAuthorizedInKsef && !hasInvoiceRead) {
      this.toggleAutoFetch(false, true);
      return;
    }

    if (!isAuthorizedInKsef) {
      this.toggleAutoFetch(false, true);
      return;
    }

    checked = !!hasCompanyAutoFetch;

    if (hasInvoiceRead) {
      disabled = false;
    }
    this.toggleAutoFetch(checked, disabled);
  }

  @action.bound
  public toggleAutoFetch(checked: boolean, disabled: boolean) {
    this.AutoFetchingInvoices.Status = checked;
    this.AutoFetchingDisabled = disabled;
  }

  @action.bound
  public checkTheSendSwitch(): void {
    let checked = false;
    let disabled = true;
    const isAuthorizedInKsef = this.earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
    const hasCompanyAutoSend = this.earchiveState.company.autoSendingInvoicesConfig?.Status;
    const hasInvoiceWrite = this.earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
    const {isPackageNotActive} = this.earchiveState.packageStatistics;

    if (isPackageNotActive && isAuthorizedInKsef) {
      if (hasCompanyAutoSend) {
        this.toggleAutoSend(true, false);
        return;
      }
      this.toggleAutoSend(false, true);
      return;
    }

    const autoSendingInvoices = this.earchiveState.company.autoSendingInvoicesConfig as AutoSendingInvoicesModel;
    this.AutoSendingInvoices.Status = autoSendingInvoices.Status;

    if (!isAuthorizedInKsef && !hasInvoiceWrite) {
      this.toggleAutoSend(false, true);
      return;
    }

    if (!isAuthorizedInKsef) {
      this.toggleAutoSend(false, true);
      return;
    }

    checked = !!hasCompanyAutoSend;

    if (hasInvoiceWrite) {
      disabled = false;
    }
    this.toggleAutoSend(checked, disabled);
  }

  @action.bound
  public toggleAutoSend(checked: boolean, disabled: boolean) {
    this.AutoSendingInvoices.Status = checked;
    this.AutoSendingDisabled = disabled;
  }

  @action.bound
  public timeToMinutes(time: string | undefined): number {
    if (time === undefined || time === null) return 0;
    const [hours, minutes] = time.split(':').map((value) => parseInt(value, 10));
    return hours * 60 + minutes;
  }

  @action.bound
  public minutesToTime(time: number | undefined): string {
    if (time === undefined || time === null) return '';
    if (time === 0) return '00:00';
    const minutes = (time % 60).toString();
    const hours = Math.floor(time / 60).toString();
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    return `${hoursStr}:${minutesStr}`;
  }

  @action.bound
  public checkTheStatus(status: boolean) {
    if (status) {
      return CompanyKsefServicesStatusEnum.Active;
    }
    return CompanyKsefServicesStatusEnum.Inactive;
  }

  @action.bound
  public onSubmit(dirtyFields: Partial<Readonly<BackgroundActionsFormValues>>) {
    const fetchModified = !!dirtyFields.AutoFetchingInvoices?.Status || !!dirtyFields.FetchOption || dirtyFields.FetchInterval || dirtyFields.FetchTime || dirtyFields.FetchType || !!dirtyFields.DownloadLimitDateSelected || !!dirtyFields.AutoFetchingInvoices?.DownloadLimitDate;
    const sendModified = !!dirtyFields.AutoSendingInvoices?.Status || dirtyFields.SendInterval || dirtyFields.SendTime || dirtyFields.SendOption;

    fetchModified &&
    this.autoFetchingInvoicesService.configure({
      Status: this.checkTheStatus(!!this.AutoFetchingInvoices?.Status),
      InternalEnabled: !!this.AutoFetchingInvoices.InternalEnabled,
      ExternalEnabled: !!this.AutoFetchingInvoices.ExternalEnabled,
      Minutes: this.AutoFetchingInvoices?.Minutes ?? 0,
      Type: this.AutoFetchingInvoices?.Type,
      DownloadLimitDate: this.DownloadLimitDateSelected ? this.AutoFetchingInvoices.DownloadLimitDate : null
    }).fetch()
      .then(() => {
        this.earchiveState.company.updateAutoFetchingConfig({
          Status: this.AutoFetchingInvoices.Status,
          LastModifiedBy: this.AutoFetchingInvoices.LastModifiedBy,
          LastModifiedDate: this.AutoFetchingInvoices.LastModifiedDate,
          InternalEnabled: this.AutoFetchingInvoices.InternalEnabled,
          ExternalEnabled: this.AutoFetchingInvoices.ExternalEnabled,
          Type: this.AutoFetchingInvoices.Type,
          Minutes: this.AutoFetchingInvoices.Minutes,
          DownloadLimitDate: this.DownloadLimitDateSelected ? this.AutoFetchingInvoices.DownloadLimitDate : undefined
        });
      });

    sendModified && this.autoSendingInvoicesService.configure({
      Status: this.checkTheStatus(!!this.AutoSendingInvoices?.Status),
      type: this.AutoSendingInvoices?.Type,
      minutes: this.AutoSendingInvoices?.Minutes ?? 0,
    }).fetch()
      .then(() => {
        this.earchiveState.company.updateAutoSendingConfig({
          Status: this.AutoSendingInvoices.Status,
          LastModifiedBy: this.AutoSendingInvoices.LastModifiedBy,
          LastModifiedDate: this.AutoSendingInvoices.LastModifiedDate,
          Minutes: this.AutoSendingInvoices.Status ? this.AutoSendingInvoices.Minutes : undefined,
          Type: this.AutoSendingInvoices.Type ?? undefined,
        });
      });

  }

  @action
  public clearState() {
    this.AutoFetchingInvoices = {
      Status: false,
      ExternalEnabled: false,
      InternalEnabled: false,
      LastModifiedBy: undefined,
      LastModifiedDate: undefined,
      Type: undefined,
      Minutes: 0,
      DownloadLimitDate: undefined,
    };
    this.AutoSendingInvoices = {
      Status: false,
      LastModifiedBy: undefined,
      LastModifiedDate: undefined,
      Type: undefined,
      Minutes: 0,
    };
    this.DownloadLimitDateSelected = false;
    this.FetchOption = undefined;
    this.SendOption = undefined;
    this.SendTime = '';
    this.SendInterval = 0;
    this.AutoFetchingDisabled = false;
    this.AutoSendingDisabled = false;
  }
}
