import React from 'react';
import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {AppInsightsContext} from './AppInsightsContext';
import {disableAppInsights} from '../../helpers/helperFunctions';

function applicationInsightsFactory(instrumentationKey: string, reactPlugin: ReactPlugin): ApplicationInsights {
  return new ApplicationInsights({
    config: {
      instrumentationKey,
      enableAjaxErrorStatusText: true,
      enableAjaxPerfTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      enableUnhandledPromiseRejectionTracking: true,
      enableAutoRouteTracking: true,
      autoTrackPageVisitTime: true,
      extensions: [reactPlugin],
    },
  });
}

export interface AppInsightsProviderProps extends PropsWithChildren {
  instrumentationKey: string;
}

export const AppInsightsProvider: FC<AppInsightsProviderProps> = ({instrumentationKey, children}) => {
  const [plugin, setPlugin] = useState<ReactPlugin>();
  const [client, setClient] = useState<ApplicationInsights>();

  useEffect(() => {
    if (!instrumentationKey || disableAppInsights) {
      return;
    }

    const reactPlugin = new ReactPlugin();
    setPlugin(reactPlugin);
    const appInsightsClient = applicationInsightsFactory(instrumentationKey, reactPlugin);
    setClient(appInsightsClient);
    appInsightsClient?.loadAppInsights();
  }, [instrumentationKey]);

  return <AppInsightsContext.Provider value={{client, plugin}}>{children}</AppInsightsContext.Provider>;
};
