import React, {FC, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {
  AlertVariant,
  ButtonTertiary,
  Icon,
  IconSvg,
  NotificationSize,
  NotificationVariant,
} from '@symfonia/brandbook';
import image from '../images/icon.png';
import {Notification} from '@symfonia/brandbook';
import {Tr} from '@symfonia-ksef/locales/keys';
import {intl} from '../../root/IntlProvider';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {observer} from 'mobx-react-lite';
import {FilehubAlert} from './FilehubAlert';
import {getSize} from '../utils/utils';
import {FilehubFile} from '../modals/AddAttachmentModal';
import {FormattedMessage} from 'react-intl';
import {earchiveState} from '@symfonia-ksef/state/rootRepository';
import {redirectToSymfoniaShop} from '../../common/helpers/redirectToSymfoniaShop';

export enum FilehubUploadFileWidth {
  FULL = 'FULL',
  FIT = 'FIT',
  BASE = 'BASE',
}

export type FilehubUploadFileProps = {
  className?: string;
  width?: FilehubUploadFileWidth;
  setFiles: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  files: FilehubFile[];
  setInvalidFiles: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setAttachmentsContainsFolder: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setFilesWithInvalidCharacters: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  setFilesWithExceededFileSize: React.Dispatch<React.SetStateAction<FilehubFile[]>>;
  invalidFiles: FilehubFile[];
  attachmentsContainsFolder: FilehubFile[];
  filesWithInvalidCharacters: FilehubFile[];
  filesWithExceededFileSize: FilehubFile[];
  state: IFilehubState;
  isLoading: boolean;
};

const documentExtensions = ['pdf'];
const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
const videoExtensions = ['mp4', 'avi', 'mkv'];
const audioExtensions = ['mp3', 'wav', 'aac'];

const getIconForFileType = (fileName: string): IconSvg => {
  const extension = fileName.split('.').pop()?.toLowerCase();

  if (!extension) return IconSvg.LIBRARY_BOOKS;

  if (documentExtensions.includes(extension)) return IconSvg.PICTURE_AS_PDF;
  if (imageExtensions.includes(extension)) return IconSvg.IMAGE;
  if (videoExtensions.includes(extension)) return IconSvg.VIDEO_LIBRARY;
  if (audioExtensions.includes(extension)) return IconSvg.AUDIOTRACK;

  return IconSvg.LIBRARY_BOOKS;
};

const forbiddenExtentions = ['.exe', '.com', '.bat', '.app', '.bin', '.php'];

const checkIsValid = (icon: string) => {
  return !forbiddenExtentions.some(el => icon.endsWith(el));
};

const checkIsFolder = async (file: File): Promise<boolean> => {
  if (file.type !== '' || file.size % 4096 !== 0 || file.size > 4 * 1024 * 1024) return false;

  return new Promise<boolean>(resolve => {
    const reader = new FileReader();

    reader.onload = () => resolve(false);
    reader.onerror = () => resolve(true);

    reader.readAsText(file);
  });
};

const invalidCharacters = ['\\', '/', ':', '*', '?', '"', '<', '>', '|', '#', '%'];

const checkContainsInvalidCharacters = (fileName: string): boolean => {
  return invalidCharacters.some(char => fileName.includes(char));
};

const checkFileSizeExceeded = (fileSize: number): boolean => fileSize > 104857600;

export const FilehubUploadFile: FC<FilehubUploadFileProps> = observer(
  ({
    className = undefined,
    setFiles,
    setInvalidFiles,
    setAttachmentsContainsFolder,
    setFilesWithInvalidCharacters,
    setFilesWithExceededFileSize,
    files,
    invalidFiles,
    attachmentsContainsFolder,
    filesWithInvalidCharacters,
    filesWithExceededFileSize,
    isLoading,
    state,
    width = FilehubUploadFileWidth.BASE,
  }) => {
    const [dragActive, setDragActive] = useState<boolean>(true);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const {length: allFilesLength} = files;
    const {length: allInvalidFilesLength} = invalidFiles;
    const {length: allContainsFolderLength} = attachmentsContainsFolder;
    const {length: allContainsInvalidCharactersLength} = filesWithInvalidCharacters;
    const {length: allContainsExceededFileSizeLength} = filesWithExceededFileSize;
    const areFiles = allFilesLength > 0;
    const areInvalidFiles = allInvalidFilesLength !== 0;
    const containsFolder = allContainsFolderLength !== 0;
    const containsInvalidCharacters = allContainsInvalidCharactersLength !== 0;
    const containsExceededFileSize = allContainsExceededFileSizeLength !== 0;
    const moreThanOneInvalidFile = allInvalidFilesLength > 1;
    const attachmentAddedHasStatus = state.successfulAddRequest !== null;
    const attachmentAddedSuccess = state.successfulAddRequest === true;
    const moreThanOneFile = state.addAttachmentsList.length > 1;
    const hasSizeFilesError = state.errorSizeFileList.length > 0;
    const hasMultipleSizeFilesError = state.errorSizeFileList.length > 1;

    useEffect(() => {
      const invalidFiles = files.filter(file => file.isValid === false);
      setInvalidFiles(invalidFiles);
      const containsFolder = files.filter(file => file.isFolder === true);
      setAttachmentsContainsFolder(containsFolder);
      const containsInvalidCharacters = files.filter(file => file.containsInvalidCharacters === true);
      setFilesWithInvalidCharacters(containsInvalidCharacters);
      const containsExceededFileSize = files.filter(file => file.containsExceededFileSize === true);
      setFilesWithExceededFileSize(containsExceededFileSize);
    }, [files]);

    const handleDrag = (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.type === 'dragenter' || e.type === 'dragover') {
        setDragActive(true);
      } else if (e.type === 'dragleave') {
        setDragActive(false);
      }
    };

    const handleDrop = async (e: React.DragEvent<HTMLDivElement | HTMLFormElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (areInvalidFiles) return;

      setDragActive(false);

      if (e.dataTransfer?.files && e.dataTransfer?.files[0]) {
        const addedFiles = await Promise.all(
          Array.from(e.dataTransfer.files).map(async file => ({
            file,
            id: crypto.randomUUID(),
            isValid: checkIsValid(file.name),
            isFolder: await checkIsFolder(file),
            containsInvalidCharacters: checkContainsInvalidCharacters(file.name),
            containsExceededFileSize: checkFileSizeExceeded(file.size),
          })),
        );
        setFiles(filesInState => [...filesInState, ...addedFiles]);
      }
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      e.preventDefault();

      if (e.target.files && e.target.files[0]) {
        const addedFiles = await Promise.all(
          Array.from(e.target.files).map(async file => ({
            file,
            id: crypto.randomUUID(),
            isValid: checkIsValid(file.name),
            isFolder: await checkIsFolder(file),
            containsInvalidCharacters: checkContainsInvalidCharacters(file.name),
            containsExceededFileSize: checkFileSizeExceeded(file.size),
          })),
        );
        setFiles(filesInState => [...filesInState, ...addedFiles]);
        e.target.value = '';
      }
    };

    const onButtonClick = () => {
      inputRef.current?.click();
    };

    const handleRemoveFile = (idDeleted: string) => {
      if (!isLoading) {
        setFiles(files => files.filter(({id}) => id !== idDeleted));
      }
    };

    const clearRequestAttachments = () => {
      state.setAddAttachmentsList([]);
      state.setSuccessfulAddRequest(null);
      state.setErrorSizeFileList([]);
    };

    const styles = {
      component: clsx(className, {
        'w-full': width === FilehubUploadFileWidth.FULL,
        'w-fit': width === FilehubUploadFileWidth.FIT,
        'w-[419px]': width === FilehubUploadFileWidth.BASE,
      }),
    };


    return (
      <div className={styles.component}>
        <div
          className="py-4 flex flex-col items-center border border-dashed border-grey-300 rounded-2xl font-quicksand mt-2"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
          id="drag-file-element"
        >
          <img src={image} alt="" draggable={false}/>
          <span className="text-base font-bold">Przeciągnij plik i upuść tutaj</span>
          <span>
            <form id="form-file-upload" onDragEnter={e => handleDrag(e)} onSubmit={e => e.preventDefault()}>
              <input
                disabled={areInvalidFiles || containsFolder || containsInvalidCharacters || containsExceededFileSize}
                ref={inputRef}
                type="file"
                id="input-file-upload"
                multiple
                onChange={e => handleChange(e)}
                style={{display: 'none'}}
              />
              <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? 'drag-active' : ''}>
                lub{' '}
                <ButtonTertiary
                  disabled={areInvalidFiles || containsFolder || containsInvalidCharacters || containsExceededFileSize}
                  text="Wybierz plik"
                  onClick={onButtonClick}
                />
              </label>
            </form>
          </span>
        </div>
        {areFiles && (
          <div className="flex justify-end my-[12px]">
            <div>
              <FormattedMessage
                id={Tr.numberOfAddedDocuments}
                values={{
                  countDocuments: (
                    <strong>
                      {allFilesLength - allInvalidFilesLength}/{allFilesLength}
                    </strong>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <div className="mt-[12px]">
          {areFiles &&
            files.map(({file, id, isValid, isFolder, containsInvalidCharacters, containsExceededFileSize}) => {
              const icon = getIconForFileType(file.name);

              return (
                <>
                  <div
                    key={id}
                    className={`mt-2 py-1 px-3 border border-solid ${
                      isValid && !isFolder && !containsInvalidCharacters && !containsExceededFileSize
                        ? 'border-grey-200'
                        : 'border-red-500'
                    } flex justify-between items-center rounded-xl`}
                  >
                    <div className="flex flex-row gap-[26px] items-center">
                      <div className="flex-none w-6">
                        <div className="bg-grey-100 p-[6px] w-[40px] h-[40px] rounded-full overflow-hidden flex items-center justify-center">
                          <Icon svg={icon}/>
                        </div>
                      </div>
                      <div>
                        <div className="break-all font-semibold">{file.name}</div>
                        <div>{getSize(file.size)}</div>
                      </div>
                    </div>
                    <div className="flex items-center cursor-pointer">
                      <Icon
                        className="p-[2px] w-[30px] h-[30px]"
                        onClick={() => handleRemoveFile(id)}
                        svg={IconSvg.CLOSE}
                      />
                    </div>
                  </div>
                  {/* To refactor */}
                  {!isValid && (
                    <Notification
                      className="my-[6px]"
                      size={NotificationSize.SM}
                      text={`${intl.formatMessage({id: Tr.formatOfFile})} ${file.name.slice(-4)}`}
                      variant={NotificationVariant.ERROR}
                    />
                  )}
                  {isFolder && (
                    <Notification
                      className="my-[6px]"
                      size={NotificationSize.SM}
                      text={intl.formatMessage({id: Tr.uploadFoldersProhibited})}
                      variant={NotificationVariant.ERROR}
                    />
                  )}
                  {containsInvalidCharacters && (
                    <Notification
                      className="my-[6px]"
                      size={NotificationSize.SM}
                      text={intl.formatMessage({id: Tr.filehubUploadFilesWithInvalidCharacters})}
                      variant={NotificationVariant.ERROR}
                    />
                  )}
                  {containsExceededFileSize && (
                    <Notification
                      className="my-[6px]"
                      size={NotificationSize.SM}
                      text={intl.formatMessage({id: Tr.filehubUploadFilesWithFileSizeExceeded})}
                      variant={NotificationVariant.ERROR}
                    />
                  )}
                </>
              );
            })}
        </div>
        {/* To refactor */}
        {areInvalidFiles && (
          <FilehubAlert
            className="mt-[16px]"
            description={
              intl.formatMessage(
                {id: moreThanOneInvalidFile ? Tr.cantAddFiles : Tr.cantAddFile},
                {
                  br: <br/>,
                  files: `${invalidFiles.map(x => x.file.name)}`,
                  format: `${invalidFiles.map(x => x.file.name.slice(-4))}`,
                },
              ) as string
            }
            title={intl.formatMessage({id: Tr.formatOfFile})}
            variant={AlertVariant.ERROR}
          />
        )}
        {containsFolder && (
          <FilehubAlert
            className="mt-[16px]"
            description={
              intl.formatMessage(
                {id: Tr.cantAddFolder},
                {
                  br: <br/>,
                  files: `${attachmentsContainsFolder.map(x => x.file.name)}`,
                },
              ) as string
            }
            title={intl.formatMessage({id: Tr.uploadFoldersProhibited})}
          />
        )}
        {containsInvalidCharacters && (
          <FilehubAlert
            className="mt-[16px]"
            description={
              intl.formatMessage(
                {id: Tr.filehubCantAddFileWithInvalidCharacters},
                {
                  br: <br/>,
                  files: `${filesWithInvalidCharacters.map(x => x.file.name)}`,
                  invalidCharacters: invalidCharacters.join(', '),
                },
              ) as string
            }
            title={intl.formatMessage({id: Tr.filehubUploadFilesWithInvalidCharacters})}
          />
        )}
        {containsExceededFileSize && (
          <FilehubAlert
            className="mt-[16px]"
            description={
              intl.formatMessage(
                {id: Tr.filehubCantAddFileWithFileSizeExceeded},
                {
                  br: <br/>,
                  files: `${filesWithInvalidCharacters.map(x => x.file.name)}`,
                },
              ) as string
            }
            title={intl.formatMessage({id: Tr.filehubUploadFilesWithFileSizeExceeded})}
          />
        )}
        {attachmentAddedHasStatus && (
          <FilehubAlert
            className="mt-[16px]"
            description={state.addAttachmentsList.map(file => (
              <div className="mt-[6px] break-all">{file}</div>
            ))}
            onClose={clearRequestAttachments}
            title={
              attachmentAddedSuccess
                ? moreThanOneFile
                  ? intl.formatMessage({id: Tr.attachmentsAddedSuccess})
                  : intl.formatMessage({id: Tr.attachmentAddedSuccess})
                : moreThanOneFile
                  ? intl.formatMessage({id: Tr.attachmentsAddedError})
                  : intl.formatMessage({id: Tr.attachmentAddedError})
            }
            variant={attachmentAddedSuccess ? AlertVariant.SUCCESS : AlertVariant.ERROR}
          />
        )}
        {hasSizeFilesError && (
          <FilehubAlert
            className="mt-[16px]"
            description={intl.formatMessage(
              {id: hasMultipleSizeFilesError ? Tr.filehubSizeLimitContentMultiple : Tr.filehubSizeLimitContentSingle},
              {
                br: <br/>,
                fileName: `${state.errorSizeFileList.map(file => file)}`,
                b: chunks => <strong>{chunks}</strong>,
                link: chunks => (
                  <a onClick={() => redirectToSymfoniaShop(earchiveState.packageStatistics)} className="font-bold text-red cursor-pointer">
                    {chunks}
                  </a>
                ),
              },
            )}
            onClose={clearRequestAttachments}
            title={intl.formatMessage({id: Tr.filehubSizeLimitHeader})}
          />
        )}
      </div>
    );
  },
);
