import {TextField} from '@mui/material';
import {Tr} from '@symfonia-ksef/locales/keys';
import {ButtonTertiary, ButtonTertiarySize, IconSvg} from '@symfonia/brandbook';
import {observer} from 'mobx-react-lite';
import {useState} from 'react';
import {IFilehubState} from '../../earchive/pages/Documents/state/IFilehubState';
import {intl} from '../../root/IntlProvider';
import {colors} from '../../../../../../apps/tailwind.colors';

type filehubTextareaProps = {
  state: IFilehubState;
};

const FilehubTextarea = observer(({state}: filehubTextareaProps) => {
  const [textFieldDisabled, setTextFieldDisabled] = useState(true);

  const height = 24;

  const handleChangeEmailText = (e: React.ChangeEvent<HTMLInputElement>) => {
    state.setSendByEmailText(e.target.value);
  };

  const editMessageText = () => {
    setTextFieldDisabled(!textFieldDisabled);
  };

  return (
    <div>
      <div className="mt-[16px]">
        {intl.formatMessage({id: Tr.messageText})}
      </div>
      <div className="mt-[8px]">
        <TextField
          inputProps={{style: {height, fontSize: 16, lineHeight: 1.5, fontFamily: 'quicksand'}}}
          InputProps={{style: {borderRadius: '8px'}}}
          multiline
          rows={4}
          value={state.sendByEmailText}
          onChange={handleChangeEmailText}
          size="medium"
          sx={{
            '& .MuiOutlinedInput-root': {
              ':hover fieldset': {
                borderColor: colors.primary[400],
              },
              '&.Mui-focused fieldset': {
                border: `0.5px solid ${colors.primary[400]}`,
              },
            },
          }}
          disabled={textFieldDisabled}
          fullWidth
        />
      </div>

      <div className="text-right mt-[8px]">
        <ButtonTertiary
          lIcon={IconSvg.EDIT}
          onClick={editMessageText}
          size={ButtonTertiarySize.MD}
          text={intl.formatMessage({id: textFieldDisabled ? Tr.editEmailMessage : Tr.saveEmailMessage})}
        />
      </div>
    </div>
  );
});

export default FilehubTextarea;
