import {yupResolver} from '@hookform/resolvers/yup';
import {observer} from 'mobx-react-lite';
import {useForm, useFormState , Controller} from 'react-hook-form';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {FormattedMessage, useIntl} from 'react-intl';
import React, {FC, useEffect, useMemo} from 'react';
import {KSeFAuthResult, KSeFAutomaticOperationType, KSeFTokenCredential} from '@symfonia-ksef/graphql';
import {dateParser} from '../../../../common';
import {SharedAccessSwitch} from './SharedAccess/SharedAccessSwitch';
import {WhiteListSwitch} from './WhiteList/WhiteListSwitch';
import {useSharedAccess} from './SharedAccess/useSharedAccess';
import {useWhiteList} from './WhiteList/useWhiteList';
import {backgroundActionsService, earchiveState} from '@symfonia-ksef/state/rootRepository';
import {
  AutoFetchingInvoicesType,
  BackgroundActionsFormValues,
} from '@symfonia-ksef/state/BackgroundActionsServices/BackgroundActionsService';
import {
  ButtonPrimary,
  ButtonSecondary,
  Checkbox,
  CheckboxSize,
  Dropdown,
  Icon,
  IconColor,
  IconSize,
  IconSvg,
  IconVariant,
  InputDate,
  RadioButton,
  RadioButtonGroup,
  Toggle,
  ToggleGroupOrientation,
  Tooltip,
} from '@symfonia/brandbook';
import {DATE_DMY} from '@symfonia/brandbook-external/types';
import {parseDate} from '../../../components/GetInvoicesForm/GetInvoicesForm.helpers';
import {normalizeDate} from '../../../../common/helpers/baseFilterHelpers';
import {adjustToPattern} from '@symfonia/symfonia-ksef-components';

interface BackgroundActionsFormProps {
  defaultValues: BackgroundActionsFormValues;
}

export const BackgroundActionsForm: FC<BackgroundActionsFormProps> = observer(({defaultValues}) => {
  const {
    AutoFetchingInvoices,
    AutoSendingInvoices,
    setSendInterval,
    setSendOption,
    setFetchTime,
    setFetchInterval,
    setFetchType,
    SendOption,
    FetchType,
    intervalOptions,
    SendInterval,
    SendTime,
    FetchTime,
    FetchInterval,
    setAutoFetchingInvoices,
    setAutoSendingInvoices,
    setSendTime,
    AutoSendingDisabled,
    AutoFetchingDisabled,
    timeOptions,
    disabledSend,
    disabledFetch,
    sendingHourVariant,
    fetchingHourVariant,
    checkTheSendSwitch,
    checkTheFetchSwitch,
    setFetchOption,
    getRadioOptionValue,
    BackgroundActionsSchema,
    authIsPending,
    setDownloadLimitDateSelected,
    DownloadLimitDateSelected
  } = backgroundActionsService;

  const {formatMessage} = useIntl();
  const user =
    earchiveState.company.currentEnvironment?.FirstName + ' ' + earchiveState.company.currentEnvironment?.LastName;
  const {handleSharedAccessChange, ...sharedAccessProps} = useSharedAccess({loadOnMount: false});
  const {handleWhiteListChange, ...whiteListProps} = useWhiteList({loadOnMount: false});
  const downloadLimitDate = DownloadLimitDateSelected ? AutoFetchingInvoices.DownloadLimitDate ? adjustToPattern(new Date(AutoFetchingInvoices.DownloadLimitDate!)) : '' : '';

  const {control, handleSubmit, reset, setValue, setError, clearErrors, getValues} = useForm<BackgroundActionsFormValues>({
    defaultValues,
    resolver: yupResolver(BackgroundActionsSchema),
    mode: 'onChange',
  });
  const {isValid, isDirty, dirtyFields, errors} = useFormState({
    control,
  });

  const isAuthorizedInKsef = earchiveState.company.authorizedInKSeF !== KSeFAuthResult.NotAuthorized;
  const hasInvoiceRead = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceRead);
  const hasInvoiceWrite = earchiveState.company.ksefCredentials.includes(KSeFTokenCredential.InvoiceWrite);
  const hasCompanyAutoFetch = earchiveState.company.autoFetchingInvoicesConfig?.Status;
  const hasCompanyAutoSend = earchiveState.company.autoSendingInvoicesConfig?.Status;
  const whoModifiedFetch = earchiveState.company.autoFetchingInvoicesConfig?.LastModifiedBy || 'system';
  const isDownloadLimitDateError = !!errors.AutoFetchingInvoices?.DownloadLimitDate;

  const {isPackageNotActive} = earchiveState.packageStatistics;
  const switchOnDateFetched =
    earchiveState.company.autoFetchingInvoicesConfig?.LastModifiedDate !== undefined
      ? dateParser(new Date(earchiveState.company.autoFetchingInvoicesConfig?.LastModifiedDate), true)
      : '';
  const whoModifiedSend = earchiveState.company.autoSendingInvoicesConfig?.LastModifiedBy || 'system';
  const switchOnDateSend =
    earchiveState.company.autoSendingInvoicesConfig?.LastModifiedDate !== undefined
      ? dateParser(new Date(earchiveState.company.autoSendingInvoicesConfig?.LastModifiedDate), true)
      : '';

  const saveChangesButtonEnabled = useMemo((): boolean => {
    if (AutoSendingInvoices.Status && SendOption === KSeFAutomaticOperationType.Time && SendTime === '') {
      return true;
    }
    if (AutoSendingInvoices.Status && SendOption === KSeFAutomaticOperationType.Interval && SendInterval === 0) {
      return true;
    }
    return disabledSend && disabledFetch;
  }, [dirtyFields, isValid, isDirty, AutoFetchingInvoices.Status, AutoSendingInvoices.Status, SendOption, SendTime, SendInterval, disabledSend, disabledFetch]);

  useEffect(() => {
    checkTheSendSwitch();
  }, [checkTheSendSwitch, disabledSend, hasCompanyAutoSend, hasInvoiceWrite, isAuthorizedInKsef, isPackageNotActive]);

  useEffect(() => {
    checkTheFetchSwitch();
  }, [checkTheFetchSwitch, disabledFetch, hasCompanyAutoFetch, hasInvoiceRead, isAuthorizedInKsef, isPackageNotActive]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onCancel = () => {
    backgroundActionsService.reset();
    reset(defaultValues);
  };

  function onSubmit() {
    if (AutoFetchingInvoices.Status && DownloadLimitDateSelected && !AutoFetchingInvoices.DownloadLimitDate) {
      setError('AutoFetchingInvoices.DownloadLimitDate', {});
      return;
    }
    handleSharedAccessChange();
    handleWhiteListChange();
    const newValues = {
      AutoFetchingInvoices: AutoFetchingInvoices,
      AutoSendingInvoices: AutoSendingInvoices,
      AutoFetchingDisabled: AutoFetchingDisabled,
      AutoSendingDisabled: AutoSendingDisabled,
      SendOption: SendOption,
      SendTime: SendOption === KSeFAutomaticOperationType.Time ? SendTime : '',
      SendInterval: SendOption === KSeFAutomaticOperationType.Interval ? (SendInterval ?? 2) * 60 : 0,
      FetchType: FetchType,
      FetchInterval: FetchType === KSeFAutomaticOperationType.Interval ? (FetchInterval ?? 2) * 60 : 0,
      DownloadLimitDateSelected: DownloadLimitDateSelected,
    };
    backgroundActionsService.onSubmit(dirtyFields as Partial<Readonly<BackgroundActionsFormValues>>);
    reset(newValues);
  }

  const invoiceTypesRadioButtons = [
    {
      label: intl.formatMessage({id: Tr.externalRadioLabel}),
      value: AutoFetchingInvoicesType.External,
      disabled: disabledFetch || !AutoFetchingInvoices.Status,
    },
    {
      label: intl.formatMessage({id: Tr.internalRadioLabel}),
      value: AutoFetchingInvoicesType.Internal,
      disabled: disabledFetch || !AutoFetchingInvoices.Status,
    },
    {
      label: intl.formatMessage({id: Tr.allRadioLabel}),
      value: AutoFetchingInvoicesType.All,
      disabled: disabledFetch || !AutoFetchingInvoices.Status,
    },
  ];

  return (
    <>
      {AutoFetchingInvoices?.Status !== undefined && (
        <>
          <div className="flex items-center">
            <Controller
              name="AutoFetchingInvoices.Status"
              control={control}
              render={({field}) => (
                  <Toggle
                    testId="settingsAutoFetchingToggle"
                    value=""
                    checked={AutoFetchingInvoices.Status}
                    disabled={disabledFetch}
                    label={<FormattedMessage id={Tr.backgroundInvoiceDownload}/>}
                    onChange={checked => {
                      if (!checked) {
                        field.onChange(checked);
                        setValue('FetchOption', defaultValues.FetchOption);
                        setValue('FetchType', defaultValues.FetchType);
                        setValue('FetchTime', defaultValues.FetchTime);
                        setValue('FetchInterval', defaultValues.FetchInterval);
                        setValue('DownloadLimitDateSelected', false);
                        setDownloadLimitDateSelected(false);
                        setAutoFetchingInvoices({
                          Internal: undefined,
                          External: undefined,
                          Status: checked,
                          LastModifiedBy:
                            earchiveState.company.currentEnvironment?.FirstName +
                            ' ' +
                            earchiveState.company.currentEnvironment?.LastName,
                          LastModifiedDate: new Date().toUTCString(),
                          Minutes: 0,
                          Type: undefined,
                          DownloadLimitDate: undefined,
                        });
                        return;
                      }
                      field.onChange(checked);
                      const {Time} = KSeFAutomaticOperationType;
                      const type = defaultValues.AutoFetchingInvoices?.Type ?? Time;
                      const minutes = defaultValues.AutoFetchingInvoices?.Minutes ?? 720;
                      const {Internal, External, All} = AutoFetchingInvoicesType;
                      const option = defaultValues.FetchOption ?? All;
                      setValue('FetchOption', option);
                      setValue('DownloadLimitDateSelected', true);
                      setDownloadLimitDateSelected(true);
                      const internal = option === Internal || option === All;
                      const external = option === External || option === All;
                      const {FetchTime, FetchInterval} = setAutoFetchingInvoices({
                        Internal: internal,
                        External: external,
                        Status: checked,
                        LastModifiedBy: user,
                        LastModifiedDate: new Date().toUTCString(),
                        Minutes: minutes,
                        Type: type,
                        DownloadLimitDate: undefined,
                      });
                      type === Time
                        ? setValue('FetchTime', FetchTime)
                        : setValue('FetchInterval', FetchInterval);
                    }}
                />)
              }
            />
            <Tooltip
              position="right"
              text={formatMessage({id: Tr.autoFetchingSwitchTooltip}, {br: <br/>, b: (chunks) => <strong>{chunks}</strong>, li: (chunks) => <ul className='list-disc'><li className='list-disc ml-[20px]'>{chunks}</li></ul>})}>
              <Icon
                svg={IconSvg.WARNING_CIRCLE}
                size={IconSize.LG}
                variant={IconVariant.CONTOUR}
                color={IconColor.BLUE1_500}
                className="ml-[10px]"
              />
            </Tooltip>
          </div>
          {earchiveState.company.autoFetchingInvoicesConfig?.LastModifiedBy && (
            <div className="flex items-center mt-[15px]">
              <Icon
                svg={IconSvg.WARNING_CIRCLE}
                size={IconSize.MD}
                variant={IconVariant.CONTOUR}
                color={IconColor.GREY_500}
                className="mr-[10px]"
              />
              <span data-testid="autoFetchingActivityInfoParagraph" className="text-grey-500">
                {formatMessage(
                  {id: Tr.switchStateInfo},
                  {
                    state: intl.formatMessage({
                      id: earchiveState.company.autoFetchingInvoicesConfig?.Status
                        ? Tr.whoActivated
                        : Tr.whoDeactivated,
                    }),
                    user: whoModifiedFetch,
                    date: switchOnDateFetched,
                  },
                )}
              </span>
            </div>
          )}
          {AutoFetchingInvoices?.Status && (
            <div>
              <Controller
                control={control}
                defaultValue={defaultValues.FetchOption}
                name="FetchOption"
                render={({field}) => (
                  <RadioButtonGroup
                    onChange={checked => {
                      field.onChange(checked[0]);
                      const options = setFetchOption(checked[0] as AutoFetchingInvoicesType);
                      setAutoFetchingInvoices(current => ({...current, ...options}));
                    }}
                    testId="invoiceTypeRadioButtonGroup"
                    value={getRadioOptionValue(AutoFetchingInvoices.InternalEnabled, AutoFetchingInvoices.ExternalEnabled) as any ?? ''}
                    checkboxes={invoiceTypesRadioButtons}
                    orientation={ToggleGroupOrientation.VERTICAL}
                    className="pt-[30px] pb-[30px]"
                  />
                )}
              />
              <div>
                <div className="flex items-center gap-2">
                  <Controller
                    control={control}
                    name="DownloadLimitDateSelected"
                    render={({field}) => (
                      <Checkbox
                        className="mb-2"
                        label={intl.formatMessage({id: Tr.downloadLimitDate})}
                        onChange={val => {
                          field.onChange(!field.value);
                          setDownloadLimitDateSelected(!field.value);
                        }}
                        checked={field.value}
                        size={CheckboxSize.SM}
                        disabled={!AutoFetchingInvoices.Status}
                        value={''}
                        testId="downloadLimitDateCheckbox"
                    />
                    )}
                  />
                  <Controller
                    name="AutoFetchingInvoices.DownloadLimitDate"
                    control={control}
                    render={({field}) => (
                      <InputDate
                        value={downloadLimitDate as DATE_DMY}
                        disabled={disabledFetch || !AutoFetchingInvoices.Status || !DownloadLimitDateSelected}
                        isError={!!errors.AutoFetchingInvoices?.DownloadLimitDate}
                        onChange={date => {
                            const dateValue = isNaN(parseDate(date).getTime()) ? undefined : normalizeDate(true, parseDate(date), true);
                            field.onChange(dateValue);
                            setAutoFetchingInvoices(current => (
                              {
                                ...current,
                                LastModifiedBy: earchiveState.company.currentEnvironment?.FirstName +
                                ' ' +
                                earchiveState.company.currentEnvironment?.LastName,
                                LastModifiedDate: (new Date()).toUTCString(),
                                DownloadLimitDate: dateValue
                              }));
                            clearErrors('AutoFetchingInvoices.DownloadLimitDate');
                        }}
                      />
                    )}
                  />
                  <Tooltip position="right" text={formatMessage({id: Tr.downloadLimitDateTooltip_Content}, {br: <br/>})}>
                    <Icon
                      svg={IconSvg.WARNING_CIRCLE}
                      size={IconSize.LG}
                      variant={IconVariant.CONTOUR}
                      color={IconColor.BLUE1_500}
                      className="ml-[10px]"
                    />
                  </Tooltip>
                </div>
                {!DownloadLimitDateSelected
                 ?
                  <div className="mt-4">
                    <FormattedMessage id={Tr.downloadLimitDateWarning}/>
                  </div>
                  : null
                }
              </div>
              <div className="mt-6">
                <Controller
                  name="FetchType"
                  control={control}
                  render={({field}) => (
                    <>
                      <RadioButton
                        value={KSeFAutomaticOperationType.Time ?? ''}
                        onChange={checked => {
                          field.onChange(checked);
                          const {Time} = KSeFAutomaticOperationType;
                          const type = checked ? KSeFAutomaticOperationType.Time : KSeFAutomaticOperationType.Interval;
                          const minutes = defaultValues.AutoFetchingInvoices?.Minutes ?? 720;
                          setFetchType(type);
                          const {FetchTime, FetchInterval} = setAutoFetchingInvoices(current => ({
                            ...current,
                            Status: true,
                            Minutes: minutes,
                            Type: type,
                            LastModifiedBy:
                              earchiveState.company.currentEnvironment?.FirstName +
                              ' ' +
                              earchiveState.company.currentEnvironment?.LastName,
                            LastModifiedDate: new Date().toUTCString(),
                          }));
                          type === Time ? setValue('FetchTime', FetchTime) : setValue('FetchInterval', FetchInterval);
                        }}
                        checked={FetchType === KSeFAutomaticOperationType.Time}
                        disabled={disabledFetch || !AutoFetchingInvoices.Status}
                        testId="invoiceFetchTypeRadioButton"
                        label={<FormattedMessage id={Tr.dailyPeriodSwitchLabel}/>}
                      />
                      <Tooltip position="right" text={formatMessage({id: Tr.downloadSwitchOn}, {br: <br/>})}>
                        <Icon
                          svg={IconSvg.WARNING_CIRCLE}
                          size={IconSize.LG}
                          variant={IconVariant.CONTOUR}
                          color={IconColor.BLUE1_500}
                          className="ml-[10px]"
                        />
                      </Tooltip>
                      <div className="mt-[15px]">
                        <FormattedMessage id={Tr.downloadAtTime}/>
                      </div>
                      <div className="my-[15px]">
                        <Controller
                          name="FetchTime"
                          control={control}
                          defaultValue={defaultValues.FetchTime.toString()}
                          render={({field}) => (
                            <Dropdown
                              value={[FetchTime]}
                              multiple={false}
                              placeholder={intl.formatMessage({id: Tr.choosePlaceholder})}
                              options={timeOptions.map((option: string) => {
                                return {label: option, value: option};
                              })}
                              disabled={FetchType !== KSeFAutomaticOperationType.Time}
                              onChange={val => {
                                field.onChange(val[0]);
                                setFetchTime(val[0] as BackgroundActionsFormValues['FetchTime']);
                              }}
                            />
                          )}
                        />
                      </div>
                      <RadioButton
                        value={KSeFAutomaticOperationType.Interval}
                        onChange={checked => {
                          field.onChange(checked);
                          const {Time} = KSeFAutomaticOperationType;
                          const type = checked ? KSeFAutomaticOperationType.Interval : KSeFAutomaticOperationType.Time;
                          const minutes = defaultValues.AutoSendingInvoices?.Minutes ?? 720;
                          setFetchType(type);
                          const {FetchTime, FetchInterval} = setAutoFetchingInvoices(current => ({
                            ...current,
                            Status: true,
                            Minutes: minutes,
                            Type: type,
                            LastModifiedBy:
                              earchiveState.company.currentEnvironment?.FirstName +
                              ' ' +
                              earchiveState.company.currentEnvironment?.LastName,
                            LastModifiedDate: new Date().toUTCString(),
                          }));
                          type === Time ? setValue('FetchTime', FetchTime) : setValue('FetchInterval', FetchInterval);
                        }}
                        checked={FetchType === KSeFAutomaticOperationType.Interval}
                        disabled={disabledFetch || !AutoFetchingInvoices.Status}
                        testId="invoiceFetchIntervalRadioButton"
                        label={<FormattedMessage id={Tr.intervalPeriodSwitchLabel}/>}
                      />

                      <Tooltip position="right" text={formatMessage({id: Tr.downloadSwitchOnInterval}, {br: <br/>})}>
                        <Icon
                          svg={IconSvg.WARNING_CIRCLE}
                          size={IconSize.LG}
                          variant={IconVariant.CONTOUR}
                          color={IconColor.BLUE1_500}
                          className="ml-[10px]"
                        />
                      </Tooltip>
                      <div className="mt-[15px]">
                        <FormattedMessage id={Tr.downloadInterval}/>
                      </div>
                      <div className="my-[15px]">
                        <Controller
                          name="FetchInterval"
                          control={control}
                          defaultValue={defaultValues.FetchInterval}
                          render={({field}) => (
                            <Dropdown
                              value={[FetchInterval.toString()]}
                              multiple={false}
                              placeholder={intl.formatMessage({id: Tr.choosePlaceholder})}
                              options={intervalOptions.map((option: [number, string]) => {
                                return {label: option[1], value: option[0].toString()};
                              })}
                              disabled={FetchType !== KSeFAutomaticOperationType.Interval}
                              onChange={val => {
                                field.onChange(val[0]);
                                setFetchInterval(+val[0] as BackgroundActionsFormValues['FetchInterval']);
                                setValue('FetchInterval', +val[0]);
                              }}
                            />)}
                        />
                      </div>
                    </>
                    )}
                  />
              </div>
            </div>
          )}
        </>
      )}
      {AutoSendingInvoices?.Status !== undefined && (
          <div className="flex items-center mt-[25px]">
            <Controller
              name="AutoSendingInvoices.Status"
              control={control}
              render={({field}) => (
                <Toggle
                  testId="settingsAutoSendingToggle"
                  value=""
                  checked={AutoSendingInvoices.Status}
                  disabled={disabledSend}
                  label={<FormattedMessage id={Tr.backgroundInvoiceSending}/>}
                  onChange={checked => {
                    if (!checked) {
                      field.onChange(checked);
                      setValue('SendOption', defaultValues.SendOption);
                      setValue('SendTime', defaultValues.SendTime);
                      setValue('SendInterval', defaultValues.SendInterval);
                      setAutoSendingInvoices({
                        Status: checked,
                        LastModifiedBy:
                          earchiveState.company.currentEnvironment?.FirstName +
                          ' ' +
                          earchiveState.company.currentEnvironment?.LastName,
                        LastModifiedDate: new Date().toUTCString(),
                        Minutes: 0,
                        Type: undefined,
                      });
                      return;
                    }
                    field.onChange(checked);
                    const {Time} = KSeFAutomaticOperationType;
                    const type = defaultValues.AutoSendingInvoices?.Type ?? Time;
                    const minutes = defaultValues.AutoSendingInvoices?.Minutes ?? 720;
                    const {SendTime, SendInterval} = setAutoSendingInvoices({
                      Status: checked,
                      LastModifiedBy: user,
                      LastModifiedDate: new Date().toUTCString(),
                      Minutes: minutes,
                      Type: type,
                    });
                    type === Time
                      ? setValue('SendTime', SendTime)
                      : setValue('SendInterval', SendInterval);
                  }}
                />
              )}
            />
            <Tooltip
              position="right"
              text={formatMessage({id: Tr.invoiceSendSwitchTooltip}, {br: <br/>})}>
              <Icon
                svg={IconSvg.WARNING_CIRCLE}
                size={IconSize.LG}
                variant={IconVariant.CONTOUR}
                color={IconColor.BLUE1_500}
                className="ml-[10px]"
              />
            </Tooltip>
          </div>
        )}
        <div>
          {earchiveState.company.autoSendingInvoicesConfig?.LastModifiedBy && (
            <div className="flex items-center mt-[15px]">
              <Icon
                svg={IconSvg.WARNING_CIRCLE}
                size={IconSize.MD}
                variant={IconVariant.CONTOUR}
                color={IconColor.GREY_500}
                className="mr-[10px]"
              />
              <span data-testid="autoSendingActivityInfoParagraph" className="text-grey-500">
                {formatMessage(
                  {id: Tr.switchStateInfo},
                  {
                    state: intl.formatMessage({
                      id: earchiveState.company.autoSendingInvoicesConfig?.Status
                        ? Tr.whoActivated
                        : Tr.whoDeactivated,
                    }),
                    user: whoModifiedSend,
                    date: switchOnDateSend,
                  },
                )}
              </span>
            </div>
          )}
        </div>
        {AutoSendingInvoices?.Status && (
          <div>
            <div className='mt-[30px]'>
              <Controller
                control={control}
                name="SendOption"
                defaultValue={defaultValues.SendOption}
                render={({field}) => (
                  <>
                    <RadioButton
                      value={KSeFAutomaticOperationType.Time}
                      onChange={checked => {
                        field.onChange(checked);
                        const type = checked ? KSeFAutomaticOperationType.Time : KSeFAutomaticOperationType.Interval;
                        const {Time} = KSeFAutomaticOperationType;
                        const minutes = defaultValues.AutoSendingInvoices?.Minutes ?? 720;
                        setSendOption(type);
                        const {SendTime, SendInterval} = setAutoSendingInvoices(({
                          Status: true,
                          Minutes: minutes,
                          Type: type,
                          LastModifiedBy:
                            earchiveState.company.currentEnvironment?.FirstName +
                            ' ' +
                            earchiveState.company.currentEnvironment?.LastName,
                          LastModifiedDate: new Date().toUTCString(),
                        }));
                        type === Time ? setValue('SendTime', SendTime) : setValue('SendInterval', SendInterval);
                      }}
                      checked={SendOption === KSeFAutomaticOperationType.Time}
                      disabled={disabledSend || !AutoSendingInvoices.Status}
                      testId="invoiceSendTimeRadioButton"
                      label={<FormattedMessage id={Tr.dailyPeriodSwitchLabel}/>}
                    />
                    <Tooltip position="right" text={formatMessage({id: Tr.sendSwitchOn}, {br: <br/>})}>
                      <Icon
                        svg={IconSvg.WARNING_CIRCLE}
                        size={IconSize.LG}
                        variant={IconVariant.CONTOUR}
                        color={IconColor.BLUE1_500}
                        className="ml-[10px]"
                      />
                    </Tooltip>
                    <div className="mt-[15px]">
                      <FormattedMessage id={Tr.sendAtTime}/>
                    </div>
                      <Controller
                        name="SendTime"
                        control={control}
                        defaultValue={defaultValues.SendTime}
                        render={({field}) => (
                          <Dropdown
                            value={[SendTime]}
                            multiple={false}
                            placeholder={intl.formatMessage({id: Tr.choosePlaceholder})}
                            options={timeOptions.map((option: string) => {
                              return {label: option, value: option};
                            })}
                            disabled={SendOption !== KSeFAutomaticOperationType.Time}
                            onChange={val => {
                              field.onChange(val[0]);
                              setSendTime(val[0] as BackgroundActionsFormValues['SendTime']);
                            }}
                          />)}
                      />
                    <div className="my-[15px]">
                      <RadioButton
                        value={KSeFAutomaticOperationType.Interval}
                        onChange={checked => {
                          field.onChange(checked);
                          const {Time} = KSeFAutomaticOperationType;
                          const type = checked ? KSeFAutomaticOperationType.Interval : KSeFAutomaticOperationType.Time;
                          const minutes = defaultValues.AutoSendingInvoices?.Minutes ?? 720;
                          setSendOption(type);
                          const {SendTime, SendInterval} = setAutoSendingInvoices(({
                            Status: true,
                            Minutes: minutes,
                            Type: type,
                            LastModifiedBy:
                              earchiveState.company.currentEnvironment?.FirstName +
                              ' ' +
                              earchiveState.company.currentEnvironment?.LastName,
                            LastModifiedDate: new Date().toUTCString(),
                          }));
                          type === Time ? setValue('SendTime', SendTime) : setValue('SendInterval', SendInterval);
                        }}
                        checked={SendOption === KSeFAutomaticOperationType.Interval}
                        disabled={disabledSend || !AutoSendingInvoices.Status}
                        testId="invoiceSendIntervalRadioButton"
                        label={<FormattedMessage id={Tr.intervalPeriodSwitchLabel}/>}
                      />
                      <Tooltip position="right" text={formatMessage({id: Tr.sendSwitchInterval}, {br: <br/>})}>
                        <Icon
                          svg={IconSvg.WARNING_CIRCLE}
                          size={IconSize.LG}
                          variant={IconVariant.CONTOUR}
                          color={IconColor.BLUE1_500}
                          className="ml-[10px]"
                        />
                      </Tooltip>
                      <div className="mt-[15px]">
                        <FormattedMessage id={Tr.sendInterval}/>
                      </div>
                      <div className="my-[15px]">
                      <Controller
                        name="SendInterval"
                        control={control}
                        defaultValue={defaultValues.SendInterval}
                        render={({field}) => (
                          <Dropdown
                            value={[SendInterval.toString()]}
                            multiple={false}
                            placeholder={intl.formatMessage({id: Tr.choosePlaceholder})}
                            options={intervalOptions.map((option: [number, string]) => {
                              return {label: option[1], value: option[0].toString()};
                            })}
                            disabled={SendOption !== KSeFAutomaticOperationType.Interval}
                            onChange={val => {
                              field.onChange(val[0]);
                              setSendInterval(+val[0] as BackgroundActionsFormValues['SendInterval']);
                              setValue('SendInterval', +val[0], {shouldValidate: true, shouldDirty: true});
                            }}
                         />
                        )}
                      />
                    </div>
                  </div>
                  </>
                )}
              />
            </div>
        </div>
      )}
      <SharedAccessSwitch {...sharedAccessProps} disabled={authIsPending}/>
      <WhiteListSwitch {...whiteListProps} disabled={authIsPending}/>
      {
        <div className="mt-[30px] flex flex-row justify-end items-center buttonFixed">
          <ButtonSecondary
            text={intl.formatMessage({id: Tr.cancelButton})}
            className="btn btn-md mr-[10px]"
            disabled={(disabledSend && disabledFetch) || !isDirty}
            onClick={onCancel}
            testId="backgroundActionsCancelButton"
          />
          <ButtonPrimary
            testId="backgroundActionsSubmitButton"
            text={intl.formatMessage({id: Tr.save})}
            className="btn btn-md"
            disabled={
              !sharedAccessProps.isDirty &&
              !whiteListProps.isDirty &&
              !isDownloadLimitDateError &&
              (saveChangesButtonEnabled || !isValid || !isDirty)
            }
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      }
    </>
  );
});
