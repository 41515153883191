import {ButtonPrimary, ButtonSecondary} from '@symfonia/brandbook';
import {intlFunction} from '../utils/utils';
import {Tr} from '@symfonia-ksef/locales/keys';
import {observer} from 'mobx-react-lite';

type Props = {
  loading?: boolean;
  onClose?: () => void;
  onSave?: () => void;
  isCancelEnabled?: boolean;
  isSaveEnabled?: boolean;
  primaryButtonText?: Tr;
  secondaryButtonText?: Tr;
};

const FilehubFooterButtons = observer(
  ({loading, onClose, onSave, isCancelEnabled = true, isSaveEnabled = true, primaryButtonText = Tr.save, secondaryButtonText = Tr.undo}: Props) => {
    return (
      <div className="flex justify-between gap-[16px]">
        <ButtonSecondary
          disabled={!isCancelEnabled || loading}
          onClick={onClose}
          className="w-full"
          text={intlFunction(secondaryButtonText)}
        />
        <ButtonPrimary
          disabled={!isSaveEnabled}
          className="w-full"
          onClick={onSave}
          loading={loading}
          text={intlFunction(primaryButtonText)}
        />
      </div>
    );
  },
);

export default FilehubFooterButtons;
