import {ExtendedTableServiceI} from '../../../../root/services/TableServices/ExtendedTableService';
import {
  CredentialsFragment,
  GetPermissionsKSeFQuery,
  GetPermissionsKSeFQueryVariables,
  OrderDirection,
} from '@symfonia-ksef/graphql';
import {intl} from '../../../../root/IntlProvider';
import {Tr} from '@symfonia-ksef/locales/keys';
import {DataSourceHandlerI, GetVariablesParams} from '../../../../root/services/TableServices/DataSourceTableService';
import React from 'react';
import {PermissionsRepositoryI} from '../Permissions.repository';
import {Columns} from '../../../../root/services/TableServices/BaseTableService';
import {PermissonsTableList} from './PermissonsTableList';
import {TableSortDirection} from '@symfonia/brandbook';
import {Button} from '@mui/material';
import MoreVert from '@mui/icons-material/MoreVert';

export type PermissionsRow = CredentialsFragment & { Id: string }

export type PermissionsColumns = Columns<PermissionsRow>

export type PermissionsTableI = ExtendedTableServiceI<PermissionsRow, 'GetPermissionsKSeF', GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables>

export class PermissionsDataSourceHandler implements DataSourceHandlerI<'GetPermissionsKSeF', GetPermissionsKSeFQuery, GetPermissionsKSeFQueryVariables, PermissionsRow> {
  constructor(public repository: PermissionsRepositoryI) {
  }

  public dataMapper(data: GetPermissionsKSeFQuery['GetPermissionsKSeF'] | null) {
    if (data?.Credentials) {
      return data.Credentials.map(credential => ({
        ...credential,
        Id: credential.Identifier + credential.CredentialsSelfInvoicing,
      }));
    }
    return [];
  }

  public getTotalCount(data: GetPermissionsKSeFQuery['GetPermissionsKSeF']): number {
    return data.FilteredCount;
  }

  public prepareVariablesConfig(params: GetVariablesParams) {
    const variables: Partial<GetPermissionsKSeFQueryVariables> = {};
    variables.skip = params.offset;
    variables.take = params.size;
    if (params.sortBy?.name) {
      variables.order = {
        FieldName: params.sortBy.name ?? '',
        Direction: params.sortBy?.direction === TableSortDirection.ASC ? OrderDirection.Asc : OrderDirection.Desc,
      };
    }
    return variables;
  }
}

export const permissionsTableKeysFactory = (row: PermissionsRow): string => row.Id;

export const permissionsInitialColumnsFactory = (onEditClicked: (userPermissions: CredentialsFragment, element: HTMLElement) => void, areActionsDisabled: boolean | undefined): PermissionsColumns => ({
  Identifier: {
    header: intl.formatMessage({id: Tr.forWho}),
    width: 'w-[300px]',
    sortable: true,
    order: 1,
    isObserver: true,
    cell: (row) => {
      return <span>{row.Identifier}</span>;
    },
  },
  CredentialsInvoiceRead: {
    header: intl.formatMessage({id: Tr.permissions}),
    sortable: false,
    order: 2,
    isObserver: true,
    width: 'w-[1200px]',
    cell: (row) => {
      return <PermissonsTableList val={row}/>;
    },
  },
  CredentialsSelfInvoicing: {
    header: intl.formatMessage({id: Tr.actions}),
    order: 3,
    asAction: true,
    width: 'w-[70px]',
    cell: (row) => {
      return <Button size="small"
                     onClick={(e) => onEditClicked(row, e.currentTarget)}
                     disabled={areActionsDisabled}
                     data-testId={`${row.Identifier}PermissionsTableActionButton`}
      ><MoreVert className={'IconWithTransform'}/></Button>;
    },
  },
});
