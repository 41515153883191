import {calendarT} from './Calendar.t';

type ArrayElement<ArrayType> = ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export const tMonths = [
  calendarT.styczen,
  calendarT.luty,
  calendarT.marzec,
  calendarT.kwiecien,
  calendarT.maj,
  calendarT.czerwiec,
  calendarT.lipiec,
  calendarT.sierpien,
  calendarT.wrzesien,
  calendarT.pazdziernik,
  calendarT.listopad,
  calendarT.grudzien,
] as const;

export const tDays = [
  calendarT.poniedzialek,
  calendarT.wtorek,
  calendarT.sroda,
  calendarT.czwartek,
  calendarT.piatek,
  calendarT.sobota,
  calendarT.niedziela,
] as const;

export const tShortDays = [
  calendarT.pn,
  calendarT.wt,
  calendarT.sr,
  calendarT.cz,
  calendarT.pt,
  calendarT.sb,
  calendarT.nd,
] as const;

export enum CalendarView {
  Calendar,
  MonthSelector,
  YearSelector,
}

export type CalendarMonthAndYear = {
  month: number;
  year: number;
};

export const getMonthIndex = (month: ArrayElement<typeof tMonths>): number => tMonths.findIndex(m => m === month);

export const monthMatrix = [
  [calendarT.styczen, calendarT.luty, calendarT.marzec],
  [calendarT.kwiecien, calendarT.maj, calendarT.czerwiec],
  [calendarT.lipiec, calendarT.sierpien, calendarT.wrzesien],
  [calendarT.pazdziernik, calendarT.listopad, calendarT.grudzien],
];

export const moduloDivideArray = <T>(array: T[], modulo: number): T[][] => {
  return array.reduce((a, c, i) => {
    if (i % modulo === 0) {
      a.push([]);
    }
    a[a.length - 1].push(c);
    return a;
  }, [] as T[][]);
};

export const getDecade = (year: number): number => Math.floor(year / 10) * 10;
export const getDecadeMatrix = (year: number) => {
  const decade: number = getDecade(year);
  const base = Array.from({length: 10}, (v, i) => decade + i);
  base.unshift(decade - 1);
  base.push(decade + 10);
  return moduloDivideArray(base, 3);
};

export const dayOfYear = (date: Date): number =>
  Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);

export enum EditedDateType {
  DATE,
  END_DATE,
}

export const getDaysInMonth = (date: Date): number => new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
export const getFirstDayOfMonthDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth(), 1);
export const getLastDayOfMonthDate = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
export const getFirstDayOfMonth = (year: number, monthIndex: number): Date => new Date(year, monthIndex, 1);
export const getLastDayOfMonth = (year: number, monthIndex: number): Date => new Date(year, monthIndex + 1, 0);

export const getFirstDayOfMonthOffset = (date: Date): number => getFirstDayOfMonthDate(date).getDay();
export const getWeeksInMonth = (date: Date) => Math.ceil((getDaysInMonth(date) + getFirstDayOfMonthOffset(date)) / 7);
export const getOffsetDate = (date: Date, offsetDays: number): Date => {
  return new Date(new Date(date).setDate(date.getDate() + offsetDays));
};
export const getOffsetMonth = (date: Date, offsetMonth: number): Date => {
  return new Date(new Date(date).setMonth(date.getMonth() + offsetMonth));
};
export const getOffsetYear = (date: Date, offsetYear: number): Date => {
  return new Date(new Date(date).setFullYear(date.getFullYear() + offsetYear));
};
export const getFirstDayOfTheYear = (date: Date): Date => {
  const nextDate = new Date(date);
  nextDate.setDate(1);
  nextDate.setMonth(0);
  return nextDate;
};
export const getLastDayOfTheYear = (date: Date): Date => {
  const nextDate = new Date(date);
  nextDate.setDate(31);
  nextDate.setMonth(11);
  return nextDate;
};
export const getMonthMatrix = (date: Date, weeksToDisplay?: number): Date[][] => {
  const firstDayOfMonthDate = getFirstDayOfMonthDate(date);

  return Array.from({length: weeksToDisplay || getWeeksInMonth(date)}, (wv, wi) =>
    Array.from({length: 7}, (dv, di) => getOffsetDate(firstDayOfMonthDate, wi * 7 + di - firstDayOfMonthDate.getDay())),
  );
};

export const isSameDay = (dateA: Date, dateB: Date): boolean =>
  dateA.getFullYear() === dateB.getFullYear() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getDate() === dateB.getDate();

export const isSameMonth = (dateA: Date, dateB: Date): boolean =>
  dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth();

export type DateObj = {
  year: number;
  month: number;
  day: number;
};

export type TimeObj = {
  h: number;
  m: number;
  s: number;
};

export type CalendarDateRange = {
  date: DateObj;
  endDate: DateObj;
};

export const isBetweenDates = (date: Date, startDate: Date, endDate: Date): boolean =>
  date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime();

export const isWithinDates = (date: Date, startDate: Date, endDate: Date): boolean =>
  date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();

export const isBeforeDate = (date: Date, refDate: Date): boolean => date.getTime() < refDate.getTime();
export const isBeforeEqDate = (date: Date, refDate: Date): boolean => date.getTime() <= refDate.getTime();

export const isAfterDate = (date: Date, refDate: Date): boolean => date.getTime() > refDate.getTime();
export const isAfterEqDate = (date: Date, refDate: Date): boolean => date.getTime() >= refDate.getTime();

export const isFormerMonth = (date: Date, refDate: Date): boolean => {
  const formerMonthDate = getOffsetMonth(refDate, -1);
  return isSameMonth(date, formerMonthDate);
};

export const isLatterMonth = (date: Date, refDate: Date): boolean => {
  const latterMonthDate = getOffsetMonth(refDate, +1);
  return isSameMonth(date, latterMonthDate);
};

export const dateObjToDate = (d: Date | DateObj) => (d instanceof Date ? d : new Date(d.year, d.month - 1, d.day));

export const dateTimeObjToDate = (d: DateObj, t?: TimeObj): Date => {
  const nextDate = dateObjToDate(d);
  nextDate.setHours(t?.h || 0);
  nextDate.setMinutes(t?.m || 0);
  nextDate.setSeconds(t?.s || 0);
  return nextDate;
};

export const dateToDateObj = (d: Date): DateObj => {
  return {
    day: d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear(),
  };
};

export const isFirstDayOfTheMonth = (date: Date): boolean => {
  const firstDay = getFirstDayOfMonthDate(date);
  return isSameDay(firstDay, date);
};

export const mondayAsAFirstDayOfAWeek = (_monthMatrix: Date[][]): Date[][] => {
  const beginsWithFirstDay = isFirstDayOfTheMonth(_monthMatrix[0][0]);

  const output = _monthMatrix.map((week, weekIndex) =>
    week.reduce<Date[]>((fixedWeek, day, dayIndex) => {
      if (dayIndex !== 0) {
        fixedWeek.push(day);
      }

      if (dayIndex === 6) {
        fixedWeek.push(getOffsetDate(day, 1));
      }

      return fixedWeek;
    }, []),
  );

  if (beginsWithFirstDay) {
    output.pop();
    output.unshift([
      getOffsetDate(_monthMatrix[0][0], -6),
      getOffsetDate(_monthMatrix[0][0], -5),
      getOffsetDate(_monthMatrix[0][0], -4),
      getOffsetDate(_monthMatrix[0][0], -3),
      getOffsetDate(_monthMatrix[0][0], -2),
      getOffsetDate(_monthMatrix[0][0], -1),
      _monthMatrix[0][0],
    ]);
  }

  return output;
};

export const isSameDateObj = (date1: DateObj, date2: DateObj): boolean =>
  date1.year === date2.year && date1.month === date2.month && date1.day === date2.day;

export const dateToString = (d: Date): string => {
  return [d.getFullYear(), `0${d.getMonth() + 1}`.slice(-2), `0${d.getDate()}`.slice(-2)].join('-');
};

export const dateToYearMonthString = (d: Date): string => {
  return [d.getFullYear(), `0${d.getMonth() + 1}`.slice(-2)].join('-');
};

export const stringToDateObj = (s: string): DateObj => {
  const dateArr = s.split('-');
  return {year: parseInt(dateArr[0], 10), month: parseInt(dateArr[1], 10), day: parseInt(dateArr[2], 10)};
};
